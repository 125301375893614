<template>
    <div class="entire-table">
        <div
            class="md-layout md-card"
            :class="{ 'is-half': highscores.length < (pageSize ? pageSize : 5) + 1 }"
        >
            <HighscoreColumn
                :page="0"
                :pageSize="pageSize ? pageSize : 5"
                :highscores="highscores"
            ></HighscoreColumn>
            <HighscoreColumn
                :page="1"
                :pageSize="pageSize ? pageSize : 5"
                :highscores="highscores"
            ></HighscoreColumn>
        </div>
    </div>
</template>

<script>
import HighscoreColumn from "./HighscoreColumn";

export default {
    name: "HighscoreTable",
    components: { HighscoreColumn },
    props: {
        highscores: Array,
        pageSize: Number,
    },
};
</script>

<style scoped>
/*.is-half {
    width: 50%;
    margin: auto;
}*/

.entire-table {
    text-align: left;
}
</style>