<template>
    <div id="pano-edit-div">
        <template v-if="isSet">
            <span class="md-title">Add Panoramas</span>
            <md-button
                class="md-raised md-accent"
                id="pano-edit-btn"
                @click="openPanoEditor"
                :disabled="saving"
                >Open Panorama Editor</md-button
            >
            <p class="md-caption">
                Currently
                {{ nrPanos > 0 ? nrPanos : "no" }}
                pano{{ nrPanos !== 1 ? "s" : "" }}
            </p>
            <p
                class="md-caption error-text"
                v-if="!hasEnoughPanos && !loadingPanos"
            >
                Need at least 5 panos to make public/playable.
            </p>
            <p
                class="md-caption error-text"
                v-if="!hasEnoughPanos && !existsInDb && !loadingPanos"
            >
                You don't have to add panoramas now. You CAN add these later
            </p>
        </template>

        <div v-else-if="!isSet">
            <span class="md-title">Sync the map to a MyMaps URL</span>
            <div>
                <span class="md-caption" style="position: relative; top: 5px"
                    >How to create map</span
                >
                <md-button
                    style="margin-left: 0px"
                    class="md-icon-button md-dense"
                    @click="showInfoDialog"
                >
                    <md-icon> info </md-icon>
                </md-button>
            </div>

            <div>
                <md-field>
                    <label>MyMaps URL (https://www.google.com/maps/d/edit?[...])</label>
                    <md-input
                        style="height: auto"
                        v-model="url"
                        :disabled="saving"
                    ></md-input>
                </md-field>
                <div style="display: flex; justify-content: end">
                    <md-button
                        class="md-raised md-accent"
                        @click="syncUrl"
                        :disabled="saving || syncing"
                        >Sync</md-button
                    >
                </div>
            </div>

            <div
                v-if="!isSet"
                class="md-layout-item"
                style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                "
            >
                <switches
                    v-model="onlyOutdoorPanos"
                    class="switch"
                    color="blue"
                ></switches>
                <div style="text-align: left">
                    <span>Use only panoramas marked as "outdoor".</span><br />
                    <span>This will exclude most non-Google panoramas.</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Switches from "vue-switches";

export default {
    components: {
        Switches,
    },
    props: {
        nrPanos: Number,
        isSet: Boolean,
        value: String,
        hasEnoughPanos: Boolean,
        loadingPanos: Boolean,
        saving: Boolean,
        syncing: Boolean,
        existsInDb: Boolean,
        mapId: [String, Number],
        onlyOutdoorPanosInit: Boolean,
    },
    data() {
        return {
            url: this.value,
            onlyOutdoorPanos: this.onlyOutdoorPanosInit,
        };
    },
    watch: {
        url: function () {
            if (this.url.includes("https://www.google.com/maps/d/edit")) {
                this.url = this.url.replace("https://www.google.com/maps/d/edit?", "");
            }
            this.$emit("input", this.url);
        },
        onlyOutdoorPanos: function () {
            this.$emit("onlyOutdoorPanosChange", this.onlyOutdoorPanos);
        },
    },
    methods: {
        openPanoEditor() {
            this.$router.push({
                name: "EditMapPanos",
                params: { mapId: this.mapId },
                query: { n: !this.existsInDb },
            });
        },
        showInfoDialog() {
            this.$emit("showInfoDialog");
        },
        syncUrl() {
            this.$emit("syncUrl");
        },
    },
};
</script>

<style scoped>
#pano-edit-div {
    margin-bottom: 2vh;
    margin-top: 3vh;
}

#pano-edit-btn {
    margin-left: 30px;
    position: relative;
    bottom: 7px;
}
.no-space-prefix {
    margin-right: 0;
    padding-right: 0;
}

.switch {
    margin: 0 10px;
}
.switch-div {
    display: inline-flex;
}
</style>