<template>
    <div style="width: 100%" class="flex-down-center">
        <l-map ref="map" style="height: 50vh" :zoom="zoom" :center="center">
            <l-tile-layer
                v-if="mapUrlFull"
                :url="mapUrlFull"
                :attribution="attribution"
            ></l-tile-layer>
            <template v-if="includePanoLocation">
                <template v-for="(loc, index) in markers.data">
                    <l-circle
                        v-if="loc.latPano != null && loc.lngPano != null"
                        :key="index + 'p'"
                        :lat-lng="[loc.latPano, loc.lngPano]"
                        :radius="100"
                        :stroke="false"
                        fillColor="blue"
                        :fillOpacity="opacity"
                    />
                </template>
            </template>
            <template v-if="includeSearchLocation">
                <l-circle
                    v-for="(loc, index) in markers.data"
                    :key="index"
                    :lat-lng="[loc.latSearch, loc.lngSearch]"
                    :radius="
                        useConstantSearchRadius ? constantSearchRadius : loc.r
                    "
                    :stroke="false"
                    :fillColor="loc.successfullyFoundPano ? 'green' : 'red'"
                    :fillOpacity="opacity"
                />
            </template>
        </l-map>
        <div>
            <md-checkbox v-model="includeHits">Include found</md-checkbox>
            <md-checkbox v-model="includeMisses">Include misses</md-checkbox>
            <md-checkbox v-model="includePanoLocation"
                >Include pano location</md-checkbox
            >
            <md-checkbox v-model="includeSearchLocation"
                >Include search location</md-checkbox
            >
            <md-checkbox
                v-model="useConstantSearchRadius"
                :disabled="!includeSearchLocation"
                >Constant search radius</md-checkbox
            >
        </div>
        <div>
            <span class="md-headline">Location for heatmap</span>
            <div class="flex-center">
                <span style="margin-right: 10px">Pano location (if avail)</span>
                <md-switch v-model="useSearchForHeatmap"
                    >Search location</md-switch
                >
            </div>
        </div>
        <div class="action-container">
            <div class="slider-group">
                <input
                    class="input-slider"
                    type="range"
                    name="radius-slider"
                    min="0.01"
                    max="3"
                    v-model.number="cfg.radius"
                    step="0.01"
                    @mouseup="heatmapLayer.setData(markers)"
                />
                <div class="slider-label-container">
                    <label for="radius-slider">Heat radius:</label>
                </div>
                <md-field
                    style="
                        width: 100px;
                        margin: 0;
                        padding: 0;
                        min-height: auto;
                    "
                >
                    <md-input
                        v-model="cfg.radius"
                        v-on:keyup.enter="heatmapLayer.setData(markers)"
                    ></md-input>
                </md-field>
            </div>

            <div class="slider-group">
                <input
                    class="input-slider"
                    type="range"
                    name="radius-slider"
                    min="0.01"
                    max="1"
                    v-model.number="panoIntensity"
                    step="0.01"
                    @mouseup="heatmapLayer.setData(markers)"
                />
                <div class="slider-label-container">
                    <label for="radius-slider">Heat weight:</label>
                </div>
                <md-field
                    style="
                        width: 100px;
                        margin: 0;
                        padding: 0;
                        min-height: auto;
                    "
                >
                    <md-input
                        v-model="panoIntensity"
                        v-on:keyup.enter="heatmapLayer.setData(markers)"
                    ></md-input>
                </md-field>
            </div>

            <div
                class="slider-group"
                v-if="useConstantSearchRadius && includeSearchLocation"
            >
                <input
                    class="input-slider"
                    type="range"
                    name="radius-slider"
                    min="100"
                    max="10000"
                    v-model.number="constantSearchRadius"
                    step="100"
                />
                <div class="slider-label-container">
                    <label for="radius-slider">Const search radius:</label>
                </div>
                <md-field
                    style="
                        width: 100px;
                        margin: 0;
                        padding: 0;
                        min-height: auto;
                    "
                >
                    <md-input v-model="constantSearchRadius"></md-input>
                </md-field>
            </div>

            <div style="margin-top: 5px" class="date-select">
                <span class="md-title">Select date range</span>
                <div class="md-layout md-alignment-top-space-between">
                    <div
                        class="flex-start md-layout-item"
                        style="align-items: center; margin: 5px 0"
                    >
                        <multiselect
                            style="max-width: 200px"
                            v-model="predefRange"
                            name="dateRange"
                            :options="dateOptions"
                            :searchable="false"
                            :allowEmpty="false"
                            :showLabels="false"
                        />
                    </div>
                    <md-button
                        class="md-layout-item md-raised expand-btn"
                        style="max-width: 80px"
                        @click="dateDrawerOpen = !dateDrawerOpen"
                    >
                        <md-icon v-if="!dateDrawerOpen"> expand_more </md-icon>
                        <md-icon v-else> expand_less </md-icon>
                    </md-button>
                </div>
                <div
                    v-if="dateDrawerOpen"
                    class="md-layout md-gutter"
                    style="overflow: hidden"
                >
                    <div
                        :class="{
                            'datepicker-disabled': setRange,
                        }"
                        class="md-layout-item md-small-size-50 md-xsmall-size-100 date-picker"
                    >
                        <label>From</label>
                        <md-datepicker
                            :md-model-type="String"
                            v-model="customStartDate"
                            :md-disabled-dates="disabledDates"
                        />
                    </div>
                    <div
                        :class="{
                            'datepicker-disabled': setRange,
                            'datepicker-error': datepickerError,
                        }"
                        class="md-layout-item md-small-size-50 md-xsmall-size-100 date-picker"
                    >
                        <label>To</label>
                        <md-datepicker
                            :md-model-type="String"
                            v-model="customEndDate"
                            :md-disabled-dates="disabledDates"
                        />
                        <span
                            class="error-span md-caption"
                            style="margin-right: 12px"
                            >End date must be after start</span
                        >
                    </div>
                </div>
            </div>

            <div v-if="false" class="flex-end" style="margin-top: 5px">
                <md-button
                    @click="getGeneratedPanoLocations"
                    class="md-raised md-primary"
                    >Refresh Map</md-button
                >
            </div>
        </div>
        <md-table
            style="margin-top: 20px; max-width: 600px; margins: auto"
            v-model="byRegion"
            md-sort="found"
            md-sort-order="desc"
            md-card
        >
            <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="Flag"
                    ><img
                        style="
                            min-width: 30px;
                            max-width: 30px;
                            min-height: 30px;
                            max-height: 30px;
                        "
                        :src="getFlagUrl(item.code)"
                /></md-table-cell>
                <md-table-cell md-label="Name" md-sort-by="name">{{
                    item.name
                }}</md-table-cell>
                <md-table-cell md-label="Total" md-sort-by="total" md-numeric>{{
                    item.total
                }}</md-table-cell>
                <md-table-cell md-label="Found" md-sort-by="found" md-numeric>{{
                    item.found
                }}</md-table-cell>
                <md-table-cell
                    md-label="Not Found"
                    md-sort-by="notFound"
                    md-numeric
                    >{{ item.notFound }}</md-table-cell
                >
                <md-table-cell
                    md-label="Hit %"
                    md-sort-by="hitPercent"
                    md-numeric
                    >{{ item.hitPercent }}</md-table-cell
                >
            </md-table-row>
        </md-table>
    </div>
</template>

<script>
import { LMap, LTileLayer, LCircle } from "vue2-leaflet";
import * as HeatmapOverlay from "leaflet-heatmap";
import axiosMixin from "../mixins/axiosMixin";
import utilMixin from "../mixins/utilMixin";
import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";

export default {
    components: {
        LMap,
        LTileLayer,
        LCircle,
        Multiselect,
    },
    mixins: [axiosMixin, utilMixin],
    data() {
        return {
            mapId: null,
            attribution: "",
            zoom: 2,
            center: [20, 0],
            locations: [],
            byRegion: [],

            includeHits: true,
            includeMisses: false,
            includeSearchLocation: false,
            useConstantSearchRadius: false,
            includePanoLocation: false,

            useSearchForHeatmap: true,

            constantSearchRadius: 10000,

            cfg: {
                // radius should be small ONLY if scaleRadius is true (or small radius is intended)
                // if scaleRadius is false it will be the constant radius used in pixels
                radius: 1,
                maxOpacity: 0.8,
                // scales the radius based on map zoom
                scaleRadius: true,
                useLocalExtrema: false,
                latField: "lat",
                lngField: "lng",
                valueField: "count",
                blur: 0.75,
            },

            opacity: 0.8,
            panoIntensity: 0.2,
            heatmapLayer: null,

            predefRange: "All",
            dateOptions: [
                "All",
                "Custom",
                "1 week",
                "2 weeks",
                "1 month",
                "3 months",
                "6 months",
                "1 year",
            ],
            customStartDate: null,
            customEndDate: null,

            disabledDates: (date) => {
                return date > new Date();
            },

            datepickerError: false,
            dateDrawerOpen: false,
        };
    },

    computed: {
        ...mapGetters(["mapUrlFull"]),
        markers() {
            return {
                data: this.locations
                    .filter(
                        (l) =>
                            (l.successfullyFoundPano && this.includeHits) ||
                            (!l.successfullyFoundPano && this.includeMisses)
                    )
                    .map((l) => ({
                        lat:
                            this.useSearchForHeatmap || l.latPano == null
                                ? l.lat
                                : l.latPano,
                        lng:
                            this.useSearchForHeatmap || l.lngPano == null
                                ? l.lng
                                : l.lngPano,
                        successfullyFoundPano: l.successfullyFoundPano,
                        r: l.radius,
                        latSearch: l.lat,
                        lngSearch: l.lng,
                        count: this.panoIntensity,
                        latPano: l.latPano,
                        lngPano: l.lngPano,
                    })),
            };
        },
        dateRange(newV, preV) {
            const today = new Date();
            const startDate = new Date();
            let endDate = new Date();
            let selected = this.predefRange;
            if (
                newV === "custom" &&
                !this.customStartDate &&
                !this.customEndDate
            ) {
                selected = preV;
            }
            switch (selected) {
                case "1 week":
                    startDate.setDate(today.getDate() - 7);
                    break;
                case "2 weeks":
                    startDate.setDate(today.getDate() - 14);
                    break;
                case "1 month":
                    startDate.setMonth(today.getMonth() - 1);
                    break;
                case "3 months":
                    startDate.setMonth(today.getMonth() - 3);
                    break;
                case "6 months":
                    startDate.setMonth(today.getMonth() - 6);
                    break;
                case "1 year":
                    startDate.setMonth(today.getMonth() - 12);
                    break;
                case "Custom":
                    return {
                        start: this.customStartDate,
                        end: this.customEndDate,
                    };
                default:
                    return { start: null, end: null }; // For 'All' option, use default start and end dates
            }
            return { start: startDate, end: endDate };
        },
        setRange() {
            return this.predefRange !== "Custom";
        },
    },

    watch: {
        includeHits() {
            this.refreshHeatMap();
        },
        includeMisses() {
            this.refreshHeatMap();
        },
        useSearchForHeatmap() {
            this.refreshHeatMap();
        },
        dateRange(newV, oldV) {
            if (newV !== oldV && !this.setRange) {
                this.dateDrawerOpen = true;
            }
            if (
                newV &&
                newV.start &&
                (!newV.end || (newV.end && newV.end < newV.start))
            ) {
                this.datepickerError = true;
            } else {
                this.datepickerError = false;
                if (this.setRange || (newV && newV.start && newV.end)) {
                    this.getGeneratedPanoLocations();
                }
            }
            this.customStartDate = newV.start;
            this.customEndDate = newV.end;
        },
    },

    methods: {
        refreshHeatMap() {
            this.heatmapLayer.setData(this.markers);
        },
        getGeneratedPanoLocations() {
            this.makeRequest(
                "get",
                process.env.VUE_APP_SERVER_URL + "map/getGeneratedLocations",
                {
                    mapId: this.mapId,
                    fromDate: this.dateRange.start,
                    toDate: this.dateRange.end,
                }
            ).then((resp) => {
                if (!resp) {
                    return;
                }
                if (resp.status == 200) {
                    this.locations = resp.data;
                    this.updateByRegion();
                    this.heatmapLayer.setData(this.markers);
                }
            });
        },
        getMapInfo() {
            this.makeRequest(
                "get",
                process.env.VUE_APP_SERVER_URL + "map/byId",
                { mapId: this.mapId }
            ).then((resp) => {
                if (!resp) {
                    return;
                }
                if (resp.status == 200) {
                    this.center = [resp.data.center.lat, resp.data.center.lng];
                    this.zoom = resp.data.zoom;
                    this.$refs.map.mapObject.setView(this.center, this.zoom);
                }
            });
        },
        updateByRegion() {
            // Groups the locations by country
            let out = {};
            this.locations.forEach((l) => {
                let code = l.countryCodeEntity
                    ? l.countryCodeEntity.resultingCode
                    : "AQ";
                if (!out[code]) {
                    out[code] = {
                        found: 0,
                        notFound: 0,
                        name: l.countryCodeEntity
                            ? l.countryCodeEntity.countryName
                            : "Unknown",
                        code,
                        total: 0,
                        hitPercent: 0,
                    };
                }
                if (l.successfullyFoundPano) {
                    out[code]["found"] += 1;
                } else {
                    out[code]["notFound"] += 1;
                }
                out[code]["total"] += 1;
                out[code]["hitPercent"] = Math.round(
                    (out[code]["found"] / out[code]["total"]) * 100
                );
            });
            this.byRegion = Object.keys(out)
                .map((k) => out[k])
                .sort((a, b) => {
                    return b.found - a.found;
                });
        },
    },
    mounted() {
        this.mapId = this.$route.params.mapId;
        this.getGeneratedPanoLocations();
        this.getMapInfo();

        this.heatmapLayer = new HeatmapOverlay(this.cfg);
        this.heatmapLayer.addTo(this.$refs.map.mapObject);
    },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.date-select .multiselect__content-wrapper {
    z-index: 400 !important;
}
.date-select .multiselect--active {
    z-index: 400 !important;
}
.expand-btn.md-button {
    margin-right: 0;
}
</style>

<style scoped>
.action-container {
    padding: 0 5px;
    max-width: 600px;
    /*overflow: hidden;*/
    margin-left: auto;
    margin-right: auto;
}

.slider-group {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-top: 10px;
    overflow: hidden;
}

.slider-group .slider-label-container {
    flex: 1;
    display: flex;
    justify-content: right;
    margin: 0 10px;
}

.slider-group .slider-label-container label {
    text-align: right;
}

.input-slider {
    max-width: 400px;
    flex: 2;
}

.date-picker {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
}

.date-picker label {
    min-width: 50px;
    text-align: left;
    flex: 1;
}

/*.date-picker .md-field {
    max-width: 250px;
}*/

.datepicker-disabled {
    pointer-events: none;
    opacity: 0.5;
}

.error-span {
    position: absolute;
    bottom: 1px;
    color: red;
    opacity: 0;
    right: 0;
}

.datepicker-error .error-span {
    /*display: block !important;*/
    opacity: 1;
}
</style>

<style>
.date-picker .md-field .md-input {
    padding: 0;
    margin: 0;
    margin-left: 5px;
    text-align: center;
}

.datepicker-error .md-field .md-input {
    -webkit-text-fill-color: red !important;
}

.datepicker-error .md-field::after {
    background-color: red;
}
</style>
