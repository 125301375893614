<template>
    <md-content id="about-container" class="flex-down">
        <img src="/img/nasa-globe.webp" />
        <div class="text-overlay flex-down">
            <span class="md-display-4 header">WhereRU</span>
            <span class="md-headline">Version: {{ version }}</span>
            <md-content class="about-container-text md-elevation-8 text-group">
                <div class="flex-down-left text-container">
                    <span class="md-subheading"> Credits </span>
                    <span class="md-body-1 text"
                        >Created by Jacob Möller and Jonatan Jonsson, 2021</span
                    >
                </div>
                <div class="flex-down-left text-container">
                    <span class="md-subheading"> Description </span>
                    <span class="md-body-1 text">
                        WhereRU is a map guessing game where players are
                        provided a panorama and have to guess where on the map
                        the panorama is from.
                    </span>
                </div>
                <div class="flex-down-left text-container">
                    <span class="md-subheading"> Closed Beta </span>
                    <span class="md-body-1 text">
                        WhereRU is currently in a closed beta. The reason being
                        that the Google API is only free up to a certain
                        request/month threshold. Currently the threshold has not
                        been reached and new players can therefore apply to
                        <b style="color: white"
                            >join the beta by trying to login with their Google
                            account and then choosing "Request Whitelist" when
                            denied.</b
                        >
                    </span>
                </div>
            </md-content>

            <span class="md-display-1">Changelog</span>
            <md-list
                id="changelog"
                style="margin-top: 10px; margin-bottom: 10px"
                class="md-elevation-8 text-group"
            >
                <template v-for="(item, index) in changelog">
                    <md-subheader :key="`s-${index}`"
                        >[{{ item.version }}] {{ item.date }}</md-subheader
                    >

                    <md-list-item :key="`t-${index}`">
                        <div class="md-list-item-text changelog-list">
                            <span>{{ item.title }}</span>
                            <template v-for="(change, i2) in item.changes">
                                <span class="text change" :key="`c-${i2}`"
                                    >+ {{ change }}</span
                                >
                            </template>
                            <div v-if="item.fixes" style="margin-top: 5px" />
                            <template v-for="(change, i2) in item.fixes">
                                <span class="text fix" :key="`f-${i2}`"
                                    >- {{ change }}</span
                                >
                            </template>
                        </div>
                    </md-list-item>

                    <md-divider
                        v-if="index != Object.keys(changelog).length - 1"
                        :key="`d-${index}`"
                    />
                </template>
            </md-list>
        </div>
    </md-content>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    computed: mapGetters(["version"]),

    data() {
        return {
            changelog: [],
        };
    },

    methods: {
        ...mapActions([
            "updateVersion",
            "updateToNewVersion"
        ]),
    },

    created() {
        fetch("/changelog.json")
            .then((response) => response.json())
            .then((data) => {
                this.changelog = data;
            });
        this.updateToNewVersion();
    },
};
</script>

<style>
#about-container {
    position: relative;
    width: 100%;
    background-size: cover;
    background-position: center;    
    overflow: hidden;
    max-height: calc(100vh - 48px);
}

@media screen and (max-width: 500px) {
    #about-container .text-overlay {
        padding: 0 5px !important;
    }

    .header {
        font-size: 74px;
    }
}

#about-container span {
    color: white;
}

#about-container .text-group {
    background: #2b2b2b85;
    transition: background 1s;
}

#about-container .text-group:hover {
    background: #2b2b2bc5;
}

#changelog {
    max-width: 400px;
    min-width: min(90%, 400px);
}

#changelog .md-subheader {
    color: white;
    min-height: 24px;
    padding-top: 10px;
    padding-bottom: 5px;
}

#changelog span {
    overflow-wrap: break-word;
    white-space: break-spaces;
}

#changelog .md-divider {
    background-color: gray;
    margin-top: 5px;
}

#changelog .md-list-item-content {
    min-height: 24px;
}

.changelog-list span {
    font-size: 14px;
}

.changelog-list span:nth-child(1) {
    font-size: 18px;
}

#about-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

#about-container .about-container-text {
    padding: 10px;
    margin: 10px 0;
    max-width: 1000px;
}

#about-container .about-container-text div span {
    width: auto;
    text-align: left;
}

#about-container .text-overlay {
    z-index: 1;
    overflow-y: auto;
    align-items: center;
    padding: 0 75px;
}

#about-container .header {
    margin: 40px 0 10px 0;
}

#about-container .text {
    color: lightgray;
}

#about-container .text-container {
    margin: 10px 0;
}
</style>