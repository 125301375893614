<template>
    <div class="main-container flex-down">
        <div class="score-map-container">
            <InteractiveMap
                class="all-guesses"
                :markerGroups="getMarkerPositions"
            />
        </div>

        <!-- Buttons -->
        <div class="md-layout md-alignment-bottom-center" id="btn-container">
            <md-button
                v-on:click="cpyLink"
                v-if="isMultiplayer"
                class="
                    md-layout-item md-xsmall-size-90 md-raised md-primary
                    decision-btn
                "
                >Copy Link</md-button
            >
            <md-button
                v-on:click="refresh"
                class="md-layout-item md-xsmall-size-90 md-raised decision-btn"
                >Refresh</md-button
            >
            <md-button
                v-on:click="$router.push('/')"
                class="
                    md-layout-item md-xsmall-size-90 md-raised md-accent
                    decision-btn
                "
                >Home</md-button
            >

            <md-button
                v-on:click="playAgain"
                :disabled="creatingGame"
                class="
                    md-layout-item md-xsmall-size-90 md-raised md-primary
                    decision-btn
                "
                >{{ isMultiplayer ? "Host Again" : "Play Again" }}</md-button
            >
        </div>

        <!-- Score tables -->
        <div class="md-layout md-alignment-top-center flex-grow">
            <div
                class="
                    md-layout-item
                    table-container
                    score-container
                    md-xlarge-size-30
                    md-large-size-30
                    md-medium-size-30
                    md-small-size-100
                "
            >
                <div class="score-title-container">
                    <span class="md-display-2">{{
                        `Your ${isRegionGuessing ? "Streak" : "Score"}: ${getScore}`
                    }}</span>
                </div>
                <ScoreTable
                    class="score-table md-card"
                    :scores="getScores"
                    :distances="getDistances"
                    :sumRow="false"
                />
            </div>
            <div
                class="
                    md-layout-item
                    md-xlarge-size-70
                    md-large-size-70
                    md-medium-size-70
                    md-small-size-100
                    table-container
                    highscore-container
                "
            >
                <span class="md-display-2">
                    {{ isMultiplayer ? "MP " : "" }}Highscores</span
                >
                <div id="highscore-div">
                    <div id="filters">
                        <md-button
                            class="md-icon-button md-raised md-accent"
                            style="margin-right: 0"
                            @click="openHighscoreView"
                        >
                            <md-icon>open_in_browser</md-icon>
                            <md-tooltip md-delay="300">Open highscore view</md-tooltip>
                        </md-button>
                        <md-avatar
                            class="md-avatar-icon"
                            :class="{ 'md-primary': !getSettings.canMove }"
                        >
                            <md-icon>do_not_step</md-icon>
                        </md-avatar>
                        <md-avatar
                            class="md-avatar-icon"
                            :class="{ 'md-primary': !getSettings.canZoom }"
                        >
                            <md-icon>search_off</md-icon>
                        </md-avatar>
                        <md-avatar
                            class="md-avatar-icon"
                            :class="{ 'md-primary': !getSettings.canRotate }"
                        >
                            <md-icon>do_not_touch</md-icon>
                        </md-avatar>
                    </div>
                    <HighscoreTable
                        class="high-score-table"
                        :highscores="highscores"
                        :pageSize="isSmall ? 10 : 5"
                    />
                    
                </div>
            </div>
        </div>

        <!-- Popup informing the user that they have copied the link -->
        <md-snackbar
            md-position="center"
            :md-duration="2000"
            :md-active.sync="showSnackbar"
            md-persistent
        >
            <span>Copied to clipboard</span>
        </md-snackbar>
    </div>
</template>

<script>
import InteractiveMap from "../components/InteractiveMap.vue";
import ScoreTable from "../components/ScoreTable";
import HighscoreTable from "../components/HighscoreTable";

import axiosMixin from "../mixins/axiosMixin";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
    name: "PostGame",
    mixins: [axiosMixin],
    components: {
        InteractiveMap,
        ScoreTable,
        HighscoreTable,
    },
    data() {
        return {
            highscores: [],
            mpLink: "",
            showSnackbar: false,
            creatingGame: false,
        };
    },
    computed: {
        ...mapGetters([
            "getDistances",
            "getScore",
            "getScores",
            "getMarkerPositions",
            "getGameId",
            "getSettings",
            "getMapId",
            "isMultiplayer",
            "hasGameInstance",
            "isRegionGuessing",
        ]),
        isSmall() {
            return window.innerWidth < 1500;
        },
    },
    methods: {
        ...mapActions([
            "setGameInstance",
            "setScoreDialogVis",
        ]),
        ...mapMutations(["commitHasNotGuessed"]),

        refresh() {
            this.makeRequest(
                "get",
                process.env.VUE_APP_SERVER_URL +
                    (this.isMultiplayer
                        ? "score/game/top10"
                        : "score/map/top10"),
                this.isMultiplayer
                    ? { gameId: this.getGameId }
                    : {
                          mapId: this.getMapId,
                          canMove: this.getSettings.canMove,
                          canZoom: this.getSettings.canZoom,
                          canRotate: this.getSettings.canRotate,
                          time: this.getSettings.time,
                          guessRegion: this.isRegionGuessing,
                      }
            ).then((resp) => {
                this.highscores = resp.data;
            });
        },
        cpyLink() {
            this.$clipboard(this.mpLink);
            this.showSnackbar = true;
        },
        playAgain() {
            this.creatingGame = true;
            if (this.isMultiplayer) {
                this.$router.push({
                    name: "PreGame",
                    params: {
                        mapIdIn: this.getMapId,
                        settings: {
                            mode: "multi",
                            canMove: this.getSettings.canMove,
                            canZoom: this.getSettings.canZoom,
                            canRotate: this.getSettings.canRotate,
                            time: this.getSettings.time,
                        },
                    },
                });
            } else {
                this.commitHasNotGuessed();
                axiosMixin.methods
                    .makeRequest(
                        "post",
                        process.env.VUE_APP_SERVER_URL + "game/newGame",
                        null,
                        {
                            mapId: this.getMapId,
                            canMove: this.getSettings.canMove,
                            canZoom: this.getSettings.canZoom,
                            canPan: this.getSettings.canRotate,
                            time: this.getSettings.time,
                            guessRegion: this.isRegionGuessing,
                        }
                    )
                    .then((resp) => {
                        if (resp.status == 200) {
                            this.setGameInstance(resp.data);
                            this.setScoreDialogVis(false);
                            this.$router.push({
                                name: "Game",
                            });
                        } else {
                            // TODO fix error message
                            //this.showSnackbar = true;
                            //this.message = "Map not valid.";
                            this.creatingGame = false;
                        }
                    });
            }
        },
        openHighscoreView() {
            this.$router.push({
                name: "HighScore",
                params: { 
                    mapId: this.getMapId 
                },
                query: {
                    canMove: this.getSettings.canMove.toString(),
                    canZoom: this.getSettings.canZoom.toString(),
                    canRotate: this.getSettings.canRotate.toString(),
                    time: "" + this.getSettings.time,
                }
            });
        },
        shortcut(event) {
            if (event.keyCode === 32) { // space
                this.playAgain();
            }
        },
    },
    created() {
        window.addEventListener('keyup', this.shortcut);
        // Construct mp link if mp
        if (this.isMultiplayer) {
            // TODO? vuex mpLink
            this.mpLink =
                window.location.origin + "/joinGame?gameId=" + this.getGameId;
        }

        this.refresh();
    },
    beforeDestroy() {
        window.removeEventListener('keyup', this.shortcut);
    },
};
</script>

<style lang="scss" scoped>
.score-map-container {
    min-height: 45%;
}

.all-guesses {
    min-height: 100%;
    min-width: 100%;
}

.main-container {
    height: calc(100vh - 48px);
    width: 100%;
}

.table-container {
    margin-top: 20px !important;
}

.score-table {
    margin-top: 10px !important;
    width: 80%;
    margin: auto;
    min-width: calc(min(400px, 90vw));
    max-width: 500px;
    overflow: auto;
}

.high-score-table {
    margin-top: 10px !important;
    min-width: calc(min(600px, 95vw));
    margin: auto;
    margin-bottom: 10px;
}

@media (min-width: 600px) {
    .high-score-table {
        width: 90%;
    }
}

@media (max-width: 600px) {
    ::v-deep .md-table-head-label {
        padding-right: 16px;
        padding-left: 12px;
    }

    ::v-deep .md-table-cell-container {
        padding: 6px 2px 6px 2px;
        text-align: center;
    }
}

.decision-btn {
    max-width: 20%;
    margin: 5px 10px;
}

#btn-container {
    margin-top: 10px;
}

@media (min-width: 600px) {
    #filters {
        position: absolute;
        top: -50px;
        right: 70px;
    }
}

#filters {
    margin-top: 5px;
}

#filters div {
    margin-left: 5px;
}

#highscore-div {
    position: relative;
}
</style>
