var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-down flex-grow"},[(_vm.isTeams)?_c('span',{staticClass:"text fat medium",staticStyle:{"padding":"15px 0"},style:({ 'background-color': _vm.team.color })},[_vm._v(" "+_vm._s(_vm.team ? _vm.team.name : "Error")+" ")]):_c('div',{staticClass:"flex-center",staticStyle:{"margin":"0px 0 35px 0"}}),_c('div',{staticClass:"flex-center",staticStyle:{"margin":"10px 0 10px 0"}},[_c('span',{staticClass:"md-display-2"},[_vm._v(_vm._s(_vm.username))]),_c('md-icon',{staticClass:"md-size-2x",staticStyle:{"margin":"5px 10px"}},[_vm._v(_vm._s(_vm.playerImg ? _vm.playerImg : "person"))])],1),(!_vm.isEnd)?_c('span',{staticClass:"text medium"},[_vm._v("Waiting for next round")]):_vm._e(),(
            _vm.info.settings.elimination &&
            _vm.info.settings.partyGameType === 0 &&
            _vm.info.roles.includes('GUESSER') &&
            !_vm.isEnd &&
            _vm.isAlive
        )?_c('div',{staticClass:"flex-center",staticStyle:{"width":"100%","margin-top":"10px"}},[_c('BonusGuessDisplay')],1):_vm._e(),(_vm.isTeams && !_vm.info.settings.guessRegion)?_c('div',[_c('md-button',{staticClass:"md-raised",class:{ 'md-primary': _vm.displayTeamScore },on:{"click":function($event){_vm.displayTeamScore = true}}},[_vm._v("TEAM SCORE")]),_c('md-button',{staticClass:"md-raised",class:{ 'md-primary': !_vm.displayTeamScore },on:{"click":function($event){_vm.displayTeamScore = false}}},[_vm._v("YOUR SCORE")])],1):_vm._e(),(_vm.info.settings.guessRegion)?_c('div',{attrs:{"id":"info-content"}},[_c('div',{staticClass:"info-row flex-center"},[_c('div',{staticClass:"flex-down"},[_c('span',{staticClass:"text fat small"},[_vm._v("Guess: ")]),(_vm.guesserResponse.playerScore.regionCode)?_c('span',{staticClass:"text fat medium region-text",class:{
                        correct:
                            _vm.guesserResponse.playerScore.roundCorrectRegion,
                    },staticStyle:{"max-width":"320px"}},[_vm._v(_vm._s(_vm.guesserResponse.playerScore.regionCode.name))]):_vm._e()]),(_vm.guesserResponse.playerScore.regionCode)?_c('img',{attrs:{"src":_vm.getFlagUrl(_vm.guesserResponse.playerScore.regionCode.code)}}):_c('span',{staticClass:"text fat medium",staticStyle:{"margin-left":"5px"}},[_vm._v(" No guess ")])]),_c('md-divider'),(!_vm.info.settings.elimination)?_c('ScoreDispText',{attrs:{"header":_vm.isEnd ? '' : 'Overall',"posText":_vm.isTeams
                    ? _vm.isEnd
                        ? 'Your team placed:'
                        : 'Your team is:'
                    : _vm.isEnd
                    ? 'You placed:'
                    : 'You are:',"pos":_vm.isTeams
                    ? _vm.guesserResponse.teamScore.position
                    : _vm.guesserResponse.playerScore.position,"scoreString":"Correct guesses:","score":_vm.isTeams
                    ? _vm.guesserResponse.teamScore.totalCorrectRegions
                    : _vm.guesserResponse.playerScore.totalCorrectRegions}}):_c('div',{staticClass:"flex-down"},[_c('span',{staticClass:"text fat small"},[_vm._v(" "+_vm._s(_vm.isAlive ? "Remaining health" : "You are out :("))]),_c('div',{staticClass:"flex-center"},_vm._l((_vm.isTeams
                        ? _vm.guesserResponse.teamScore.health
                        : _vm.guesserResponse.playerScore.health),function(h,i){return _c('md-icon',{key:i,staticClass:"md-size-2x",staticStyle:{"color":"red","margin":"0"}},[_vm._v(" favorite ")])}),1)])],1):_c('div',{attrs:{"id":"info-content"}},[(!_vm.displayTeamScore)?[(!_vm.isEnd)?[_c('ScoreDispText',{attrs:{"posText":"This round you placed:","pos":_vm.guesserResponse.playerScore.roundPosition,"scoreString":"This round you scored:","score":_vm.guesserResponse.playerScore.roundScore,"regionCode":_vm.guesserResponse.playerScore.regionCode}}),_c('md-divider')]:_vm._e(),_c('ScoreDispText',{attrs:{"header":_vm.isEnd ? '' : 'Overall',"posText":_vm.isEnd ? 'You placed:' : 'You are:',"pos":_vm.guesserResponse.playerScore.position,"scoreString":_vm.isEnd ? 'You scored:' : 'Total score:',"score":_vm.guesserResponse.playerScore.totalScore}})]:_vm._e(),(_vm.isTeams && _vm.displayTeamScore)?[(!_vm.isEnd)?[_c('ScoreDispText',{attrs:{"posText":"This round your team placed:","pos":_vm.guesserResponse.teamScore.roundPosition,"scoreString":"This round your team scored:","score":_vm.guesserResponse.teamScore.roundScore,"regionCode":_vm.guesserResponse.teamScore.regionCode}}),_c('md-divider')]:_vm._e(),_c('ScoreDispText',{attrs:{"header":_vm.isEnd ? '' : 'Overall',"posText":_vm.isEnd ? 'Your team placed:' : 'Your team is:',"pos":_vm.guesserResponse.teamScore.position,"scoreString":_vm.isEnd ? 'Your team scored:' : 'Team score:',"score":_vm.guesserResponse.teamScore.totalScore}})]:_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }