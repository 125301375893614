<template>
    <md-content
        class="md-layout-item text-in-box player-pill md-primary"
        :class="{'md-accent': !isActivePlayer, 'md-primary':isActivePlayer, 'player-is-you': isActivePlayer && isTeams}"
        @mouseover="hover = true"
        @mouseleave="hover = false"
        v-on:click="kickPlayer"
    >
        <div class="btn-overlay" v-if="!(nonRemovable || this.isActivePlayer)">
            <md-icon class="md-size-3x">close</md-icon>
        </div>
        <div class="pill-content">
            <md-icon :class="{'gray-color':isActivePlayer || isTeams}" class="player-icon">{{
                player.img ? player.img : "account_box"
            }}</md-icon>
            <span class="scaling-font-small" :class="{'gray-color':isActivePlayer || isTeams}">{{ player.username }}</span>
        </div>
    </md-content>
</template>

<script>
export default {
    computed: {
        isActivePlayer() {
            return this.player.id === this.idOfActivePlayer;
        }
    },

    data() {
        return {
            hover: false,
        };
    },
    props: {
        player: Object,
        nonRemovable: Boolean,
        idOfActivePlayer: String,
        isTeams: Boolean,
    },
    methods: {
        kickPlayer() {
            if (!(this.nonRemovable || this.isActivePlayer)) {
                this.$emit('kick');
            }
        }
    }
};
</script>

<style scoped>
.text-in-box {
    padding: 12px;
    width: fit-content;
    border-radius: 5px;

    margin: 10px auto;
    white-space: nowrap;
    max-width: fit-content;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
}

.player-is-you {
    border: 2px solid black !important;
    padding: 10px;
}

.btn-overlay {
    border-radius: 5px;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0);
    opacity: 0;
    z-index: 2;
    cursor: pointer;

    transition: 0.4s cubic-bezier(0.4, 0, 0.2, 1);

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.btn-overlay:hover {
    opacity: 0.5;
}

.btn-overlay i {
    color: #4a4a4a !important;
}

.player-icon {
    margin-right: calc(max(8px, .5vw));
    transform: scale(1.2);
}

.gray-color.gray-color {
    color: #e6e6e6 !important;
}

.pill-content {
    display: flex;
    align-items: center;
}

.player-pill {
    margin: 5px;
}
</style>