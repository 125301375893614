<template>
    <div>
        <div class="center-div">
            <span class="md-title">Select score function</span>

            <p class="md-caption">
                Pick which scoring function size best matches your map
            </p>

            <div>
                <md-radio
                    :disabled="saving"
                    v-model="content.scoreFunction"
                    :value="4"
                >
                    <span
                        >Small Town

                        <md-tooltip md-delay="200" md-direction="top"
                            >E.g. Linköping</md-tooltip
                        >
                    </span>
                </md-radio>

                <md-radio
                    :disabled="saving"
                    v-model="content.scoreFunction"
                    :value="3"
                >
                    <span
                        >Large City

                        <md-tooltip md-delay="200" md-direction="top"
                            >E.g. Stockholm</md-tooltip
                        >
                    </span>
                </md-radio>

                <md-radio
                    :disabled="saving"
                    v-model="content.scoreFunction"
                    :value="2"
                >
                    <span
                        >Country

                        <md-tooltip md-delay="200" md-direction="top"
                            >E.g. Sweden</md-tooltip
                        >
                    </span>
                </md-radio>

                <md-radio
                    :disabled="saving"
                    v-model="content.scoreFunction"
                    :value="1"
                >
                    <span
                        >Continent

                        <md-tooltip md-delay="200" md-direction="top"
                            >E.g Europe</md-tooltip
                        >
                    </span>
                </md-radio>

                <md-radio
                    :disabled="saving"
                    v-model="content.scoreFunction"
                    :value="0"
                >
                    <span
                        >World

                        <md-tooltip md-delay="200" md-direction="top"
                            >The Whole World</md-tooltip
                        >
                    </span>
                </md-radio>
            </div>
        </div>

        <md-divider></md-divider>

        <div class="center-div">
            <span class="md-title">Set map center</span>

            <p class="md-caption">
                Zoom and pan map to where the default center of the map will be
                (start position when guessing)
            </p>

            <InteractiveMap
                id="map"
                ref="centerMap"
                :disabled="saving"
                :settings="{
                    center: content.center,
                    zoom: content.zoom,
                }"
            />
        </div>
    </div>
</template>

<script>
import InteractiveMap from "../InteractiveMap.vue";

export default {
    components: { InteractiveMap },
    props: {
        value: Object,
        saving: Boolean,
    },
    data() {
        return {
            content: this.value,
        };
    },
    watch: {
        content: function () {
            this.$emit("input", this.content);
        },
    },
    methods: {
        currentCenter() {
            return this.$refs.centerMap.currentCenter;
        },
        currentZoom() {
            return this.$refs.centerMap.currentZoom;
        },
    },
};
</script>

<style scoped>
#map {
    width: 80%;
    height: 29vh;
    min-width: 200px;
    min-height: 200px;
    margin: auto;
}

@media only screen and (max-width: 600px) {
    #map {
        width: 100%;
    }
}
</style>
