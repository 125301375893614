<template>
    <img class="map-img" :src="fullImgUrl" alt="Map image" @error="imageLoadError" />
</template>

<script>
export default {
    props: {
        imgUrl: String,
        mapId: Number,
        largeImg: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            imgId: this.imgUrl,
        };
    },
    watch: {
        imgUrl: function () {
            this.imgId = this.imgUrl;
        }
    },
    computed: {
        fullImgUrl: function () {
            if (this.largeImg) {
                if (this.imgId) {
                    return `${process.env.VUE_APP_IMAGE_URL}getFullImage?image=${this.imgId}`;
                } else {
                    return "/img/generic.webp";
                }
            }
            else {
                if (this.imgId) {
                    return `${process.env.VUE_APP_IMAGE_URL}getThumbnail?image=${this.imgId}&mapId=${this.mapId}`;
                } else {
                    return "/img/generic-small.webp";
                }
            }
        },
    },
    methods: {
        imageLoadError() {
            this.imgId = "";
        },
    }
};
</script>
    
<style>
</style>