<template>
    <div
        class="timer-box center"
        style="z-index: 1000"
        v-if="endTime > 0 || useBonus"
        v-show="started && (!useBonus || (useBonus && endTime > 0) || bonusAvail)"
    >
        <span
            class="timer-container"
            ref="timerBox"
            :class="{ 'low-time': lowTime, 'new-timer': timerIsNew }"
        >
            <Timer
                v-show="endTime > 0"
                class="timer-box-timer"
                :endTime="endTime"
                :startTime="startTime"
                @lowTime="lowTime = true"
                @start="started = true"
                @end="onEnd"
            />
            <div
                class="timer-fill"
                :style="`left:-${currPos}px;`"
                :class="{ animate: bonusAvail }"
            />
        </span>
        <div class="bonus-info" v-if="bonusAvail">TIME BONUS</div>
    </div>
</template>

<script>
import Timer from "./Timer.vue";

const bonusTime = 1000 * 10;

export default {
    components: {
        Timer,
    },
    props: {
        endTime: Number,
        startTime: Number,
        useBonus: Boolean,
    },
    data() {
        return {
            lowTime: false,
            started: false,
            timerIsNew: false,

            // Bonus animation
            bonusEndTime: this.startTime + bonusTime,
            currPos: 1000,
            endPos: 120,
            bonusTimer: null,
            currentTime: -1,
        };
    },
    watch: {
        endTime(newVal, prevVal) {
            if (Math.abs(newVal - prevVal) >= 500) {
                this.timerIsNew = true;
                setTimeout(() => {
                    this.timerIsNew = false;
                }, 1000);
            }
        },
        useBonus(newVal) {
            if (newVal) {
                this.startBonusTimer();
            }
        },
        started(newVal) {
            if (this.useBonus) {
                this.startBonusTimer();
            }
        },
    },
    computed: {
        bonusAvail() {
            return this.useBonus && (this.bonusEndTime - this.currentTime) > 0;
        },
    },
    methods: {
        onEnd() {
            this.$emit("end");
        },
        startBonusTimer() {
            // Bonus animation, countdown
            if (this.bonusTimer) return;
            this.bonusTimer = setInterval(() => {
                this.currentTime = Date.now();
                if ((this.bonusEndTime - this.currentTime) < bonusTime) {
                    const bonusPercentageRemaining =
                        (this.bonusEndTime - this.currentTime) / bonusTime;
                    this.currPos = (1 - bonusPercentageRemaining) * (this.endPos + 2);
                } else {
                    clearInterval(this.bonusTimer);
                    this.bonusTimer = null;
                }
            }, 100);
        },
    },
    mounted() {
        this.currentTime = Date.now();
        if (this.useBonus) {
            this.startBonusTimer();
        }
    },
    beforeDestroy() {
        clearInterval(this.bonusTimer);
    },
};
</script>

<style>
.timer-box-timer {
    position: relative;
    z-index: 1001;
}

.timer-container {
    overflow: hidden;
    position: relative;
    border-radius: 25px;
    padding: 15px 20px;
    font-size: 30px;
    background: #ffffff81;
}

.timer-box {
    width: 115px;
    position: absolute;
    top: 50px;
    pointer-events: none;
    display: flex;
    flex-direction: column;
}

.timer-box.new-timer {
    border: 6px solid #fbff00;
}

.low-time {
    background: #ff0000ab;
    color: white;
}

.timer-fill {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: #fbff00ab;
}

.animate {
    transition: 0.1s left linear;
}

.bonus-info {
    color: #fbff00;
    font-size: 15px;
    text-shadow: 2px 2px black;
}
</style>