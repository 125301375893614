<template>
    <l-map
        ref="map"
        :zoom="zoom"
        :center="center"
        @click="onMapClick"
        :options="{ zoomControl: false }"
        @update:center="centerUpdate"
        @update:zoom="zoomUpdate"
        :worldCopyJump="true"
    >
        <l-control-zoom position="bottomleft"></l-control-zoom>

        <l-control position="bottomright">
            <md-button
                @click="centerOrResetMap"
                class="md-icon-button md-dense md-raised md-primary"
            >
                <md-icon>cached</md-icon>
            </md-button>
        </l-control>
        <l-control position="topleft" v-if="canLarge">
            <md-button
                @click="toggleMapSize"
                id="size-toggle-btn"
                class="md-icon-button md-dense md-raised md-primary"
            >
                <md-icon v-if="isLarge"> unfold_less </md-icon>
                <md-icon v-else> unfold_more </md-icon>
            </md-button>
        </l-control>

        <l-tile-layer v-if="mapUrlFull" :url="mapUrlFull" :attribution="attribution"/>
        <l-marker
            v-if="markerLatLng"
            :lat-lng="markerLatLng"
            :icon="selectionMarkerIcon()"
        ></l-marker>

        <template :v-if="oldMarkers">
            <l-marker
                @click.stop:="markerClick"
                v-for="(marker, index) in oldMarkers"
                :key="`o+${index}`"
                :lat-lng="marker.coord ? marker.coord : marker"
                :icon="oldMarkerIcon(marker.regionCode)"
            />
        </template>

        <!-- Display locations on the map -->
        <template v-for="(markerPair, index) in markerGroups">
            <template v-if="markerPair.guesses">
                <template v-for="(guess, index2) in markerPair.guesses.filter(g => g.coord != null)">
                    <l-marker
                        @click.stop:="markerClick"
                        :key="`f+${index}+${index2}`"
                        :lat-lng="guess.coord"
                        :icon="customMarkerIcon(guess.img, guess.color, guess.temporary, guess.regionCode ? guess.regionCode : guess.coord ? guess.coord.regionCode : {})"
                    >
                        <l-tooltip
                            v-if="guess.text"
                            >{{ guess.text }}</l-tooltip
                        >
                    </l-marker>
                </template>
            </template>

            <l-marker
                @click.stop:="markerClick"
                :key="`s+${index}`"
                :lat-lng="markerPair.correct.coord"
                :icon="goalMarkerIcon(markerPair.correct.text, markerPair.correct.coord.regionCode)"
            >
                <l-tooltip
                    v-if="markerPair.correct.text"
                    >{{ 'Round ' + markerPair.correct.text }}</l-tooltip
                >
            </l-marker>
        </template>

        <template v-if="teammateGuesses">
            <div class="teammate-marker-and-icon-container">
                <span class="dot"></span>
                <l-marker
                    @click.stop:="markerClick"
                    v-for="(guess, index) in teammateGuesses"
                    :key="`g+${index}`"
                    :lat-lng="guess.coord"
                    :icon="customMarkerIcon(guess.img, guess.color, guess.temporary, guess.regionCode)"
                />
            </div>
        </template>
    </l-map>
</template>

<script>
// Uses https://github.com/henrythasler/Leaflet.Geodesic to draw geodesic lines
import L from 'leaflet';
import 'leaflet.geodesic';
import { icon, divIcon } from "leaflet";
import {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
    LControlZoom,
    LControl,
} from "vue2-leaflet";

const defaultZoom = 15;
const defaultCenter = [51.505, -0.159];

import { mapGetters } from "vuex";
import utilMixin from "../mixins/utilMixin";

export default {
    name: "InteractiveMap",
    components: {
        LMap,
        LTileLayer,
        LMarker,
        LTooltip,
        LControlZoom,
        LControl,
    },
    mixins: [utilMixin],
    data() {
        return {
            attribution:L.version,
            zoom: defaultZoom,
            center: defaultCenter,
            markerLatLng: null,
            
            currentZoom: defaultZoom,
            currentCenter: defaultCenter,
            markerPlaced: false,

            regionCode: {},

            lines: [],
        };
    },
    props: {
        canPlaceMarker: Boolean,
        settings: Object,
        markerGroups: Array,
        oldMarkers: Array,
        teammateGuesses: Array,

        centerMapAtStart: Boolean,

        initGuess: Object,
        guessRegionCode: Object,

        disabled: Boolean,
        canLarge: Boolean,
        isLarge: Boolean,

        triggerClear: Boolean,
    },
    watch: {
        guessRegionCode: function (newVal) {
            if (!newVal || !newVal.code) {
                setTimeout(() => {
                    if (!this.guessRegionCode || !this.guessRegionCode.code) {
                        this.regionCode = this.guessRegionCode;
                    }
                }, 125);
            } else {
                this.regionCode = this.guessRegionCode;
            }
        },
        markerGroups: function () {
            if (this.markerGroups && this.markerGroups.length > 0) {
                this.centerMap();
                this.drawLines();
            }
        },
        disabled: function () {
            const map = this.$refs.map.mapObject;
            const lc = document.getElementsByClassName(
                "leaflet-control-container"
            );
            if (this.disabled) {
                lc[0].style.visibility = "hidden";
                map._handlers.forEach(function (handler) {
                    handler.disable();
                });
            } else {
                lc[0].style.visibility = "visible";
                map._handlers.forEach(function (handler) {
                    handler.enable();
                });
            }
        },
        triggerClear: function () {
            this.clear();
            this.centerOrResetMap();
        },
    },
    computed: {
        ...mapGetters(["mapUrlFull"]),
    },
    methods: {

        /* A round circle with the player's icon */
        customMarkerIcon(teammateIcon, teamColor, temporary, regionCode) {
            const icon = teammateIcon ? teammateIcon : "person";
            const border = "2px solid " + (temporary ? 'gray' : teamColor ? teamColor : "#0da200");
            const html = '<div class="div-marker-style">' + this.getFlag(regionCode) + '<span class="dot" style="border:'+border+
                         '"><span class="material-symbols-outlined">'+icon+'</span></span></div>';
            return divIcon({
                html,
                iconSize: null,
                iconAnchor: [16, 16],
            })
        },

        /* An orange triangle with a small round number in a circle */
        goalMarkerIcon(index, regionCode) {
            const border = "2px solid black"
            const circleNumber = index ? '<span class="small dot" style="border:'+border+'"><span>'+index+'</span></span>' : '';
            const html = '<div class="div-marker-style">' + this.getFlag(regionCode, 'right: -2px;') + '<img src="img/marker2.webp"/>'+circleNumber+'</div>';
            return divIcon({
                html,
                iconSize: null,
                iconAnchor: [16, 37],
            })
        },

        selectionMarkerIcon() {
            return this.markerIkon("img/logo.webp", this.regionCode);
        },
        oldMarkerIcon(regionCode) {
            return this.markerIkon("img/marker_old.webp", regionCode ? regionCode : this.regionCode);
        },

        markerIkon(markerImg, regionCode="") {
            const html = '<div class="div-marker-style">' + this.getFlag(regionCode, 'right: -2px;') + '<img src="' + markerImg + '"/></img></div>';
            return divIcon({
                html,
                iconSize: null,
                iconAnchor: [16, 37],
            })
        },

        getFlag(regionCode, right) {
            if (regionCode && regionCode.code) {
                return '<img src="' + this.getFlagUrl(regionCode.code) + '" style="position: absolute; top: -30px; ' + right + ' border-radius: 50%; width: 32px; height: 32px;"/>';
            }
            return '';
        },

        markerClick(e) {
            this.$refs.map.mapObject.setView(
                e.latlng,
                Math.min(15, this.currentZoom + 2)
            );
        },
        onMapClick(e) {
            this.placeMarker(e);
        },

        placeMarker(pos) {
            const coord = pos.latlng ? pos.latlng : pos.coord
            if (this.canPlaceMarker) {
                if (!this.markerPlaced) {
                    this.markerPlaced = true;
                    this.$emit("markerPlaced", true);
                }
                this.markerLatLng = coord;
                this.$emit("markerPosUpdate", coord);
            }
        },

        centerMap() {
            if (this.markerGroups && this.markerGroups.length > 0) {
                let all_markers = [];
                this.markerGroups.forEach((group) => {
                    if (group.correct) {
                        all_markers.push(group.correct.coord);
                    }
                    if (group.guesses) {
                        group.guesses.forEach((guess) => {
                            if (guess) {
                                all_markers.push(guess.coord);
                            }
                        });
                    }
                });
                all_markers = all_markers.filter(l => !!l);
                this.$refs.map.mapObject.fitBounds(all_markers);

                if (all_markers.length <= 1) {
                    this.$refs.map.mapObject.setZoom(this.settings && this.settings.zoom ? this.settings.zoom : 13);
                }
            }
        },
        clear() {
            this.markerPlaced = false;
            this.$emit("markerPlaced", false);
            this.markerLatLng = null;
            window.dispatchEvent(new Event("resize"));
        },

        centerOrResetMap() {
            if (this.markerGroups) {
                this.centerMap();
            } else {
                this.resetCenterZoom();
            }
        },

        resetCenterZoom() {
            if (this.settings) {
                this.setCenterZoom(this.settings.center, this.settings.zoom);
            } else {
                this.setCenterZoom(defaultCenter, defaultZoom);
            }
        },

        setCenterZoom(center, zoom) {
            this.$refs.map.mapObject.setView(center, zoom);
        },

        zoomUpdate(zoom) {
            this.currentZoom = zoom;
        },
        centerUpdate(center) {
            this.currentCenter = center;
        },

        toggleMapSize() {
            this.$emit("toggleMapSize");
            window.dispatchEvent(new Event("resize"));
        },

        drawLines() {
            this.lines.forEach(line => {
                line.setLatLngs([]);
            });
            this.lines = [];
            if (!this.markerGroups) return;
            const options = {
                opacity: 0.8,
                color: 'black',
                steps: 8,
                wrap: true,
            }
            this.markerGroups.forEach(markerPair => {
                if (markerPair.guesses && markerPair.correct) {
                    markerPair.guesses.filter(g => g.coord != null).forEach(guess => {
                        this.lines.push(L.geodesic([guess.coord, markerPair.correct.coord], options).addTo(this.$refs.map.mapObject));
                    });
                }
            });
        },
    },
    created() {},
    mounted() {
        this.clear();
        if (this.settings && !this.centerMapAtStart) {
            this.center = this.settings.center;
            this.currentCenter = this.center;
            this.zoom = this.settings.zoom;
            this.currentZoom = this.zoom;
        } else {
            this.centerMap();
        }
        if (this.initGuess) {
            this.placeMarker(this.initGuess)
        }
        this.drawLines();
    },
};
</script>

<style scoped>
.example-custom-control {
    background: #fff;
    padding: 0 0.5em;
    border: 1px solid #aaa;
    border-radius: 0.1em;
}
.custom-control-watermark {
    font-size: 200%;
    font-weight: bolder;
    color: #aaa;
    text-shadow: #555;
}
.teammate-marker-and-icon-container{
    position: relative;
}
.marker-icon {
    position: absolute;
    top: 200px;
    right: 500px;
}

</style>

<style>
/* Custom markers */
.div-marker-style {
	position: relative;
}

.div-marker-style img {
	width: 32px;
	height: 37px;
}

.div-marker-style .dot .material-symbols-outlined {
	position: absolute;
	top: 2px;
	left: 2px;
}


.div-marker-style .dot {
	position: absolute;
	top: 0;
	left: 0;
	height: 32px;
	width: 32px;
	background-color: white;
	border-radius: 50%;
	display: inline-block;
	border: 2px solid #0da200;
}


.small.dot {
    top: auto;
    left: auto;
    bottom: 0 !important;
    right: 0 !important;
    width: 16px !important;
    height: 16px !important;
}

.small.dot span {
    position: relative;
    bottom: 10px;
    font-size: 11px;
}
</style>
