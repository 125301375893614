import axios from "axios";
import router from "../router/index";

export default {
    methods: {
        makeRequest(method, path, params = null, data = null, useToken = true, allowFail = true) {
            let token = "";
            if (useToken) {
                token = localStorage.getItem("token");
            }

            let url = path;
            if (!url.startsWith("http")) {
                const urlBase = `${window.location.protocol}//${window.location.hostname}`;
                url = urlBase +":"+ path;
            }

            return axios({
                method,
                url,
                params,
                data,
                headers: {
                    Authorization: "Bearer " + token,
                },
            }).catch((error) => {
                if ((!error.response || error.response.status == 403) && !allowFail) {
                    // Cors error
                    this.navigateToLogin();
                }
                return error.response;
            });
        },
        navigateToLogin() {
            let nextUrl = "";
            if (this.$route && !this.$route.fullPath.startsWith("/login")) {
                nextUrl = this.$route.fullPath
            }
            window.sessionStorage.clear();
            localStorage.removeItem("token");
            router.push({
                name: "Login", 
                query: {
                    nextUrl 
                }
            });
        }
    },
};
