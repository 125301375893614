<template>
    <!-- Settings dialog -->
    <md-dialog
        :md-active.sync="showSettings"
        class="settings-dialog"
        :md-fullscreen="false"
    >
        <md-dialog-title>
            <div class="cover">
                <span class="md-headline">{{ info.mapName }}</span>
            </div>
            <MapImage :imgUrl="info.imgUrl" :mapId="info.mapId" :largeImg="true" class="card-img" />
        </md-dialog-title>

        <div class="flex-center dialog-content" style="display: grid">
        <GameSettings
            class="settings-container"

            :mode="info.roomType === 'MULTIPLAYER' ?  'multi' : 'party'"
            :mapId="info.mapId"
            :canRegionGuess="canRegionGuess"
            :locked="saving"
            :initialSettings="convSettings()"
            @validChanged="settingsValid = $event"
            @settingsChanged="settings = $event"
        />
        </div>

        <md-dialog-actions>
            <md-button class="md-primary" @click="showSettings = false"
                >Close</md-button
            >
            <md-button
                v-if="!disableAll"
                class="md-primary"
                @click="saveSettings"
                :disabled="disableSave"
                >Save</md-button
            >
        </md-dialog-actions>
    </md-dialog>
</template>

<script>
import { mapState } from "vuex";
import GameSettings from "../../components/GameSettings.vue";
import MapImage from "../../components/MapImage.vue";
import axiosMixin from "../../mixins/axiosMixin";

export default {
    mixins: [axiosMixin],
    components: {
        MapImage,
        GameSettings,
    },
    props: {
        showSettingsTrigger: Boolean,
        disableAll: Boolean,
    },
    data() {
        return {
            showSettings: false,
            settings: null,
            saving: false,
            settingsValid: true,
            canRegionGuess: false,
        };
    },
    computed: {
        ...mapState("partygame", ["info", "socket"]),
        disableSave: function () {
            return (
                this.saving || !this.settingsValid
            );
        },
        disableChanges() {
            return this.disableAll || this.saving;
        },
    },
    watch: {
        showSettingsTrigger: function () {
            this.settings = this.convSettings();
            this.showSettings = true;
        },
    },
    methods: {
        saveSettings() {
            if (!this.settingsValid) {
                return;
            }
            if (
                JSON.stringify(this.settings) !==
                JSON.stringify(this.convSettings())
            ) {
                this.saving = true;
                this.socket.emit("changeSettings", {
                    roomCode: this.info.roomId,
                    authHeader: "Bearer " + localStorage.getItem("token"),
                    payload: this.settings,
                });
                this.socket.once("settingsUpdated", () => {
                    this.showSettings = false;
                    this.saving = false;
                });
            } else {
                this.showSettings = false;
            }
        },
        convSettings() {
            return {
                ...this.info.settings,
                noPan: !this.info.settings.canRotate,
                noMove: !this.info.settings.canMove,
                noZoom: !this.info.settings.canZoom,
                eliminationMode: this.info.settings.elimination,
            };
        },
    },
    created() {
        this.settings = this.convSettings();

        this.makeRequest(
            "get",
            process.env.VUE_APP_SERVER_URL + "map/byId",
            { mapId: this.info.mapId }
        ).then((resp) => {
            if (!resp) {
                return;
            }
            if (resp.status == 200) {
                this.canRegionGuess = resp.data.canRegionGuess;
            }
        });
    },
};
</script>

<style scoped>
.settings-container {
    width: calc(min(620px, 100vw));
    padding: 10px;
}

.md-dialog-title {
    padding: 0;
    margin-bottom: 0px;
    position: relative;
}

.cover {
    position: absolute;
    bottom: 10px;
    left: 0px;
    background: #7d79798f;
    width: 100%;
    padding: 5px;
    text-align: center;
    color: white;
}

.card-img {
    width: 100%;
    height: calc(min(100px, 20vh));
    overflow: hidden;
    object-fit: cover;
}

.dialog-content {
    overflow: auto;
}
</style>