<template>
    <div id="region-container">
        <div v-if="regionCode && regionCode.code" id="flag-container">
            <div style="position: relative">
                <img
                    :src="getFlagUrl(regionCode.code)"
                    @click="showRegionText = !showRegionText"
                />
                <div :class="{ 'flag-border-correct-region': correct }" />
            </div>
            <div
                class="flex-down-left infobox"
                style="text-align: left"
                v-if="showRegionText"
            >
                <span class="md-title">
                    {{
                        hasGuessesLeft
                            ? "Currently Selected"
                            : correct
                            ? "You correctly picked"
                            : "You picked"
                    }}
                </span>
                <span class="md-subheading">
                    {{ regionCode.name }}
                </span>
            </div>
        </div>
        <div
            v-if="getIncorrectRegions && getIncorrectRegions.length > 0"
            class="flex-down-left"
            id="incorrect-regions-container"
        >
            <span class="md-subtitle infobox">Incorrect guesses:</span>
            <div class="md-layout md-align-top-left">
                <template v-for="region in getIncorrectRegions">
                    <div
                        :v-if="region && region.code && region.code.length > 0"
                        :key="region.code"
                        class="md-layout-item flex-start md-size-25 md-xsmall-size-33"
                    >
                        <img
                            :src="getFlagUrl(region.code)"
                            @click="showRegionText = !showRegionText"
                        />
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import utilMixin from "../../mixins/utilMixin";

export default {
    mixins: [utilMixin],
    computed: {
        ...mapState("partygame", ["currentRegionCode"]),
        ...mapGetters("partygame", [
            "hasGuessesLeft",
            "getCorrectRegion",
            "getIncorrectRegions",
        ]),
        correct() {
            return this.getCorrectRegion && this.getCorrectRegion.code;
        },
    },
    watch: {
        currentRegionCode(newVal) {
            if (this.getCorrectRegion && this.getCorrectRegion.code) return;
            if (!newVal || !newVal.code) {
                setTimeout(() => {
                    if (
                        !this.currentRegionCode ||
                        !this.currentRegionCode.code
                    ) {
                        this.regionCode = this.currentRegionCode;
                    }
                }, 125);
            } else {
                this.regionCode = this.currentRegionCode;
            }
        },
        getCorrectRegion(newVal) {
            this.regionCode = newVal;
        },
    },
    data() {
        return {
            regionCode: {},
            showRegionText: true,
        };
    },
    created() {
        this.regionCode = this.getCorrectRegion
            ? this.getCorrectRegion
            : this.currentRegionCode;
    },
};
</script>

<style scoped>
#region-container {
    opacity: 0.85;
    position: absolute;
    top: 55px;
    left: 15px;
    z-index: 100;
    pointer-events: none;
    display: flex;
    flex-direction: column;
}

#incorrect-regions-container {
    pointer-events: none;
    width: calc(min(150px, 20vw));
    min-width: 130px;
    text-align: left;
}

#incorrect-regions-container img {
    max-height: 32px;
    max-width: 32px;
}

#flag-container {
    position: relative;
    top: -15px;
    left: -10px;
    margin-bottom: -10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

#flag-container div {
    /* To make clicks pass through */
    pointer-events: none;
}

.infobox {
    background: rgba(0, 0, 0, 0.278);
    border-radius: 5%;
    color: white;
    padding: 5px;
}

#flag-container img {
    max-width: 128px;
    pointer-events: all;
}

.flag-border-correct-region {
    background: green;
    position: absolute;
    width: calc(100% - 16px);
    height: calc(100% - 16px);
    top: 0;
    left: 0;
    z-index: -1;
    border-radius: 5px;
    margin: 8px;
}

@media only screen and (max-width: 800px) {
    #flag-container {
        flex-direction: column;
        align-items: start;
    }

    #flag-container .infobox {
        position: relative;
        left: 10px;
    }

    #flag-container img {
        max-width: 100px;
    }
}
</style>