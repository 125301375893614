<template>
  <div class="flex-left-center" id="correct-flag-container">
        <img
            :src="getFlagUrl(correctRegionCode.code)"
        />
        <div class="flex-down-left" style="text-align: left">
            <span class="header">Correct answer</span>
            <span class="region-name">{{
                correctRegionCode.name
            }}</span>
        </div>
    </div>
</template>

<script>
import utilMixin from "../mixins/utilMixin";

export default {
    props: ["correctRegionCode"],
    mixins: [utilMixin],
}
</script>

<style>
#correct-flag-container img {
    height: 80px;
}

#correct-flag-container .header {
    font-size: 24px;
    color: grey;
}

#correct-flag-container .region-name {
    font-size: 32px;
    margin-top: 5px;
}

@media only screen and (max-width: 800px) {
    #correct-flag-container img {
        height: 60px;
    }

    #correct-flag-container .header {
        font-size: 18px;
    }

    #correct-flag-container .region-name {
        font-size: 24px;
    }

    #correct-flag-container {
        margin-bottom: -5px;
    }
}
</style>