<template>
    <div style="width:100%; height: 100%; position: absolute">
        <div class="floating-map" v-bind:class="{ 'large-map': isLarge }">
            <PartyGuessMap :canLarge="true" @large="setLarge"> </PartyGuessMap>
        </div>
        <BattleTable />
    </div>
</template>

<script>
import PartyGuessMap from "../../components/party/PartyGuessMap.vue";
import BattleTable from "../../components/party/BattleTable.vue";

export default {
    components: {
        PartyGuessMap,
        BattleTable,
    },
    data() {
        return {
            isLarge: false,
        };
    },
    methods: {
        setLarge(large) {
            this.isLarge = large;
        },
    },
};
</script>

<style>
.floating-map {
    position: absolute;
    bottom: 8px;
    right: 8px;
    z-index: 101;
}

@media only screen and (max-width: 600px) {
    .floating-map.large-map {
        bottom: 0;
        right: 0;
        height: 100%;
    }
}

#battle-table {
    top: 50px;
    left: 5px;
}
</style>