<template>
    <md-list-item class="small-list-item">
        <!-- <md-avatar>
            <img src="https://placeimg.com/40/40/people/8" alt="People" />
        </md-avatar> -->
        <md-icon>person</md-icon>

        <div class="md-list-item-text">
            <span
                >{{ `[${getDate(data)}] `
                }}{{ data.username ? data.username : "-" }}</span
            >
            <span>{{ data.userEmail }}</span>
            <p>
                {{ `[${getRequestedDate(data)}] `
                }}{{ data.comment ? data.comment : "No comment" }}
            </p>
        </div>

        <div>
            <md-button
                class="md-icon-button md-list-action"
                v-if="isPending || isBlacklist"
                @click="addToWhitelist"
                :disabled="sending"
            >
                <md-icon>done</md-icon>
                <md-tooltip md-delay="200" md-direction="top"
                    >Add to whitelist</md-tooltip
                >
            </md-button>
            <md-button
                class="md-icon-button md-list-action"
                v-if="isPending || !isBlacklist"
                @click="addToBlacklist"
                :disabled="sending"
            >
                <md-icon>block</md-icon>
                <md-tooltip md-delay="200" md-direction="top">{{
                    !isPending
                        ? "Remove from whitelist (does not delete account)"
                        : "Add to blacklist"
                }}</md-tooltip>
            </md-button>
        </div>
    </md-list-item>
</template>

<script>
import axiosMixin from "../../mixins/axiosMixin";

export default {
    props: {
        data: Object,
        isPending: Boolean,
        isBlacklist: Boolean,
    },
    data() {
        return {
            sending: false,
        };
    },
    mixins: [axiosMixin],
    methods: {
        addToWhitelist() {
            this.sending = true;
            this.makeRequest(
                "put",
                process.env.VUE_APP_SERVER_URL + "user/addToWhitelist",
                { email: this.data.userEmail }
            ).then((resp) => {
                this.sending = false;
                this.$emit("toWhitelist", this.data);
            });
        },
        addToBlacklist() {
            this.sending = true;
            this.makeRequest(
                "put",
                process.env.VUE_APP_SERVER_URL + "user/addToBlacklist",
                { email: this.data.userEmail }
            ).then((resp) => {
                if (resp) {
                    if (resp.status === 200) {
                        this.$emit("toBlacklist", this.data);
                    } else if (resp.status === 423) {
                        this.$emit("message", "Can't blacklist admin");
                    }
                }
                this.sending = false;
            });
        },
        getDate(e) {
            if (this.isPending) {
                return this.getRequestedDate(e);
            }
            return e.dateChanged
                ? this.getDateString(e.dateChanged)
                : "Ukn date";
        },
        getRequestedDate(e) {
            return e.dateRequested
                ? this.getDateString(e.dateRequested)
                : "Ukn date";
        },
        getDateString(d) {
            return new Date(d).toLocaleDateString("en-GB");
        },
    },
};
</script>

<style>
.small-list-item .md-list-item-container .md-list-item-content {
    min-height: 78px;
}
</style>