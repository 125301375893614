<template>
    <div id="content-container">
        <div style="width: 100%; height: 50vh; overflow: hidden">
            <MapImage
                style="width: 100%"
                :imgUrl="backgroundImg"
                :mapId="mapId"
                :largeImg="true"
            />
        </div>
        <div style="flex: auto"></div>
        <md-button
            id="start-btn"
            class="md-raised md-primary"
            @click="startMap(false)"
            style="width: fit-content"
            >Play Map</md-button
        >
        <div id="map-info">
            <span id="map-name" class="scaling-font-big">{{ mapName }}</span>
            <div class="md-layout md-alignment-center" v-if="highScores">
                <div
                    class="high-score-table md-layout-item md-xlarge-size-33 md-large-size-50 md-medium-size-75 md-small-size-75 md-xsmall-size-100 md-layout"
                >
                    <HighScoreFilters
                        id="filter"
                        :filterInit="filterLocal"
                        :guessRegionInit="guessRegion"
                        @filterChanged="filterChanged"
                        @guessRegionChanged="guessRegionChanged"
                        :canRegionGuess="canRegionGuess"
                    />

                    <HighscoreTable
                        v-if="highScores.length > 0"
                        :highscores="highScores"
                        :pageSize="10"
                    />
                    <md-card style="width: 100%" v-else>
                        <md-empty-state
                            md-icon="format_list_numbered"
                            md-label="Be the first to set a highscore"
                            md-description="No one has played with these settings before. Be the first one."
                        >
                            <md-button
                                class="md-primary md-raised"
                                @click="startMap(true)"
                                >Play with settings</md-button
                            >
                        </md-empty-state>
                    </md-card>
                    <HighScoreFilters
                        id="other-filter"
                        :filterInit="filterLocal"
                        :guessRegionInit="guessRegion"
                        @filterChanged="filterChanged"
                        @guessRegionChanged="guessRegionChanged"
                        :canRegionGuess="canRegionGuess"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axiosMixin from "../mixins/axiosMixin";
import HighscoreTable from "../components/HighscoreTable.vue";
import MapImage from "../components/MapImage.vue";
import HighScoreFilters from "./HighScoreFilters.vue";

export default {
    components: {
        HighscoreTable,
        MapImage,
        HighScoreFilters,
    },
    data() {
        return {
            mapId: -1,
            highScores: [],
            backgroundImg: "",
            mapName: "",

            filterLocal: {
                canMove: false,
                canZoom: false,
                canRotate: false,
            },
            guessRegion: false,
            canRegionGuess: false,
        };
    },

    methods: {
        startMap() {
            this.$router.push({
                name: "PreGame",
                params: {
                    mapIdIn: this.mapId,
                    settings: {
                        canMove: this.filterLocal.canMove,
                        canZoom: this.filterLocal.canZoom,
                        canRotate: this.filterLocal.canRotate,
                        guessRegion: this.guessRegion && this.canRegionGuess,
                    },
                },
            });
        },
        filterHighscores() {
            this.getHighScores();
            this.$router.push({
                query: { ...this.filterLocal, guessRegion: this.guessRegion && this.canRegionGuess },
            });
        },
        getHighScores() {
            axiosMixin.methods
                .makeRequest(
                    "get",
                    process.env.VUE_APP_SERVER_URL + "score/map/top10",
                    {
                        mapId: this.mapId,
                        ...this.filterLocal,
                        guessRegion: this.guessRegion && this.canRegionGuess,
                    }
                )
                .then((resp) => {
                    if (resp.status == 200) {
                        this.highScores = resp.data;
                    }
                });
        },
        filterChanged(val) {
            if (JSON.stringify(this.filterLocal) !== JSON.stringify(val)) {
                this.filterLocal = { ...val };
                this.filterHighscores();
            }
        },
        guessRegionChanged(val) {
            if (this.guessRegion != val) {
                this.guessRegion = val;
                this.filterHighscores();
            }
        },
    },

    mounted() {
        this.mapId = Number(this.$route.params.mapId);
        this.filterLocal = {
            canMove: this.$route.query.canMove !== "false",
            canZoom: this.$route.query.canZoom !== "false",
            canRotate: this.$route.query.canRotate !== "false",
            guessRegion: this.$route.query.guessRegion === "true",
            time: null,
        };

        axiosMixin.methods
            .makeRequest("get", process.env.VUE_APP_SERVER_URL + "map/byId", {
                mapId: this.mapId,
            })
            .then((resp) => {
                if (resp.status == 200) {
                    this.backgroundImg = resp.data.imgUrl;
                    this.mapName = resp.data.mapName;
                    this.canRegionGuess = resp.data.canRegionGuess;
                }
            });

        this.getHighScores();
    },
};
</script>

<style>
#content-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.white {
    background-color: aliceblue !important;
}

#map-name {
    padding: 5px 50px 15px 50px;
    background: #f0f8ffa3;
    border-radius: 15px;
}
#map-info {
    position: absolute;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    transform: translate(-50%, 0%);
    top: 40px;
    left: 50%;
}
#start-btn {
    margin: auto;
    margin-bottom: 10px;
}

.high-score-table {
    position: relative;
}

.entire-table {
    width: 100%;
}

#other-filter {
    display: none;
    margin: auto;
    margin-top: 10px;
    align-items: center;
}

#filter {
    position: absolute;
    top: 0;
    left: -68px;
}

@media only screen and (max-width: 600px) {
    #filter {
        display: none;
    }

    #other-filter {
        display: flex;
    }
}
</style>

<style>
@media only screen and (max-width: 600px) {
    .md-table-cell-container {
        padding: 0;
        text-align: center;
    }
}
</style>