import Vue from "vue";
import VueRouter from "vue-router";
import About from "../views/About.vue";
import Home from "../views/Home.vue";
import Game from "../views/Game.vue";
import PostGame from "../views/PostGame.vue";
import PreGame from "../views/PreGame.vue";
import MapEditorPanoramas from "../views/MapEditorPanoramas.vue";
import MapEditorOverview from "../views/MapEditorOverview.vue";
import AdvancedStatistics from "../views/AdvancedStatistics.vue";
import JoinGame from "../views/JoinGame.vue";
import Login from "../views/Login.vue";
import PartyGame from "../views/PartyGame.vue";
import HighScore from "../views/HighScore.vue";
import Profile from "../views/Profile.vue";
import Whitelist from "../views/Whitelist.vue";

import SideMenu from "../components/SideMenu.vue";

import store from "../store/index";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        components: {
            default: Home,
            SideMenu,
        },
        props: { default: { filterOnlyFavorites: false } },
    },
    {
        path: "/favorites",
        name: "Favorites",
        components: {
            default: Home,
            SideMenu,
        },
        props: { default: { filterOnlyFavorites: true } },
    },
    {
        path: "/myMaps",
        name: "MyMaps",
        components: {
            default: Home,
            SideMenu,
        },
        props: {
            default: { filterOnlyFavorites: false, filterOnlyMyMaps: true },
        },
    },
    {
        path: "/whitelist",
        name: "Whitelist",
        components: {
            default: Whitelist,
            SideMenu,
        },
    },
    {
        path: "/game",
        name: "Game",
        component: Game,
        props: true,
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
    },

    {
        path: "/profile",
        name: "Profile",
        components: {
            default: Profile,
            SideMenu,
        },
    },
    {
        path: "/profile/:tab",
        name: "Profile",
        components: {
            default: Profile,
            SideMenu,
        },
    },

    {
        path: "/preGame",
        name: "PreGame",
        component: PreGame,
        props: true,
    },
    {
        path: "/postGame",
        name: "PostGame",
        component: PostGame,
        props: true,
    },
    {
        path: "/joinGame",
        name: "JoinGame",
        component: JoinGame,
    },
    {
        path: "/highScore/:mapId",
        name: "HighScore",
        components: {
            default: HighScore,
            SideMenu,
        },
    },
    {
        path: "/about",
        name: "About",
        components: {
            default: About,
            SideMenu,
        },
    },

    // EDIT MAP
    { path: "/editMap", redirect: "/" },
    {
        path: "/editMap/:mapId",
        name: "EditMap",
        components: {
            default: MapEditorOverview,
            SideMenu,
        },
    },
    { path: "/editMapPanos", redirect: "/" },
    {
        path: "/editMapPanos/:mapId",
        name: "EditMapPanos",
        components: {
            ContentAndMenu: MapEditorPanoramas,
        },
    },
    // {
    //     path: "/test/",
    //     name: "TEST",
    //     components: {
    //         default: TESTPage,
    //         SideMenu,
    //     },
    // },

    { path: "/advStatistics", redirect: "/" },
    {
        path: "/advStatistics/:mapId",
        name: "AdvStatistics",
        components: {
            default: AdvancedStatistics,
            SideMenu,
        },
    },

    {
        path: "/multiplayer",
        name: "Multiplayer",
        component: PartyGame,
        props: (route) => ({
            ...route.params,
        }),
    },
];

const router = new VueRouter({
    mode: 'history',
    routes,
});

function hasToken() {
    const token = localStorage.getItem("token");
    return !!token;
}

// If no token then send to login
router.beforeEach((to, from, next) => {
    if (hasToken() || to.name === "Login" || to.name === "Multiplayer" || to.name === "About" || to.name.includes("TEST")) {
        // If the token exists try to set the profile info if not already set
        if (hasToken() && !store.getters.userProfileSet && to.name !== "Login") {
            // Try to log in if a token exists
            store.dispatch('setProfile', to.name === "Multiplayer");
        }
        next();
    } else {
        store.dispatch('clearTopBar');
        window.sessionStorage.clear();
        localStorage.removeItem("token");
        next({ name: "Login", query: to.name !== "Home" ? { nextUrl: to.fullPath } : {} });
    }
});

export default router;
