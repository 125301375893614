<template>
    <md-content
        :class="{ enabled: !disabled, disabled: disabled, 'md-elevation-2': !pressed }"
        class="scaling-font-small big-btn"
        v-on:click.stop="click"
    >
        <slot></slot>
    </md-content>
</template>

<script>
export default {
    props: {
        disabled: Boolean,
    },
    data() {
        return {
            pressed: false,
        }
    },
    methods: {
        click() {
            if (!this.disabled) {
                this.$emit("click");

                this.pressed = true;
                const _this = this;
                setTimeout(function () {
                    _this.pressed = false;
                }, 500);
            }
        },
    },
};
</script>

<style scoped>
.big-btn {
    padding: 20px !important;
    /*transition: 0.4s cubic-bezier(0.4, 0, 0.2, 1);*/
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    padding: 12px;
    width: fit-content;
    border-radius: 5px;
    margin: 10px auto;
    white-space: nowrap;
    max-width: fit-content;
}
.big-btn.enabled:hover {
    -webkit-filter: brightness(70%);
}

.big-btn.enabled {
    cursor: pointer;
}
.big-btn.disabled {
    opacity: 0.6;
}

.big-btn.pressed {
    -webkit-filter: brightness(70%);
}
</style>