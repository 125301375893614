<template>
    <div>
        <span class="text fat medium" v-if="header">{{header}}</span>
        <div class="info-row">
            <span class="text fat small">{{posText}}</span> <!-- You are:  -->
            <span class="text fat big">{{
                pos > 0 ? (pos + getOrdinal(pos)) : "&nbsp;-"
            }}</span>
        </div>
        <div class="info-row">
            <span class="text fat small">{{scoreString}}</span> <!-- Total score: -->
            <span class="text fat medium">{{
                score ? score : 0
            }}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        header: String,
        posText: String,
        pos: Number,
        scoreString: String,
        score: Number,
        regionCode: Object,
    },
    methods: {
        getOrdinal(number) {
            if (10 < number && number < 20) {
                return "th";
            }

            switch (number % 10) {
                case 1:
                    return "st";
                case 2:
                    return "nd";
                case 3:
                    return "rd";
                default:
                    return "th";
            }
        },
    },
};
</script>

<style score>
#info-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: top;
    row-gap: 2.5vh;
    margin-top: 10px;
}
.text {
    line-height: normal;
}
.fat {
    font-weight: bold;
}
.small {
    font-size: 18px;
}
.medium {
    font-size: 36px;
}
.big {
    font-size: 72px;
}
.region-text {
    color: red;
}
.correct {
    color: green;
}
</style>