<template>
    <span v-if="started && endTime > 0">{{ timeText }}&nbsp;s</span>
</template>

<script>
export default {
    data() {
        return {
            hasEnded: false,
            lowTimeWarning: false,
            currentTime: Date.now(),
            started: false,
        };
    },
    props: {
        startTime: {
            type: Number,
            default: -1,
        },
        endTime: Number,
    },
    watch: {
        endTime: function (time) {
            if (time > 0) {
                this.hasEnded = false;
                this.lowTimeWarning = false
                this.start();
            }
        },
    },
    computed: {
        timeText() {
            return this.timeLeft.toFixed(this.timeLeft <= 10 ? 1 : 0);
        },
        timeLeft() {
            const secLeft = (this.endTime - this.currentTime) / 1000;
            if (secLeft <= 10 && !this.lowTimeWarning) {
                this.onLowTimeWarning();
            }
            if (secLeft <= 0 && !this.hasEnded) {
                this.onEnd();
                clearInterval(this.timeInterval);
            }
            return secLeft > 0 ? secLeft : 0;
        },
    },
    methods: {
        onLowTimeWarning() {
            this.lowTimeWarning = true;
            this.$emit("lowTime");
        },
        onEnd() {
            this.hasEnded = true;
            this.$emit("end");
        },
        start() {
            clearInterval(this.timeInterval);
            this.timeInterval = setInterval(() => {
                this.currentTime = Date.now();
                if (!this.started && (this.startTime < 0 || this.currentTime > this.startTime)) {
                    this.started = true;
                    this.$emit("start");
                }
            }, 100);
        },
    },
    created() {
        this.start();
    },
    beforeDestroy() {
        clearInterval(this.timeInterval);
    },
};
</script>

<style scoped></style>
