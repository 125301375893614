import axiosMixin from "../../mixins/axiosMixin";

const mapUrls = {
    'de' : "https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png",
    'default' : "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
}

const state = {
    menuVisible: false,
    userProfile: {mapUrl: "de"},
    hasSideMenu: false,
    pendingWhitelist: 0,
    isNewVersion: false,
    version: "",
};

const getters = {
    menuVisible: (state) => state.menuVisible,
    userProfile: (state) => state.userProfile,
    isAdmin: (state) => state.userProfile.role === 1,
    hasSideMenu: (state) => state.hasSideMenu,
    userProfileSet: (state) => !!state.userProfile.userName,
    pendingWhitelist: (state) => state.pendingWhitelist,
    isNewVersion: (state) => state.isNewVersion,
    version: (state) => state.version,
    mapUrlFull: (state) => state.userProfile && mapUrls[state.userProfile.mapUrl] ? mapUrls[state.userProfile.mapUrl] : mapUrls['de'],
    mapUrl: (state) => state.userProfile && mapUrls[state.userProfile.mapUrl] ? state.userProfile.mapUrl : 'de',
};

const actions = {
    setProfile({ commit }, allowFail = false) {
        // Fetch the user data from the db
        return axiosMixin.methods
            .makeRequest("get", process.env.VUE_APP_SERVER_URL + "user/info", null, null, true, allowFail)
            .then((resp) => {
                if (resp && resp.status == 200) {
                    commit("setProfile", resp.data);
                    return resp;
                }
                return {}
            });
    },
    clearTopBar({ commit }) {
        commit("setProfile", {});
    },
    toggleMenuVisible({ state, commit }) {
        commit("setMenuVisible", !state.menuVisible);
    },
    updatePendingWhitelist({ state, commit }) {
        if (state.userProfile.role === 1) {
            axiosMixin.methods.makeRequest("get",
                process.env.VUE_APP_SERVER_URL +
                "user/getPendingWhitelistSize",
                null,
                null,
                true,
                true
            ).then((resp) => {
                commit("setPendingWhitelist", resp.data)
            });
        }
    },
    updateVersion({ commit }) {
        const lastVersion = localStorage.getItem("lastVersion");
        fetch("/info.json")
            .then((response) => response.json())
            .then((data) => {
                commit("setVersion", data.version)
                commit("setIsNewVersion", data.version != lastVersion);
            });
    },
    updateToNewVersion({ state, commit }) {
        fetch("/info.json")
            .then((response) => response.json())
            .then((data) => {
                commit("setVersion", data.version)
                commit("setIsNewVersion", false);
                localStorage.setItem("lastVersion", state.version);
            });
    }
};

const mutations = {
    setProfile: (state, profile) => {
        state.userProfile = profile;
    },
    setMenuVisible: (state, visible) => {
        state.menuVisible = visible;
    },
    setHasSideMenu: (state, hasSideMenu) => {
        state.hasSideMenu = hasSideMenu;
        setTimeout(() => window.dispatchEvent(new Event("resize")), 500);
    },
    setPendingWhitelist: (state, pendingWhitelist) => {
        state.pendingWhitelist = pendingWhitelist;
    },
    setVersion: (state, value) => {
        state.version = value;
    },
    setIsNewVersion: (state, value) => {
        state.isNewVersion = value;
    },
    setMapUrl: (state, value) => {
        state.userProfile.mapUrl = value;
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
};
