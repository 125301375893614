var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"position":"relative"},attrs:{"id":"canvas"}},[(_vm.displayCorrectGuess && _vm.getCorrectRegion)?_c('div',{attrs:{"id":"correct-guess-dialog"}},[_c('div',[_c('img',{attrs:{"src":_vm.getFlagUrl(_vm.getCorrectRegion.code)}}),_c('span',{staticClass:"md-title"},[_vm._v("Correct guess")])])]):_vm._e(),(
            !(
                _vm.roomState === _vm.State.NONE ||
                _vm.roomState === _vm.State.IN_GAME ||
                (_vm.roomState === _vm.State.END && _vm.hosting)
            )
        )?_c('md-button',{staticClass:"overlay md-raised md-icon-button",staticStyle:{"top":"3px","left":"0px","color":"white","background":"rgb(206, 58, 58)"},on:{"click":function($event){_vm.showConfirmLeave = true}}},[_c('md-icon',[_vm._v("exit_to_app")])],1):_vm._e(),(_vm.roomState === _vm.State.NONE)?_c('div',[_c('PrePartyGame',{ref:"prePartyGame",attrs:{"waitingForCreation":_vm.waitingForCreation,"refresh":_vm.refreshPreGame},on:{"joinRoom":_vm.joinRoom}})],1):(
            _vm.info.roles.includes(_vm.Role.SCORE_ACCESS) &&
            _vm.roomState === _vm.State.LOBBY
        )?[_c('HostLobby',{on:{"start":_vm.startGame}})]:(
            _vm.info.roles.includes(_vm.Role.SCORE_ACCESS) &&
            _vm.roomState === _vm.State.INTERMISSION
        )?[_c('HostIntermission',{on:{"start":_vm.nextRound,"cancel":_vm.onCancel}})]:(
            !_vm.info.roles.includes(_vm.Role.HOST) &&
            (_vm.roomState === _vm.State.LOBBY || _vm.roomState === _vm.State.INTERMISSION)
        )?[_c('PlayerLobby',{attrs:{"intermission":_vm.roomState === _vm.State.INTERMISSION}})]:(_vm.roomState === _vm.State.IN_GAME)?[(
                _vm.info.roles.includes(_vm.Role.PANORAMA_ACCESS) &&
                _vm.info.roles.includes(_vm.Role.MAP_ACCESS)
            )?[_c('HostInGame',[_c('PnMInGame')],1)]:(_vm.info.roles.includes(_vm.Role.PANORAMA_ACCESS))?[_c('HostInGame')]:[_c('PlayerInGame')]]:(_vm.roomState == _vm.State.DISPLAYING_ANSWERS)?[(_vm.info.roles.includes(_vm.Role.SCORE_ACCESS))?[_c('HostDisplayAnswers',{attrs:{"isTotal":false,"isEnd":false},on:{"nextRound":_vm.nextRound}})]:[_c('PlayerDisplayAnswers',{staticStyle:{"display":"flex","flex-direction":"column"},attrs:{"isEnd":false}})]]:(_vm.roomState === _vm.State.END)?[(_vm.info.roles.includes(_vm.Role.SCORE_ACCESS))?[_c('HostDisplayAnswers',{attrs:{"isTotal":true,"isEnd":true},on:{"continue":_vm.closeGame}})]:[_c('PlayerDisplayAnswers',{staticStyle:{"display":"flex","flex-direction":"column"},attrs:{"isEnd":true}})]]:(_vm.roomState === _vm.State.POST_LOBBY)?[(_vm.info.roles.includes(_vm.Role.HOST))?[_c('HostPostLobby',{on:{"endGame":_vm.closeRoom,"rehost":_vm.rehost}})]:[_c('span',{staticClass:"md-display-2",staticStyle:{"margin-top":"20px"}},[_vm._v("Waiting for host")])]]:_c('span',[_vm._v(" Error state ")]),_c('md-snackbar',{attrs:{"md-position":'center',"md-duration":4000,"md-active":_vm.snackbarVisible,"md-persistent":""},on:{"update:mdActive":function($event){_vm.snackbarVisible=$event},"update:md-active":function($event){_vm.snackbarVisible=$event}}},[_c('span',{staticStyle:{"color":"yellow"}},[_vm._v(_vm._s(_vm.message))]),_c('md-button',{staticStyle:{"color":"white"},on:{"click":function($event){_vm.snackbarVisible = false}}},[_vm._v("Close")])],1),_c('md-dialog-confirm',{attrs:{"md-active":_vm.showConfirmLeave,"md-title":_vm.hosting ? 'Close Room?' : 'Leave?',"md-content":_vm.hosting
                ? 'Do you want to close the room for everyone (room is deleted)'
                : `Do you want to leave the room? You can ${
                      _vm.roomState === _vm.State.LOBBY ? '' : 'NOT'
                  } rejoin.`,"md-confirm-text":"Yes","md-cancel-text":"No"},on:{"update:mdActive":function($event){_vm.showConfirmLeave=$event},"update:md-active":function($event){_vm.showConfirmLeave=$event},"md-confirm":_vm.leaveOrCloseRoom}}),_c('md-dialog',{attrs:{"md-active":_vm.showDisconnected,"md-close-on-esc":false,"md-click-outside-to-close":false},on:{"update:mdActive":function($event){_vm.showDisconnected=$event},"update:md-active":function($event){_vm.showDisconnected=$event}}},[_c('md-dialog-title',[_vm._v("You are disconnected!")]),_c('md-dialog-content',[_vm._v(" You are disconnected. You can try waiting a few seconds or refreshing the page. ")]),_c('md-dialog-actions',[_c('md-button',{on:{"click":_vm.goHome}},[_vm._v(" Return Home ")]),_c('md-button',{staticClass:"md-raised md-primary",on:{"click":_vm.refresh}},[_vm._v(" Refresh ")])],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }