<template>
    <md-dialog
        id="scoreDialog"
        :md-active.sync="alwaysShow"
        :md-click-outside-to-close="false"
        :md-close-on-esc="false"
        @keyup.space="shortcut"
    >
        <md-button
            class="md-icon-button md-raised md-primary"
            style="position: absolute; right: 20px; top: 20px"
            @click="() => setScoreDialogVis(false)"
        >
            <md-icon>close_fullscreen</md-icon>
        </md-button>
        <div class="md-layout md-gutter" style="margin: 0">
            <div
                class="md-layout-item md-size-66 md-small-size-100"
                style="min-height: 30%; padding: 0"
            >
                <InteractiveMap :markerGroups="getLastMarkerPosition" />
            </div>

            <div
                class="md-layout-item md-size-33 md-small-size-100 layout-right"
            >
                <div id="right-panel">
                    <div class="header">
                        <span class="scaling-font-big">{{
                            "Round " + getRoundText
                        }}</span>
                    </div>

                    <div v-if="isRegionGuessing">
                        <CorrectRegionDisplay
                            :correctRegionCode="correctRegionCode"
                        />
                    </div>

                    <div class="distance">
                        <span class="md-body-2"
                            >Your guess was
                            <b style="font-size: 20px">{{
                                getDistanceString(getCurrentDistance)
                            }}</b>
                            from the correct location.</span
                        >
                    </div>

                    <div class="score-bar">
                        <span class="md-body-1">{{
                            "Score: " + (getCurrentScore + "/" + getMaxScore)
                        }}</span>
                        <md-progress-bar
                            class="md-accent"
                            md-mode="determinate"
                            :md-value="(getCurrentScore / getMaxScore) * 100"
                        ></md-progress-bar>
                    </div>

                    <ScoreTable
                        :scores="getScores"
                        :distances="getDistances"
                        :sumRow="true"
                    />
                </div>
                <md-button
                    v-on:click="clickedNextRound"
                    :disabled="loading"
                    class="md-raised md-accent"
                    >{{
                        (isRegionGuessing &&
                            correctRegionCode.code != latestRegionCode.code) ||
                        (!isRegionGuessing && getRound >= getMaxRound)
                            ? "Game over"
                            : "Next round"
                    }}</md-button
                >
            </div>
        </div>
    </md-dialog>
</template>

<script>
import ScoreTable from "./ScoreTable";
import InteractiveMap from "./InteractiveMap.vue";

import { mapActions, mapGetters } from "vuex";

import utilMixin from "../mixins/utilMixin";
import CorrectRegionDisplay from "./CorrectRegionDisplay.vue";

export default {
    name: "ScoreDialog",
    mixins: [utilMixin],
    components: {
        InteractiveMap,
        ScoreTable,
        CorrectRegionDisplay,
    },
    data() {
        return {
            alwaysShow: true,
            unsubscribe: null,
            loading: false,
            prevCorrectRegionCode: {
                name: "",
                code: "AQ",
            },
            prevLatestRegionCode: {
                name: "",
                code: "AQ",
            },
        };
    },
    computed: {
        ...mapGetters([
            "isScoreDialogVisible",
            "isGameOver",
            "getCurrentScore",
            "getMaxScore",
            "getScores",
            "getDistances",
            "getCurrentDistance",
            "getRoundText",
            "getRound",
            "getMaxRound",
            "getLastMarkerPosition",
            "isRegionGuessing",
        ]),
        correctRegionCode() {
            if (
                this.getLastMarkerPosition &&
                this.getLastMarkerPosition.length > 0
            ) {
                return this.getLastMarkerPosition[0].correct.coord.regionCode;
            }
            return this.prevCorrectRegionCode;
        },
        latestRegionCode() {
            if (
                this.getLastMarkerPosition &&
                this.getLastMarkerPosition.length > 0
            ) {
                return this.getLastMarkerPosition[0].guesses[0].coord
                    .regionCode;
            }
            return this.prevLatestRegionCode;
        },
    },
    watch: {
        latestRegionCode: function () {
            if (this.latestRegionCode && this.latestRegionCode.name) {
                this.prevLatestRegionCode = this.latestRegionCode;
            }
        },
        correctRegionCode: function () {
            if (this.correctRegionCode && this.correctRegionCode.name) {
                this.prevCorrectRegionCode = this.correctRegionCode;
            }
        },
    },
    methods: {
        ...mapActions(["nextRound", "setScoreDialogVis"]),
        clickedNextRound() {
            this.loading = true;
            this.nextRound();
        },
        shortcut(event) {
            if (
                this.isScoreDialogVisible &&
                !this.loading &&
                event.keyCode === 32
            ) {
                // space
                this.clickedNextRound();
            }
        },
    },
    created() {
        window.addEventListener("keyup", this.shortcut);
    },
    mounted() {
        this.unsubscribe = this.$store.subscribe((mutation) => {
            if (mutation.type === "commitScoreDialogVisible") {
                const isShown = mutation.payload;

                if (isShown) {
                    this.loading = false;
                }

                document
                    .getElementById("scoreDialog")
                    .setAttribute(
                        "style",
                        `visibility: ${isShown ? "visible" : "hidden"}`
                    );
                document
                    .getElementsByClassName("md-overlay")[0]
                    .setAttribute(
                        "style",
                        `visibility: ${isShown ? "visible" : "hidden"}`
                    );
            }
        });

        if (!this.isScoreDialogVisible) {
            document
                .getElementById("scoreDialog")
                .setAttribute("style", "visibility: hidden");
            document
                .getElementsByClassName("md-overlay")[0]
                .setAttribute("style", "visibility: hidden");
        }
    },
    beforeDestroy() {
        window.removeEventListener("keyup", this.shortcut);
        this.unsubscribe();
    },
};
</script>

<style scoped>
.md-layout,
.md-dialog /deep/ .md-dialog-container {
    width: 100%;
    height: 100%;
}

@media only screen and (max-height: 700px) {
    .md-dialog /deep/ .md-dialog-container {
        min-width: 100%;
        max-height: 100%;
    }
}

#score-map {
    min-height: 100%;
    min-width: 100%;
}

.header {
    margin-top: calc(min(20px, 2vh));
}

.md-button {
    position: absolute;
    right: 20px;
    bottom: 20px;
}

.score-bar .md-body-1 {
    position: relative;
    top: 23px;
    left: 5px;
    z-index: 1;
}

.md-progress-bar {
    height: 25px;
}

.distance {
    margin-top: 10px;
}

.md-table .md-table-head-label,
.md-table .md-table-cell .md-table-cell-container {
    padding-right: 0;
    padding-left: 0;
}

div /deep/ .md-table-head-label {
    padding-left: 0;
}

div /deep/ .md-table-cell-container {
    padding-left: 5px;
}

@media (max-width: 900px) {
    .layout-right {
        padding: 0 20px !important;
        max-height: 70%;
    }

    #right-panel {
        overflow: scroll;
        max-height: 100%;
    }
}
.layout-right {
    margin: 0px;
}
</style>