<template>
    <div id="content-div" class="flex-down" @keyup.enter="joinRoom">
        <template>
            <div v-if="waitingForCreation">
                <span class="md-caption">Game is being created</span>
                <br />
                <md-progress-spinner
                    md-mode="indeterminate"
                    :md-diameter="20"
                    :md-stroke="3"
                ></md-progress-spinner>
            </div>
            <template v-else-if="loggedIn">
                <span class="md-caption"
                    >Create a game by selecting any map and then choosing party
                    mode</span
                >
                <br />
                <router-link to="/">
                    <md-button class="md-raised"> Select map </md-button>
                </router-link>
            </template>

            <span v-else class="md-caption"
                >Login to create your own lobby</span
            >
        </template>

        <div class="divider-with-text flex-center">
            <md-divider class="divider" />
            <span class="md-caption">OR</span>
            <md-divider class="divider" />
        </div>

        <md-field>
            <label>Room Code:</label>
            <md-input
                v-model="loc_roomCode"
                maxlength="6"
                md-counter="false"
                v-on:keypress="isLetter($event)"
                ref="roomCodeInput"
                :disabled="hasLobby"
            ></md-input>
        </md-field>

        <md-field :class="{ 'md-invalid': incorrectUsername }">
            <label>Username:</label>
            <md-input
                v-model="loc_username"
                maxlength="12"
                v-on:keypress="isValidUsernameSymbol($event)"
                :disabled="hasLobby"
            ></md-input>
            <span class="md-error">{{ usernameError }}</span>
        </md-field>
        <div v-if="hasLobby" style="margin-top: 15px">
            <span class="md-caption"
                >You have already host a Lobby, click to rejoin.
            </span>
            <span class="md-caption" style="white-space: nowrap"
                >Close it to join someone else's.</span
            >
        </div>
        <div>
            <md-button
                class="md-raised"
                id="join_btn"
                :disabled="waitingForCreation"
                v-on:click="joinRoom"
                >{{ hasLobby ? "Rejoin as Host" : "Re/Join Room" }}</md-button
            >
        </div>
        <div v-if="hasLobby">
            <md-button class="md-raised" v-on:click="closeExistingLobby" style="color:red">
                Close existing lobby
            </md-button>
        </div>
    </div>
</template>

<script>
import utilMixin from "../../mixins/utilMixin";
import { mapActions, mapGetters, mapState } from "vuex";
import axiosMixin from "../../mixins/axiosMixin";

export default {
    mixins: [utilMixin, axiosMixin],
    data() {
        return {
            loc_username: "",
            incorrectUsername: false,
            usernameError: "",

            loc_roomCode: "",

            hasLobby: false,
        };
    },
    props: {
        waitingForCreation: Boolean,
        refresh: Boolean,
    },
    watch: {
        loc_roomCode: function () {
            this.loc_roomCode = this.loc_roomCode.toUpperCase();
        },
        refresh: function() {
            this.checkIfHasRoom();
        },
    },
    computed: {
        ...mapState("partygame", [
            "socket",
            "username",
            "roomCode",
            "playerId",
        ]),
        ...mapGetters([
            "userProfile",
            "userProfileSet",
        ]),
        loggedIn() {
            return !!localStorage.getItem("token"); // TODO validate
        },
    },
    methods: {
        ...mapActions("partygame", [
            "setUsername",
            "setRoomCode",
            "setPlayerId",
        ]),
        isLetter(e) {
            let char = String.fromCharCode(e.keyCode);
            if (/^[A-Za-zåäöÅÄÖ]+$/.test(char)) return true;
            else e.preventDefault();
        },
        isValidUsernameSymbol(e) {
            let char = String.fromCharCode(e.keyCode);
            if (/^[a-zA-Z0-9åäöÅÄÖ _-]+$/.test(char)) return true;
            else e.preventDefault();
        },
        createRoom() {
            const newGameRequest = {
                mapId: 1,
                canMove: true,
                canZoom: true,
                canPan: true,
                time: 11,
                authHeader: "Bearer " + localStorage.getItem("token"),
            };
            this.$emit("create");
            this.socket.emit("create", newGameRequest);
        },

        validateUsername() {
            // Validate username
            this.incorrectUsername = false;
            let temp = this.loc_username.trim();
            do {
                temp = temp.replaceAll("  ", " ");
            } while (temp.includes("  "));

            this.loc_username = temp;
            if (this.loc_username.length < 2) {
                this.usernameError = "Username too short (min 2)";
                this.incorrectUsername = true;
            } else if (this.loc_username.length > 12) {
                this.usernameError = "Username too long (max 12)";
                this.incorrectUsername = true;
            } else if (!/^[a-zA-Z0-9åäöÅÄÖ _-]+$/.test(this.loc_username)) {
                // TODO remove?
                this.usernameError =
                    "Only letters, numbers, spaces and '_', '-'";
                this.incorrectUsername = true;
            }

            let oneLetter = false;
            for (var i = 0; i < this.loc_username.length; i++) {
                if (
                    this.loc_username
                        .charAt(i)
                        .toLowerCase()
                        .match(/[a-zåäö]/i)
                ) {
                    oneLetter = true;
                    break;
                }
            }
            if (!oneLetter) {
                this.usernameError = "Must contain at least one letter";
                this.incorrectUsername = true;
            }
        },

        joinRoom() {
            if (!this.hasLobby) {
                this.validateUsername();

                if (this.incorrectUsername) {
                    return;
                }
            }

            this.loc_roomCode = this.loc_roomCode.toUpperCase();
            this.setUsername(this.loc_username);
            this.setRoomCode(this.loc_roomCode);
            this.$emit("joinRoom");
        },

        checkIfHasRoom() {
            if (this.loggedIn) {
                this.makeRequest(
                    "get",
                    process.env.VUE_APP_SERVER_URL + "user/hasLobby"
                ).then((resp) => {
                    if (!resp) return;
                    this.hasLobby = resp.data.hasLobby;
                    if (this.hasLobby) {
                        this.loc_roomCode = resp.data.roomCode;
                    }
                });
            }
        },
        closeExistingLobby() {
            this.socket.emit("close", {
                roomCode: this.roomCode,
                authHeader: "Bearer " + localStorage.getItem("token"),
            });
            this.hasLobby = false;
        }
    },
    mounted() {
        this.loc_username = this.username;
        if (!this.loc_username && this.userProfileSet) {
            this.loc_username = this.userProfile.userName;
            this.setUsername(this.userProfile.userName);
        }

        if (this.$route.query.roomCode) {
            this.loc_roomCode = this.$route.query.roomCode;
            this.setRoomCode(this.loc_roomCode);
        } else {
            this.loc_roomCode = this.roomCode;
        }
        this.checkIfHasRoom();
    },
};
</script>

<style scoped>
@media only screen and (min-width: 1200px) {
    #content-div {
        max-width: 33vw;
    }
}
@media only screen and (min-width: 600px) and (max-width: 1200px) {
    #content-div {
        max-width: 50vw;
    }
}
@media only screen and (max-width: 600px) {
    #content-div {
        max-width: 90vw;
    }
}

#content-div {
    margin: auto;
    margin-top: 30px;
}

.divider-with-text {
    position: relative;
}

.divider-with-text span {
    padding: 4px;
}

.divider {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 50%;
}

#join_btn {
    margin-top: 15px;
}
</style>
