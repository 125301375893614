import { Loader } from "@googlemaps/js-api-loader";

const state = {
    loader: null,
    callbacks: [],
    libraries: ["places"],
};

const getters = {};

const actions = {
    loadGoogle({ commit, state }) {
        if (!state.loader || (!state.loader.loading && !state.loader.done)) {
            // It needs to be loaded
            commit("commitNewLoader");
            return state.loader.load().then(() => {
                return;
            });
        } else if (state.loader.done) {
            // It is already loaded
            return Promise.resolve();
        } else {
            // Somebody else is loading it
            return waitUntilLoaded();
        }
    },
};

function waitUntilLoaded() {
    return new Promise(function(resolve, reject) {
        (function waitForGoogle() {
            if (state.loader.done) {
                return resolve();
            } else if (!state.loader.loading) {
                return reject();
            } else {
                setTimeout(waitForGoogle, 30);
            }
        })();
    });
}

const mutations = {
    commitNewLoader: (state) => {
        state.loader = new Loader({
            apiKey: process.env.VUE_APP_API_KEY,
            version: "weekly",
            libraries: state.libraries,
        });
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
