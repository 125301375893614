import io from "socket.io-client";

const ROOM_STATE = {
    NONE: -1,
    LOBBY: 0,
    IN_GAME: 1,
    DISPLAYING_ANSWERS: 2,
    END: 3,
};

const stateInit = {
    playerMap: new Map(),
    hosting: false,
    info: {},
    answersPerRound: {},
    competitors: [],
    roomState: ROOM_STATE.NONE,

    // TEAM
    teamList: [],
    teammateGuesses: [],
    // PLAYER GEUSS
    location: {},
    guesserResponse: {},
    toGuess: true,
    madeGuesses: [],
    temporaryGuess: undefined,

    battleRoyal: {},

    damageMultiplier: 1,
    eliminationWarning: false,
    guessesLeft: 1,
    center: { center: [0, 0], zoom: 0 },
    team: { teamId: null, name: null },
    playerImg: null,
    endTime: -1,
    startTime: -1,
    message: "",
    showSnackbar: false,

    currentRegionCode: {},
    
    guessBonuses: [],
};

const state = {
    ...stateInit,

    socket: null,
    username: localStorage.getItem("username") || "",
    roomCode: localStorage.getItem("roomCode") || "",
    playerId: localStorage.getItem("playerId"),
    publicPlayerId: localStorage.getItem("publicPlayerId"),
}

const getters = {
    guessesLeft(state) {
        return state.guessesLeft;
    },
    hasGuessesLeft(state) {
        return state.guessesLeft > 0 && !state.guesserResponse.correctRegion;
    },
    playerList(state) {
        return [...state.playerMap.values()];
    },
    partyGameType(state) {
        return state.info.settings ? state.info.settings.partyGameType : 0;
    },
    isTeams(state) {
        return (state.info.settings ? state.info.settings.partyGameType : 0) === 1;
    },
    getCorrectRegion(state) {
        return state.guesserResponse.correctRegion;
    },
    getIncorrectRegions(state) {
        return state.guesserResponse.incorrectRegions;
    },
    getRoundText(state) {
        return `Round ${state.info.round}${state.info.settings.duel || state.info.settings.elimination ? "" : `/${state.info.settings.maxRounds}`}`;
    },
};

const actions = {
    connectSocket({ commit }) {
        commit("CONNECT");
    },
    disconnectSocket({ state }) {
        if (state.socket) {
            if (state.playerId && state.roomState == ROOM_STATE.LOBBY) {
                state.socket.emit("leave", state.playerId);
            }
            state.socket.disconnect();
        }
    },
    incRound({ state, commit }) {
        let info = state.info;
        info.round += 1;
        commit("setInfo", info);
    },
    setUsername({ commit }, username) {
        commit("SET_AND_STORE", { key: "username", value: username });
    },
    setPlayerId({ commit }, id) {
        commit("SET_AND_STORE", { key: "playerId", value: id });
    },
    setPublicPlayerId({ commit }, id) {
        commit("SET_AND_STORE", { key: "publicPlayerId", value: id });
    },
    setRoomCode({ commit }, roomCode) {
        commit("SET_AND_STORE", { key: "roomCode", value: roomCode });
    },
    sendEvent({ state }, { event, payload }) {
        if (state.socket) {
            state.socket.sendEvent(event, payload);
        } else {
            console.log("Socket not connected.", event, payload);
        }
    },
    clearEndTime({ commit }) {
        commit("setEndTime", -1);
    },
    clearStartTime({ commit }) {
        commit("setStartTime", -1);
    },
    setPlayerList({ commit }, players) {
        commit("setPlayerMap", playerListToMap(players));
    },
    addPlayer({ state, commit }, player) {
        state.playerMap.set(player.id, player);
        commit("setPlayerMap", state.playerMap);
    },
    removePlayer({ state, commit }, player) {
        state.playerMap.delete(player.id);
        commit("setPlayerMap", state.playerMap);
    },
    addTeam({ state }, teamName) {
        state.socket.emit("createTeam", {
            roomCode: state.roomCode,
            authHeader: "Bearer " + localStorage.getItem("token"),
            payload: teamName,
        });
    },
    removeTeam({ state }, teamId) {
        state.socket.emit("deleteTeam", {
            roomCode: state.roomCode,
            authHeader: "Bearer " + localStorage.getItem("token"),
            payload: teamId,
        });
    },
    setTeamList({ commit }, teams) {
        commit("setTeamList", teams);
    },
    sendAnswer({ state, commit }, answer) {
        answer = {
            ...answer,
            temporary: false,
            regionCode: {
                ...state.currentRegionCode
            },
        }
        commit("commitGuess", answer);
        commit("decGuessesLeft");
        commit("setCurrentRegionCode", {});
        state.socket.emit("answer", {
            coord: answer,
            playerId: state.playerId,
        });
    },
    sendTempAnswer({ state }, answer) {
        state.socket.emit("tempAnswer", {
            coord: answer,
            playerId: state.playerId,
        });
    },
    setGuesserResponse({commit, dispatch}, guesserResponse) {
        commit("commitGuesserResponse", guesserResponse);
        dispatch('setUsername', guesserResponse.playerName);
    }
};

function playerListToMap(playerList) {
    let m = new Map();
    playerList.map((player) => {
        m.set(player.id, player);
    });
    return m;
}

const mutations = {
    CONNECT: (state) => {
        if (state.socket) {
            state.socket.disconnect();
        }
        let url = process.env.VUE_APP_SOCKET_URL;
        if (!url.startsWith("http")) {
            const urlBase = `${window.location.protocol}//${window.location.hostname}`;
            url = urlBase +":"+ url;
        }

        Object.keys(stateInit).forEach(k => state[k] = stateInit[k]);
        state.socket = io(url, {
            transports: ["websocket", "polling"],
        });
    },
    resetData(state) {
        Object.keys(stateInit).forEach(k => state[k] = stateInit[k]);
    },
    SET_AND_STORE: (state, { key, value }) => {
        localStorage.setItem(key, value);
        state[key] = value;
    },
    setRoomState: (state, roomState) => {
        state.roomState = roomState;
    },
    showNewMessage: (state, message) => {
        state.message = message.trim();
        if (state.message.length > 0) {
            state.showSnackbar = !state.showSnackbar;
        }
    },
    setInfo: (state, info) => {
        state.info = info;
    },
    commitGuess: (state, guess) => {
        state.madeGuesses = [...state.madeGuesses, guess];
    },
    setCenter: (state, center) => {
        state.center = center;
    },
    setMapResponse: (state, response) => {
        state.toGuess = true;
        state.center = response.center;
        state.madeGuesses = response.answers.filter(g => !g.temporary);
        state.temporaryGuess = response.answers.filter(g => g.temporary)[0];
        state.teammateGuesses = response.teammateGuesses;   
    },
    setLocation: (state, location) => {
        state.location = location;
    },
    setScoreResponse: (state, payload) => {
        state.answersPerRound = payload.answersPerRound;
        state.competitors = payload.competitors;
        state.center = payload.center;        
    },

    setCurrentRegionCode: (state, regionCode) => {
        state.currentRegionCode = regionCode;
    },
    setCorrectRegionCode: (state, regionCode) => {
        state.guesserResponse.correctRegion = regionCode;
    },
    addIncorrectRegionCode: (state, regionCode) => {
        if (state.guesserResponse.incorrectRegions && !state.guesserResponse.incorrectRegions.find((r) => r.code == regionCode.code)) {
            state.guesserResponse.incorrectRegions.push(regionCode);
        }
    },

    decGuessesLeft: (state) => {
        state.guessesLeft--;
    },
    incGuessesLeft: (state) => {
        state.guessesLeft++;
    },

    setHosting: (state, isHosting) => {
        state.hosting = isHosting;
    },
    setEndTime: (state, endTime) => {
        if (endTime > 0) {
            state.endTime = Date.now() + endTime;
        } else {
            state.endTime = 0;
        }
    },
    setStartTime: (state, startTime) => {
        state.startTime = Date.now() + startTime;
    },
    setPlayerMap(state, players) {
        state.playerMap = new Map(players);
    },
    setToAnswer(state, toAnswer) {
        state.toAnswer = toAnswer;
    },
    commitGuesserResponse(state, guesserResponse) {
        state.guesserResponse = {
            playerScore: guesserResponse.playerScore, 
            teamScore: guesserResponse.teamScore, 
            correctRegion: guesserResponse.correctRegion, 
            incorrectRegions: guesserResponse.incorrectRegions
        };
        state.battleRoyal = {
            indexInSurroundingAnswers: guesserResponse.indexInSurroundingAnswers,
            surroundingAnswers: guesserResponse.surroundingAnswers,
            position: guesserResponse.playerScore ? guesserResponse.playerScore.position : -1
        }
        state.guessesLeft = guesserResponse.guessesLeft;
        state.guessBonuses = guesserResponse.guessBonuses;
    },
    setTeamList(state, teams) {
        state.teamList = teams;
    },

    setPlayerImg(state, img) {
        state.playerImg = img;
    },
    setDamageMultiplier(state, multiplier) {
        state.damageMultiplier = multiplier;  
    },
    // HOST TEAM MUTATIONS
    commitTeam(state, team) {
        state.teamList.push(team);
    },
    deleteTeam(state, teamId) {
        state.teamList = state.teamList.filter(team => team.teamId != teamId);
    },
    playerJoinedTeam(state, { playerId, team }) {
        const u = {...state.playerMap.get(playerId), teamId: team.teamId};
        state.playerMap.set(playerId, u);
        state.playerMap = new Map(state.playerMap);
    },

    // PLAYER TEAM MUTATIONS
    setTeam(state, team) {
        state.team = team;
    },
    teammateAnswered(state, guess) {
        state.teammateGuesses = state.teammateGuesses.filter(g => !(g.id == guess.id && g.temporary))
        state.teammateGuesses.push(guess);
    },
    setTeammateAnswers(state, guesses) {
        state.teammateGuesses = guesses;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
