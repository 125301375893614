import Vue from "vue";
import App from "./App.vue";

import "leaflet/dist/leaflet.css";
import { Icon } from "leaflet";
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

import VueMaterial from "vue-material";
import "vue-material/dist/vue-material.min.css";
import router from "./router";
import "./assets/global.css";

import Clipboard from "v-clipboard";
import store from "./store";

Vue.use(Clipboard);

Vue.use(VueMaterial);

import GAuth from 'vue-google-oauth2';
const gauthOption = {
    clientId: process.env.VUE_APP_CLIENT_ID + '.apps.googleusercontent.com',
    scope: 'profile email', // https://developers.google.com/identity/protocols/oauth2/scopes
    prompt: 'select_account' // possible values: select_account, consent
};
Vue.use(GAuth, gauthOption);

import Vuelidate from "vuelidate";
import './registerServiceWorker'
Vue.use(Vuelidate);

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
