import { render, staticRenderFns } from "./PostGame.vue?vue&type=template&id=34f78087&scoped=true"
import script from "./PostGame.vue?vue&type=script&lang=js"
export * from "./PostGame.vue?vue&type=script&lang=js"
import style0 from "./PostGame.vue?vue&type=style&index=0&id=34f78087&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34f78087",
  null
  
)

export default component.exports