<template>
    <div class="md-layout flex-grow" id="display-answers-container">
        <div
            class="md-layout-item md-small-size-50 md-xsmall-size-100"
            style="position: relative"
        >
            <InteractiveMap
                id="display-answers-map"
                :markerGroups="guessMarkers"
                :settings="center"
                :centerMapAtStart="true"
            />
            <template v-if="!isTotal">
                <div class="overlay-bar" />
                <span class="md-headline overlay top left">{{
                    getRoundText
                }}</span>
                <span id="roomCodeSpan" class="md-headline overlay top right">{{
                    roomCode
                }}</span>
            </template>
        </div>

        <div
            class="md-layout-item md-small-size-50 md-xsmall-size-100 score-table-container flex-down"
        >
            <PlayerBanner v-if="info.roles.includes('GUESSER')" />
            <span v-if="isEnd" class="md-display-3"
                >Final {{ info.settings.duel ? "Standings" : "Scores" }}</span
            >
            <template v-else-if="correctRegionCode">
                <div
                    class="flex-center"
                    style="
                        padding-left: 5px;
                        padding-right: 5px;
                        max-width: 100vw;
                    "
                >
                    <BonusGuessDisplay
                        v-if="
                            info.settings.elimination &&
                            info.settings.partyGameType === 0 &&
                            info.roles.includes('GUESSER')
                        "
                    />
                </div>
                <CorrectRegionDisplay :correctRegionCode="correctRegionCode" />
            </template>
            <md-table
                class="flex-grow"
                id="score-table"
                v-model="listRows"
                md-fixed-header
            >
                <md-table-row
                    slot="md-table-row"
                    slot-scope="{ item, index }"
                    class="big-row"
                    :class="{ 'player-self': item.playerId === publicPlayerId }"
                    :style="{
                        'background-color': item.color ? item.color : 'none',
                    }"
                >
                    <md-table-cell v-if="item.img && !item.teamId" md-label=""
                        ><md-icon
                            :class="{ 'md-size-2x': screenWidth > 1400 }"
                            >{{ item.img }}</md-icon
                        ></md-table-cell
                    >
                    <md-table-cell
                        v-else-if="item.teamId && !isTotal"
                        md-label=""
                        >{{
                            item.playerName ? item.playerName : "-"
                        }}</md-table-cell
                    >
                    <md-table-cell md-label="">{{
                        item.text
                            ? item.text
                            : item.teamName
                            ? item.teamName
                            : item.playerName
                    }}</md-table-cell>
                    <md-table-cell
                        :md-label="
                            useHealth ? 'Health' : isTotal ? 'Total' : 'Score'
                        "
                        :class="{ emph: index == 0 }"
                    >
                        <template
                            v-if="!useHealth && !info.settings.guessRegion"
                        >
                            {{
                                Math.max(
                                    totalScore(item.playerId, item.teamId),
                                    0
                                )
                            }}
                        </template>
                        <template
                            v-else-if="
                                !info.settings.elimination &&
                                !info.settings.guessRegion
                            "
                        >
                            {{
                                Math.max(
                                    remainingHealth(item.playerId, item.teamId),
                                    0
                                )
                            }}
                        </template>
                        <template v-else-if="!info.settings.elimination">
                            {{
                                Math.max(
                                    totalCorrectRegions(
                                        item.playerId,
                                        item.teamId
                                    ),
                                    0
                                )
                            }}
                        </template>
                        <div v-else class="flex-start">
                            <template
                                v-if="
                                    remainingHealth(
                                        item.playerId,
                                        item.teamId
                                    ) > 0
                                "
                            >
                                <md-icon
                                    class="heart"
                                    :class="{
                                        'md-size-2x': screenWidth > 1400,
                                    }"
                                    v-for="(h, i) in remainingHealth(
                                        item.playerId,
                                        item.teamId
                                    )"
                                    :key="i"
                                >
                                    favorite
                                </md-icon>
                            </template>
                            <span v-else>Out</span>
                        </div>
                    </md-table-cell>
                    <template v-if="!isTotal">
                        <md-table-cell
                            v-if="!info.settings.guessRegion"
                            md-label="Round"
                            :class="{ emph: isBestThisRound(item.score) }"
                            >{{ item.score }}
                        </md-table-cell>
                        <md-table-cell
                            md-label="Guess"
                            v-if="info.settings.guessRegion"
                        >
                            <div class="flex-center">
                                <template
                                    v-if="
                                        item.regionCode && item.regionCode.code
                                    "
                                >
                                    <div
                                        @mouseover="showRegionName = true"
                                        @mouseleave="showRegionName = false"
                                        @click="
                                            showRegionName = !showRegionName
                                        "
                                    >
                                        <img
                                            :src="
                                                getFlagUrl(item.regionCode.code)
                                            "
                                            class="guess-flag"
                                        />
                                        <md-tooltip
                                            md-direction="left"
                                            :md-active.sync="showRegionName"
                                            >{{
                                                item.regionCode.name
                                            }}</md-tooltip
                                        >
                                    </div>
                                    <md-icon
                                        style="margin: 0"
                                        v-if="
                                            correctRegionCode.code ===
                                            item.regionCode.code
                                        "
                                    >
                                        done
                                    </md-icon>
                                    <md-icon style="margin: 0" v-else>
                                        close
                                    </md-icon>
                                </template>
                                <div v-else>-</div>
                            </div>
                        </md-table-cell>
                        <md-table-cell md-label="Distance">{{
                            item.distance > 0
                                ? getDistanceString(Math.round(item.distance))
                                : "-"
                        }}</md-table-cell>
                    </template>
                </md-table-row>
            </md-table>

            <BigButton
                v-if="info.host"
                class="bottom-right md-primary"
                v-on:click="nextRound"
                >{{ isEnd ? "Continue" : "Next" }}</BigButton
            >
            <div class="flex-end" v-else>
                <p class="bottom-right">
                    {{
                        isEnd
                            ? "Wait for host"
                            : "Wait for host to start next round"
                    }}
                </p>
            </div>
        </div>

        <md-dialog
            :md-active.sync="showScoreClash"
            :md-click-outside-to-close="false"
            style="margin: auto"
            :md-fullscreen="false"
        >
            <ScoreClash
                :team1="team1"
                :team2="team2"
                :round="info.round"
                :duelHP="info.settings.duelHP"
                style="width: min(100vw, 500px)"
                @close="showScoreClash = false"
            />
        </md-dialog>
    </div>
</template>

<script>
import ScoreClash from "../../components/party/ScoreClash";
import InteractiveMap from "../../components/InteractiveMap";
import BigButton from "../../components/BigButton";
import utilMixin from "../../mixins/utilMixin";
import { mapState, mapGetters } from "vuex";
import PlayerBanner from "../../components/party/PlayerBanner.vue";
import BonusGuessDisplay from "../../components/party/BonusGuessDisplay.vue";
import CorrectRegionDisplay from "../../components/CorrectRegionDisplay.vue";

export default {
    mixins: [utilMixin],
    components: {
        InteractiveMap,
        BigButton,
        ScoreClash,
        PlayerBanner,
        BonusGuessDisplay,
        CorrectRegionDisplay,
    },
    props: {
        isTotal: Boolean,
        isEnd: Boolean,
    },
    data() {
        return {
            showScoreClash: false,
            showRegionName: false,
        };
    },
    computed: {
        ...mapState("partygame", [
            "answersPerRound",
            "competitors",
            "info",
            "roomCode",
            "publicPlayerId",
            "center",
        ]),
        ...mapGetters("partygame", ["getRoundText"]),
        screenWidth() {
            return window.innerWidth;
        },
        useHealth() {
            return (
                this.info.settings.duel ||
                (this.info.settings.guessRegion &&
                    this.info.settings.elimination)
            );
        },
        listRows() {
            if (this.isTotal) {
                const c = this.competitors.map((x) => x);
                c.sort(this.totalScoreSorter);
                return c;
            } else {
                if (!this.answersPerRound[this.info.round]) {
                    return [];
                }
                const g = this.answersPerRound[this.info.round].guesses;
                g.sort(this.answersPerRoundSorter);
                return g;
            }
        },
        correctRegionCode() {
            if (!this.answersPerRound[this.info.round]) {
                return null;
            }
            return this.answersPerRound[this.info.round].correct.coord
                .regionCode;
        },
        guessMarkers() {
            return Object.values(this.answersPerRound);
        },
        team1() {
            return this.info.settings.duel
                ? this.competitors.reduce((prev, current) => {
                      return prev.teamId < current.teamId ? prev : current;
                  })
                : -1;
        },
        team2() {
            return this.info.settings.duel
                ? this.competitors.reduce((prev, current) => {
                      return prev.teamId > current.teamId ? prev : current;
                  })
                : -1;
        },
    },
    methods: {
        isBestThisRound(score) {
            let max = this.answersPerRound[this.info.round]
                ? Math.max(
                      ...this.answersPerRound[this.info.round].guesses.map(
                          (x) => x.score
                      )
                  )
                : 1;

            return score >= Math.max(max, 1);
        },
        totalScore(playerId, teamId) {
            if (teamId != null) {
                return this.competitors.find((o) => o.teamId === teamId)
                    .totalScore;
            } else {
                return this.competitors.find((o) => o.playerId === playerId)
                    .totalScore;
            }
        },
        totalCorrectRegions(playerId, teamId) {
            if (teamId != null) {
                return this.competitors.find((o) => o.teamId === teamId)
                    .totalCorrectRegions;
            } else {
                return this.competitors.find((o) => o.playerId === playerId)
                    .totalCorrectRegions;
            }
        },
        remainingHealth(playerId, teamId) {
            if (teamId != null) {
                return this.competitors.find((o) => o.teamId === teamId).health;
            } else {
                return this.competitors.find((o) => o.playerId === playerId)
                    .health;
            }
        },
        shortcut() {
            this.nextRound();
        },
        nextRound() {
            if (!this.isEnd) {
                this.$emit("nextRound");
            } else {
                this.$emit("continue");
            }
        },
        /* For sorting the list of answers per round */
        answersPerRoundSorter(a, b) {
            if (this.useHealth) {
                const hA = this.remainingHealth(a.playerId, a.teamId);
                const hB = this.remainingHealth(b.playerId, b.teamId);
                if (hA < hB) {
                    return 1;
                } else if (hA > hB) {
                    return -1;
                }
            }

            if (this.info.settings.guessRegion) {
                const cA = this.totalCorrectRegions(a.playerId, a.teamId);
                const cB = this.totalCorrectRegions(b.playerId, b.teamId);
                if (cA < cB) {
                    return 1;
                } else if (cA > cB) {
                    return -1;
                }
            }

            const totA = this.totalScore(a.playerId, a.teamId);
            const totB = this.totalScore(b.playerId, b.teamId);
            if (totA < totB) {
                return 1;
            } else if (totA > totB) {
                return -1;
            }
            return 0;
        },

        totalScoreSorter(a, b) {
            if (this.useHealth) {
                if (a.health < b.health) {
                    return 1;
                } else if (a.health > b.health) {
                    return -1;
                }
            }

            if (this.info.settings.guessRegion) {
                if (a.totalCorrectRegions < b.totalCorrectRegions) {
                    return 1;
                } else if (a.totalCorrectRegions > b.totalCorrectRegions) {
                    return -1;
                }
            }

            if (a.totalScore < b.totalScore) {
                return 1;
            } else if (a.totalScore > b.totalScore) {
                return -1;
            }
            return 0;
        },
    },
    created() {
        if (this.info.settings.duel) {
            this.showScoreClash = true;
        }
    },
    mounted() {
        window.addEventListener("keyup", this.shortcut);
    },
    beforeDestroy() {
        window.removeEventListener("keyup", this.shortcut);
    },
};
</script>

<style scoped>
.big-row /deep/ .md-table-cell-container {
    padding: 6px 6px 6px 6px;
}

.score-table-container ::v-deep .md-table-head-label {
    padding-right: 6px;
    padding-left: 6px;
    font-size: 24px;
}

.left {
    margin-left: 50px;
}

.big-row td {
    font-size: 2vw;
}

@media only screen and (max-width: 900px) {
    .score-table-container ::v-deep .md-table-head-label {
        font-size: 14px;
    }

    .big-row td {
        font-size: 18px;
        padding: 0 !important;
    }
}

@media only screen and (min-width: 900px) and (max-width: 2000px) {
    .score-table-container ::v-deep .md-table-head-label {
        font-size: 18px;
    }

    .big-row td {
        font-size: 24px;
    }
}
@media only screen and (min-width: 2000px) {
    .big-row td {
        font-size: 42px;
    }
}

.bottom-right {
    opacity: 0.7;
    margin-right: 10px;
}

.emph {
    font-weight: bold;
}

.score-table-container {
    max-height: calc(100vh - 48px);
    overflow: auto;
    max-width: 100vw;
}

.big-row.player-self {
    background-color: #44546a;
    color: white;
}

.heart {
    color: red !important;
    margin: -3px;
    text-shadow: 2px 2px 5px black;
}

.guess-flag {
    height: 50px;
}

@media only screen and (max-width: 600px) {
    #display-answers-container {
        flex-direction: column;
        flex-wrap: nowrap;
    }

    .guess-flag {
        height: 38px;
    }
}

#score-table {
    max-width: 100vw;
}

.bonus-guesses div {
    margin-top: 2px;
    margin-bottom: 2px;
}
</style>

<style>
.md-dialog-container {
    max-width: 100%;
}

#score-table .md-table-fixed-header {
    min-width: 400px;
}

@media only screen and (max-width: 900px) {
    .md-table-head-container {
        padding: 0;
        height: auto;
    }
}
</style>
