<template>
    <div>
        <!-- Input for time setting -->
        <div id="time-input">
            <label for="time-range" class="md-headline">{{
                "Max time per round: " + getTimeFromStepper(timeAmount)
            }}</label>
            <input
                :disabled="locked"
                id="time-range"
                type="range"
                min="-2"
                max="11"
                v-model.number="timeAmount"
            />
        </div>

        <!-- Switches for settings -->
        <div id="switch-group">
            <div id="general-settings" class="md-layout">
                <div class="md-layout-item switch-div">
                    <switches
                        v-model="noMove"
                        class="switch"
                        :disabled="locked"
                        color="blue"
                    ></switches
                    ><span>No Move</span
                    ><md-tooltip md-direction="bottom" md-delay="500"
                        >When active disallowes moving during the
                        game</md-tooltip
                    >
                </div>
                <div class="md-layout-item switch-div">
                    <switches
                        v-model="noZoom"
                        class="switch"
                        :disabled="locked"
                        color="blue"
                    ></switches
                    ><span>No Zoom</span
                    ><md-tooltip md-direction="bottom" md-delay="500"
                        >When active panoramas cannot be zoomed</md-tooltip
                    >
                </div>
                <div class="md-layout-item switch-div">
                    <switches
                        v-model="noPan"
                        class="switch"
                        :disabled="locked"
                        color="blue"
                    ></switches
                    ><span>No Pan</span
                    ><md-tooltip md-direction="bottom" md-delay="500"
                        >When active panoramas cannot be interacted with (no
                        rotation, zooming etc.)</md-tooltip
                    >
                </div>
            </div>
            <template v-if="mode === 'party' || mode === 'multi'">
                <span class="md-headline" style="text-align: center;">{{
                    mode === "party" ? "Party Settings" : "Multiplayer Settings"
                }}</span>
                <div class="md-layout" id="party-game-type-div">
                    <md-radio
                        :disabled="locked"
                        v-model="partyGameType"
                        :value="0"
                        >Free-For-All</md-radio
                    >
                    <md-radio
                        :disabled="locked"
                        v-model="partyGameType"
                        :value="1"
                        >Team</md-radio
                    >
                    <md-radio
                        :disabled="locked"
                        v-model="partyGameType"
                        :value="2"
                        >Battle Royal</md-radio
                    >
                </div>

                <!-- Input for time setting -->
                <template v-if="timerStartAtGuess && partyGameType < 2">
                    <label for="time-at-guess-range" class="md-subheading">{{
                        "Time after first guess: " +
                        getTimeFromStepper(timeAtGuessAmount)
                    }}</label>
                    <input
                        :disabled="locked"
                        id="time-at-guess-range"
                        type="range"
                        min="-2"
                        :max="timeAtGuessMaxAmount"
                        v-model.number="timeAtGuessAmount"
                        style="margin-bottom: 10px"
                    />
                </template>

                <div class="md-layout md-alignment-center-left">
                    <template v-if="partyGameType < 2">
                        <div
                            class="md-layout-item md-size-33 md-xsmall-size-50 switch-div"
                        >
                            <switches
                                v-model="timerStartAtGuess"
                                class="switch"
                                :disabled="locked"
                                color="blue"
                            ></switches
                            ><span>Timer start at guess</span>
                            <md-tooltip md-direction="bottom" md-delay="500"
                                >Timer starts when the first guess has been
                                made.</md-tooltip
                            >
                        </div>
                    </template>

                    <template v-if="partyGameType < 2 && canRegionGuess">
                        <div
                            class="md-layout-item md-size-33 md-xsmall-size-50 switch-div"
                        >
                            <switches
                                v-model="guessRegion"
                                class="switch"
                                :disabled="locked"
                                color="blue"
                            ></switches
                            ><span>Guess country/region</span>
                            <md-tooltip md-direction="bottom" md-delay="500"
                                >Guess is correct if it is in the right
                                country/region</md-tooltip
                            >
                        </div>
                    </template>

                    <template v-if="partyGameType == 1 && !guessRegion">
                        <div
                            class="md-layout-item md-size-33 md-xsmall-size-50 switch-div"
                        >
                            <switches
                                v-model="duel"
                                class="switch"
                                :disabled="locked"
                                color="blue"
                            ></switches
                            ><span>Duel</span>
                            <md-tooltip md-direction="bottom" md-delay="500"
                                >Teams' scores are used as damage. The game ends
                                when one team runs out of health.</md-tooltip
                            >
                        </div>
                    </template>

                    <div
                        class="md-layout-item md-size-33 md-xsmall-size-50 switch-div"
                        v-if="
                            partyGameType < 2 && canRegionGuess && guessRegion
                        "
                    >
                        <switches
                            v-model="eliminationMode"
                            class="switch"
                            :disabled="locked"
                            color="blue"
                        ></switches
                        ><span>Elimination mode</span>
                        <md-tooltip md-direction="bottom" md-delay="500"
                            >Players take damage if they fail to guess the
                            correct region.</md-tooltip
                        >
                    </div>

                    <div
                        v-if="
                            (partyGameType == 1 && duel) ||
                            (partyGameType < 2 &&
                                canRegionGuess &&
                                guessRegion &&
                                eliminationMode)
                        "
                        class="flex-down md-layout-item md-size-33 md-xsmall-size-50"
                        style="max-width: 0"
                    >
                        <md-field
                            :class="validateDuelHP"
                            id="duel-hp-container"
                        >
                            <label for="duel-hp">{{
                                duel ? "Duel Hitpoints" : "Lives"
                            }}</label>
                            <md-input
                                :disabled="locked"
                                id="duel-hp"
                                v-model="duelHP"
                                type="number"
                                min="1"
                                :max="duel ? 100000 : 10"
                                :placeholder="duel ? '6000' : '3'"
                            ></md-input>
                            <span class="md-error"
                                >Must be between 1 and
                                {{ duel ? "100000" : "10" }}</span
                            >
                        </md-field>
                    </div>
                    <template
                        v-if="
                            partyGameType === 2 ||
                            (partyGameType < 2 && guessRegion)
                        "
                    >
                        <div
                            class="flex-center md-layout-item"
                            style="margin-top: 5px"
                        >
                            <div class="flex-down-right">
                                <label
                                    for="guesses-input"
                                    style="text-align: left"
                                >
                                    {{
                                        partyGameType === 0 && eliminationMode
                                            ? "Guesses for surviving"
                                            : "Guesses / round"
                                    }}: {{ guessesPerRound }}
                                </label>
                                <input
                                    :disabled="locked"
                                    class="input-field"
                                    id="guesses-input"
                                    type="range"
                                    :min="partyGameType === 2 ? 2 : 1"
                                    max="7"
                                    v-model.number="guessesPerRound"
                                />
                            </div>
                        </div>
                    </template>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import Switches from "vue-switches";

export default {
    components: {
        Switches,
    },
    props: {
        mode: String,
        mapId: Number,
        canRegionGuess: Boolean,
        locked: Boolean,
        initialSettings: Object,
    },
    watch: {
        settingsValid() {
            this.$emit("validChanged", this.settingsValid);
        },
        settings() {
            this.$emit("settingsChanged", this.settings);
        },

        noPan: function (val) {
            if (val) {
                this.noZoom = true;
                this.noMove = true;
            }
        },
        noMove: function (val, oldVal) {
            if (!val && val != oldVal) {
                this.noPan = false;
            }
        },
        noZoom: function (val, oldVal) {
            if (!val && val != oldVal) {
                this.noPan = false;
            }
        },
        timeAmount: function (val) {
            if (val) {
                this.timeAtGuessMaxAmount = Math.min(val - 1, 2);
                this.timeAtGuessAmount = Math.min(
                    this.timeAtGuessAmount,
                    this.timeAtGuessMaxAmount
                );
            }
        },
        mode: function (val) {
            if (val !== "party" && val !== "multi") {
                this.timerStartAtGuess = false;
            }
        },
        guessRegion: function (val) {
            if (val) {
                this.duelHP = 3;
                this.duel = false;
            } else {
                this.duelHP = 6000;
            }
        },
        duel: function (val) {
            if (val) {
                this.duelHP = 6000;
                this.guessRegion = false;
            } else {
                this.duelHP = 3;
            }
        },
    },
    computed: {
        settingsValid() {
            const numberInvalid = this.partyGameType === 2 && (this.guessesPerRound > 5 || this.guessesPerRound < 2);
            return !(this.eliminationMode || this.duel) || (!this.validateDuelHP["md-invalid"] && !numberInvalid);
        },
        validateDuelHP() {
            const hp = parseInt(this.duelHP);
            return { "md-invalid": hp < 1 || hp > (this.duel ? 100000 : 10) };
        },
        settings() {
            return {
                mapId: this.mapId,
                canMove: !this.noMove,
                canZoom: !this.noZoom,
                canPan: !this.noPan,
                time: this.timeAmount,
                partyGameType: this.partyGameType,
                guessesPerRound:
                    this.partyGameType === 2 || this.guessRegion
                        ? this.guessesPerRound
                        : 1,
                eliminationMode:
                    this.eliminationMode &&
                    this.partyGameType < 2 &&
                    this.guessRegion,
                timerStartAtGuess:
                    this.partyGameType === 2
                        ? false
                        : this.timerStartAtGuess,
                timeAtGuess: this.timeAtGuessAmount,
                duel: this.duel,
                duelHP: parseInt(this.duelHP),
                roomType: this.mode === "party" ? 0 : 1,
                guessRegion: this.guessRegion,
            }
        }
    },
    data() {
        return {
            noMove: false,
            noZoom: false,
            noPan: false,

            partyGameType: 0,
            eliminationMode: false,
            guessesPerRound: 2,

            timeAmount: 11,
            timerStartAtGuess: false,
            timeAtGuessAmount: -1,
            timeAtGuessMaxAmount: 2,

            guessRegion: false,

            duel: false,
            duelHP: 3,
        };
    },
    methods: {
        getTimeFromStepper(stepperNr) {
            return stepperNr > 10
                ? "Unlimited"
                : stepperNr > 0
                ? stepperNr + " min"
                : stepperNr < -2
                ? "5 s"
                : 30 / (1 - stepperNr) + " s";
        },
        getIfNotUndefined(value, defaultValue) {
            return value !== undefined ? value : defaultValue;
        }
    },
    mounted() {
        if (this.initialSettings) {
            this.noMove = !this.initialSettings.canMove;
            this.noZoom = !this.initialSettings.canZoom;
            this.noPan = !this.initialSettings.canRotate;

            this.timeAmount = this.getIfNotUndefined(this.initialSettings.time, this.timeAmount);
            this.partyGameType = this.getIfNotUndefined(this.initialSettings.partyGameType, this.partyGameType);
            this.eliminationMode = this.getIfNotUndefined(this.initialSettings.eliminationMode, this.eliminationMode);
            this.guessesPerRound = this.getIfNotUndefined(this.initialSettings.guessesPerRound, this.guessesPerRound);
            this.timerStartAtGuess = this.getIfNotUndefined(this.initialSettings.timerStartAtGuess, this.timerStartAtGuess);
            this.timeAtGuessAmount = this.getIfNotUndefined(this.initialSettings.timeAtGuess, this.timeAtGuessAmount);
            this.duel = this.getIfNotUndefined(this.initialSettings.duel, this.duel);
            this.duelHP = this.getIfNotUndefined(this.initialSettings.duelHP, this.duelHP);
            this.guessRegion = this.getIfNotUndefined(this.initialSettings.guessRegion, this.guessRegion);
        }
        this.$emit("settingsChanged", this.settings);
    },
};
</script>

<style scoped>
#time-input {
    display: flex;
    flex-direction: column;
}

.input-field {
    max-width: 200px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.switch-div {
    display: inline-flex;
}

#switch-group {
    display: flex;
    flex-direction: column;
    gap: 3px;
    margin-top: 20px;
    max-width: 600px;
}

#party-game-type-div {
    margin: auto;
}

#general-settings {
    border: 4px dotted #b8b8b8;
    padding: 5px;
    padding-top: 9px;
}

#general-settings div {
    justify-content: center;
}
</style>

<style>
#duel-hp-container {
    margin-bottom: 0px;
}
</style>