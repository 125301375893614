<template>
    <div v-if="isCountingDown" class="overlay-group">
        <span
            class="center damage-multiplier"
            style="z-index: 1000;"
            v-if="damageMultiplier > 1"
        >
            <span style="margin-right: 15px" class="md-headline">Damage</span>
            <span style="font-size: 2em;">x{{damageMultiplier}}</span>
        </span>
        <div class="countdown-circle">
            <md-progress-spinner md-mode="determinate" :md-value="getCountdownPercentage" :md-diameter="circleDiameter" class="md-accent" :md-stroke="25" />
            <div class="countdown-text md-display-4">{{ Math.max(0, Math.ceil(getCountdownTime / 1000)) }}</div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
      return {
            currentTime: -1,
            circleDiameter: 250,
            countdownHasBeenSet: false,
      }  
    },
    props: {
        countdownEndTime: {
            type: Number,
            default: -1,
        },
        damageMultiplier: {
            type: Number,
            default: 1,
        }
    },
    computed: {
        isCountingDown() {
            if (this.countdownEndTime > 0 && ((this.countdownEndTime - this.currentTime) < 10000000)) {
                return this.countdownEndTime - this.currentTime > 0;
            }
            return false;
        },

        getCountdownTime() {
            if (this.isCountingDown) {
                return this.countdownEndTime - this.currentTime;
            }
            return -1;
        }, 

        /**
         * Get the percentage of the current second of the countdown
         */
        getCountdownPercentage() {
            if (this.isCountingDown) {
                return 100 - ((this.getCountdownTime % 1000) / 10); // /1000 * 100
            }
            return 0;
        }
    },
    created() {
        clearInterval(this.timeInterval);
        this.timeInterval = setInterval(() => {
            this.currentTime = Date.now();
            if (this.countdownEndTime - this.currentTime < 0) {
                clearInterval(this.timeInterval);
                this.$emit("countdownStopped");
            }
        }, 10);
    },
    beforeDestroy() {
        clearInterval(this.timeInterval);
    },
}
</script>


<style scoped>
    .damage-multiplier {
        color: black;
        background-color: #fff33dad;
        display: flex;
        justify-content: center;
        position: absolute;
        top: 50px;
        border-radius: 25px;
        padding: 15px 20px;
        font-size: 30px;
        transform: translate(-50%, 0);
        pointer-events: none;
    }

    .overlay-group {
        flex-direction: column;
    }

    .overlay-group, .countdown-circle {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .countdown-text {
        position: absolute;
        color: white;
    }
</style>
<style>
    .countdown-circle .md-progress-spinner-circle {
        transition: none !important;
    }
</style>