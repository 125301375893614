<template>
    <div
        class="progress-bar"
        style="display: flex"
        :style="{
            backgroundColor: lighterColor,
            'justify-content': invert ? 'end' : 'left',
        }"
    >
        <div
            class="progress"
            :style="{ width: progress + '%', backgroundColor: color }"
            :class="{ animate: animate }"
        ></div>
    </div>
</template>

<script>
import * as jcolor from "color";

export default {
    data() {
        return {
            progress: 100,
        };
    },
    props: {
        value: Number,
        invert: {
            type: Boolean,
            default: false,
        },
        color: {
            type: String,
            default: "#4caf50",
        },
        animate: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        lighterColor() {
            return jcolor(this.color).lighten(0.5).hex();
        },
    },
    watch: {
        value(newValue) {
            this.progress = newValue;
        },
    },
    mounted() {
        this.progress = this.value;
    },
};
</script>

<style>
.progress-bar {
    width: 100%;
    height: 20px;
    position: relative;
}

.progress {
    height: 100%;
}

.progress.animate {
    transition: width 1.5s ease-out;
}
</style>
