<template>
    <div id="intermission">
        <div class="top-bar md-layout">
            <div id="countdown" class="md-layout-item md-xsmall-size-100 row">
                <template v-if="info.host">
                    <span class="sf-big">Game starts in: </span>
                    <Timer
                        class="sf-big bold"
                        :endTime="endTime"
                        @end="onTimerEnd"
                    />
                </template>
                <span v-else class="sf-big md-xsmall-size-100">Game starts soon</span> <!-- TODO make countdown accurate -->
            </div>

            <template v-if="info.host">
                <BigButton
                    id="cancel-btn"
                    class="md-raised opt-btn md-layout-item row"
                    @click="onCancel"
                >
                    <span>Cancel</span>
                </BigButton>

                <BigButton
                    class="md-raised md-primary opt-btn md-layout-item row"
                    @click="onTimerEnd"
                >
                    <span>Start</span>
                    <br v-if="!smallScreen" />
                    <span>now</span>
                </BigButton>
            </template>
        </div>

        <div id="player-container" class="md-layout md-alignment-center-center">
            <template v-if="partyGameType === 1">
                <div class="md-layout md-alignment-top-center" id="team-list">
                    <TeamCard
                        v-for="(team, index) in filteredTeamList"
                        class="md-layout-item"
                        style="margin-bottom: 10px"
                        :key="index"
                        :localTeam="team"
                        :playerList="playerList"
                        :canDelete="false"
                        :cantRemovePlayers="true"
                        :waitingForJoin="true"
                    />
                </div>
            </template>

            <template v-else>
                <div style="width: 100%" class="md-layout md-alignment-center-center">
                    <div
                        v-for="player in playerList"
                        :key="player.id"
                        class="md-layout-item md-large-size-20 md-medium-size-20 md-small-size-33 md-xsmall-size-100 flex-center"
                    >
                        <PlayerPill :player="player" :nonRemovable="true" :isTeams="false" :idOfActivePlayer="publicPlayerId" />
                    </div>
                </div>
            </template>
        </div>

        <div
            style="height: calc(min(15vh, 250px)); position: relative; overflow: hidden"
        >
            <MapImage style="width:100%; position: relative" class="background" :imgUrl="info.imgUrl" :largeImg="true"/>
            <div style="position: absolute; top: 0; z-index: 1; height: 100%; width: 100%; display: flex; justify-content: center; align-items: center">
                <span style="color: white" :class="{'md-display-2': smallScreen, 'md-display-3': !smallScreen && mediumScreen, 'md-display-4': !mediumScreen && !smallScreen}">{{info.mapName}}</span>
            </div>
            <div style="position: absolute; background: black; opacity: 0.3; width: 100%; height: 100%; top: 0"></div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import PlayerPill from "../../components/party/PlayerPill.vue";
import TeamCard from "../../components/party/TeamCard.vue";
import Timer from "../../components/Timer.vue";
import BigButton from "../../components/BigButton.vue";
import MapImage from "../../components/MapImage.vue";

const countdownTime = 10;

export default {
    components: {
        PlayerPill,
        TeamCard,
        Timer,
        BigButton,
        MapImage,
    },
    data() {
        return {
            endTime: -1,
            canceled: false,
            screenWidth: window.innerWidth,
            screenHeight: window.innerHeight,
        };
    },
    methods: {
        onTimerEnd() {
            if (!this.canceled) {
                this.$emit("start");
            }
        },
        onCancel() {
            this.canceled = true;
            this.$emit("cancel");
        },
        updateScreenSize() {
            this.screenWidth = window.innerWidth;
            this.screenHeight = window.innerHeight;
        }
    },
    computed: {
        ...mapState("partygame", ["socket", "roomCode", "info", "teamList", "publicPlayerId"]),
        ...mapGetters("partygame", ["playerList", "partyGameType"]),
        filteredTeamList() {
            const teamsWithPlayers = this.playerList.map((t) => t.teamId);
            return this.teamList.filter((t) =>
                teamsWithPlayers.includes(t.teamId)
            );
        },
        smallScreen() {
            return this.screenWidth < 600 || this.screenHeight < 600;
        },
        mediumScreen() {
            return !this.smallScreen && (this.screenWidth >= 600 && this.screenWidth < 1279) || (this.screenHeight >= 600 && this.screenHeight < 1279);
        }
    },
    created() {
        this.endTime = Date.now() + countdownTime * 1000;
        window.addEventListener("resize", this.updateScreenSize);
    },
    destroyed() {
        window.removeEventListener("resize", this.updateScreenSize);
    },
};
</script>

<style scoped>
.top-bar .row{
    margin-bottom: 10px;
}

#intermission {
    height: 100%;
    display: flex;
    flex-direction: column;
}

#player-container {
    flex: 1;
}

.top-bar {
    margin-top: 10px;
    margin-right: 10px;
    margin-left: 10px;
}

.opt-btn {
    height: 20vh;
    max-height: 100px;
    margin-top: 0;
    margin-bottom: 0;
    padding: auto !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#cancel-btn {
    background: #ce3a3a;
    color: white;
}

.bold {
    font-weight: bold;
}
</style>

<style>
@media only screen and (max-width: 600px) {
    #intermission .md-card {
        margin-right: 0px !important;
        margin-left: 0px !important;
    }
}
</style>