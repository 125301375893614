<template>
    <div
        class="full-container flex-grow flex flex-column"
        @keyup.space="doSendAnswer"
        :class="{ 'large-map': isLarge }"
    >
        <div class="map-container flex-grow flex" style="position: relative">
            <InteractiveMap
                ref="interactiveMap"
                class="flex-grow"
                :canPlaceMarker="toGuess && hasGuessesLeft"
                :settings="mapSettings"
                :oldMarkers="madeGuesses"
                :teammateGuesses="teammateGuesses"
                :canLarge="canLarge"
                :isLarge="isLarge"
                :initGuess="temporaryGuess"
                @markerPlaced="setMarkerPlaced"
                @toggleMapSize="toggleMapSize"
                @markerPosUpdate="doSendTempAnswer"
                :guessRegionCode="currentRegionCode"
            />
            <slot :sendAnswer="doSendAnswer" />
        </div>
        <md-button
            class="md-raised"
            id="guess-btn"
            :disabled="!markerPlaced || !(toGuess && hasGuessesLeft)"
            :class="{
                enable: markerPlaced,
                'md-primary': markerPlaced,
                disable: !markerPlaced,
                'large-map': isLarge,
            }"
            v-on:click="doSendAnswer"
            >{{
                !hasGuessesLeft
                    ? "Waiting" + (guessesLeft > 0 && canCarryOverGuesses ? ` (${guessesLeft}) carried over` : " for others")
                    : markerPlaced
                    ? `Send Answer (${guessesLeft} left)`
                    : `(${guessesLeft}) guess${
                          guessesLeft > 1 ? "es" : ""
                      } left`
            }}</md-button
        >
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import InteractiveMap from "../../components/InteractiveMap";

import utilMixin from "../../mixins/utilMixin";

export default {
    mixins: [utilMixin],
    components: {
        InteractiveMap,
    },
    data() {
        return {
            markerPlaced: false,
            isLarge: false,
        };
    },
    props: {
        canLarge: Boolean,
        startLarge: Boolean,
    },
    computed: {
        ...mapState("partygame", [
            "socket",
            "playerId",
            "center",
            "toGuess",
            "madeGuesses",
            "info",
            "teammateGuesses",
            "temporaryGuess",
            "currentRegionCode"
        ]),
        ...mapGetters("partygame", ["hasGuessesLeft", "guessesLeft"]),
        mapSettings() {
            return {
                zoom: this.center.zoom,
                center: this.center.center,
                clickableIcons: false,
                keyboardShortcuts: false,
                disableDefaultUI: true,
            };
        },
        canCarryOverGuesses() {
            return this.info.settings.elimination && this.info.settings.partyGameType === 0;
        }
    },
    methods: {
        ...mapActions("partygame", ["sendAnswer", "sendTempAnswer"]),
        ...mapMutations("partygame", ["teammateAnswered", "setCurrentRegionCode"]),
        doSendTempAnswer() {
            this.clearRegionCode();
            this.sendTempAnswer(this.$refs.interactiveMap.markerLatLng);
        },
        doSendAnswer() {
            if (this.markerPlaced) {
                this.sendAnswer(this.$refs.interactiveMap.markerLatLng);
                this.$refs.interactiveMap.clear();
            }
        },
        setMarkerPlaced(markerPlaced) {
            this.markerPlaced = markerPlaced;
        },
        toggleMapSize() {
            this.isLarge = !this.isLarge;
            this.$emit("large", this.isLarge);
        },
        clearRegionCode() {
            this.setCurrentRegionCode({});
        }
    },

    created() {
        this.isLarge = this.startLarge;
        this.screen = screen;
        this.socket.on("teammate_answered", (payload) => {
            this.teammateAnswered(payload);
        });
    },
};
</script>

<style scoped>
.full-container {
    height: 100%;
    width: 100%;
    opacity: 0.5;
    z-index: 102;

    max-height: calc(100% - 25px);
    min-height: 200px;
    max-width: 100%;
    height: 35vh;
    width: max(25vw, 200px);
}

.map-container {
    width: 100%;
}

.full-container:hover {
    opacity: 1;
    transition-property: opacity;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
}

.full-container.large-map {
    height: max(80vh, 700px);
    width: max(60vw, 400px);
    opacity: 1;
}

@media only screen and (max-width: 600px) {
    .full-container.large-map {
        height: 100%;
        width: 100vw;
        max-width: 100vw;
        opacity: 1;
    }

    .large-map #size-toggle-btn {
        position: relative;
        top: 45px;
    }

    #guess-btn:not(.large-map) {
        margin: 8px 0;
    }
}

@media only screen and (min-width: 600px) {
    #guess-btn {
        margin: 8px 0;
    }
}

.enable:hover {
    background-color: rgba(0, 201, 33) !important;
    color: white !important;
}

.disable {
    background-color: #b0b0b0 !important;
    color: rgb(77, 77, 77) !important;
}
</style>

<style>
@media only screen and (max-width: 600px) {
    .large-map #size-toggle-btn {
        position: relative;
        top: 45px;
    }
}
</style>
