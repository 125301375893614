<template>
    <div class="filter">
        <div class="filter-btn">
            <md-button
                v-if="canRegionGuess"
                :class="{ 'md-primary': guessRegion }"
                class="md-icon-button md-raised region-streak"
                @click="guessRegion = !guessRegion"
            >
                <md-icon>
                    public
                </md-icon>
            </md-button>
        </div>
        <div class="">
            <md-button class="md-fab filter-header">
                <md-icon>filter_alt</md-icon>
            </md-button>
        </div>
        <div class="filter-btn">
            <md-button
                class="md-icon-button md-raised"
                :class="{ 'md-primary': !filter.canMove }"
                @click="filter.canMove = !filter.canMove"
            >
                <md-icon>do_not_step</md-icon>
            </md-button>
        </div>
        <div class="filter-btn">
            <md-button
                class="md-icon-button md-raised"
                :class="{ 'md-primary': !filter.canZoom }"
                @click="filter.canZoom = !filter.canZoom"
            >
                <md-icon>search_off</md-icon>
            </md-button>
        </div>
        <div class="filter-btn">
            <md-button
                class="md-icon-button md-raised"
                :class="{ 'md-primary': !filter.canRotate }"
                @click="filter.canRotate = !filter.canRotate"
            >
                <md-icon>do_not_touch</md-icon>
            </md-button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        filterInit: Object,
        guessRegionInit: Boolean,
        canRegionGuess: Boolean,
    },
    data() {
        return {
            filter: {},
            guessRegion: {},
        };
    },
    watch: {
        guessRegion () {
            this.$emit('guessRegionChanged', this.guessRegion);
        },

        filterInit: function (val) {
            this.filter = { ...val };
        },

        guessRegionInit: function (val) {
            this.guessRegion = val;
        },

        "filter.canRotate": function (val) {
            if (!val) {
                this.filter.canZoom = false;
                this.filter.canMove = false;
            }
            this.$emit('filterChanged', this.filter);
        },
        "filter.canMove": function (val) {
            if (val) {
                this.filter.canRotate = true;
            }
            this.$emit('filterChanged', this.filter);
        },
        "filter.canZoom": function (val) {
            if (val) {
                this.filter.canRotate = true;
            }
            this.$emit('filterChanged', this.filter);
        },
    },
    mounted() {
        this.filter = {...this.filterInit};
        this.guessRegion = this.guessRegionInit;
    }
};
</script>

<style>
.filter-header {
    cursor: crosshair !important;
    pointer-events: none !important;
}

.filter-btn {
    margin: 5px;
}
</style>