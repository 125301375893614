<template>
    <div id="battle-table">
        <div v-if="b.position > -1 && b.surroundingAnswers.length > 1">
            <div class="container">
                <div>
                    <span class="md-title" style="max-width: 190px; padding: 0 5px">{{
                        b.indexInSurroundingAnswers == 0
                            ? "You are the closest"
                            : closerAnswers[0].username + " is closest"
                    }}</span>
                </div>
                <div
                    class="container-item"
                    v-for="(ans, index) in closerAnswers"
                    :key="`c${index}`"
                >
                    <span>{{
                        b.position + index - b.indexInSurroundingAnswers + 1
                    }}</span>
                    <span>{{ ans.username }}</span>
                </div>
                <div
                    class="container-item-wide"
                    v-if="closerAnswers.length > 0"
                >
                    <span
                        ><md-icon>north</md-icon
                        >{{
                            getDistanceString(
                                Math.round(
                                    closerAnswers[closerAnswers.length - 1]
                                        .distance
                                )
                            )
                        }}</span
                    >
                </div>

                <div class="container-item">
                    <span>{{ b.position + 1 }}</span>
                    <span>YOU</span>
                </div>

                <div
                    class="container-item-wide"
                    v-if="furtherAnswers.length > 0"
                >
                    <span
                        ><md-icon>south</md-icon
                        >{{
                            getDistanceString(
                                Math.round(furtherAnswers[0].distance)
                            )
                        }}</span
                    >
                </div>
                <div
                    class="container-item"
                    v-for="(ans, index) in furtherAnswers"
                    :key="`f${index}`"
                >
                    <span>{{ b.position + index + 2 }}</span>
                    <span>{{ ans.username }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import utilMixin from "../../mixins/utilMixin";

export default {
    mixins: [utilMixin],
    computed: {
        ...mapState("partygame", ["battleRoyal"]),
        b() {
            return this.battleRoyal.surroundingAnswers
                ? this.battleRoyal
                : {
                      surroundingAnswers: [],
                  };
        },
        closerAnswers() {
            return this.b.surroundingAnswers.slice(
                0,
                this.b.indexInSurroundingAnswers
            );
        },
        furtherAnswers() {
            return this.b.surroundingAnswers.slice(
                this.b.indexInSurroundingAnswers + 1
            );
        },
    },
};
</script>

<style>
.container {
    width: 100%;
    display: grid;
    grid-row-gap: 0.5em;
    grid-column-gap: 1em;
    min-width: 200px;
}

.container-item {
    display: grid;
    grid-template-columns: 10px 1fr;
    text-align: left;
    grid-column-gap: 1em;
    margin: 5px;
    padding: 10px;
    background: #dbdbdb;
}
.container-item-wide {
    display: block;
    text-align: center;
}
</style>

<style>
#battle-table {
    position: absolute;
    z-index: 1000;
    opacity: 0.8;
    pointer-events: none;
    width: fit-content;
    height: fit-content;
    background: rgba(255, 255, 255, 0.445);
}
</style>