<template>
    <div id="bonus-guess-display" class="flex-left-center guesses-container">
        <div class="flex-down">
            <span class="md-title">Guesses: {{ Math.min(7, guessesNextRound) }}</span>
            <span class="md-caption">(Capped at 7)</span>
        </div>
        <div
            v-if="guessBonuses"
            class="bonus-guesses md-layout md-alignment-top-left"
        >
            <div
                class="md-layout-item md-size-50"
                v-for="(bonus, index) in guessBonuses"
                :key="index"
            >
                +{{ bonus.bonus }} {{ bonus.bonusText }}
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    computed: {
        ...mapState("partygame", [
            "guessBonuses",
        ]),
        //...mapGetters("partygame", ["guessesLeft"]),
        guessesNextRound() {
            if (!this.guessBonuses) {
                return 0;
            }
            return this.guessBonuses.reduce((a, b) => a + b.bonus, 0);
        },
    }
};
</script>

<style>
#bonus-guess-display.guesses-container {
    max-width: calc(min(350px, 100%));
    width: 100%;
}
#bonus-guess-display .bonus-guesses {
    flex: 1; 
}  
</style>