<template>
    <div>
        <div class="md-layout md-gutter">
            <div class="md-layout-item md-size-100">
                <md-field
                    :class="getValidationClass('mapName')"
                >
                    <label for="map-name">Map Name</label>
                    <md-input
                        name="map-name"
                        id="map-name-input"
                        v-model="content.mapName"
                        :disabled="saving"
                        :md-counter="maxNameLen"
                    />
                    <span class="md-error">
                        {{
                            !$v.content.mapName.required
                                ? "The map name is required"
                                : !$v.content.mapName
                                        .mapNameRegex
                                ? "Invalid characters"
                                : !$v.content.mapName
                                        .minLength
                                ? "Map name too short"
                                : !$v.content.mapName
                                        .maxLength
                                ? "Map name too long"
                                : "Unknown error"
                        }}
                    </span>
                </md-field>
            </div>

            <div class="md-layout-item md-size-100">
                <md-field
                    :class="getValidationClass('mapDesc')"
                >
                    <label for="map-desc"
                        >Map Description</label
                    >
                    <md-input
                        name="map-desc"
                        id="map-desc-input"
                        v-model="content.mapDesc"
                        :disabled="saving"
                        :md-counter="maxDescLen"
                    />
                    <span class="md-error">
                        {{
                            !$v.content.mapDesc.mapDescRegex
                                ? "Invalid characters"
                                : !$v.content.mapDesc
                                        .minLength
                                ? "Map description too short"
                                : !$v.content.mapDesc
                                        .maxLength
                                ? "Map description too long"
                                : "Unknown error"
                        }}
                    </span>
                </md-field>
            </div>
        </div>

        <span class="md-caption">Write any tag and add it by pressing enter</span>
        <md-chips
            :class="{ 'md-invalid': tagError }"
            v-model="content.tags"
            md-placeholder="Add tag..."
            :md-limit="existsInDb ? 3 : 2"
            :md-check-duplicated="true"
            :md-format="formatTag"
            :md-static="saving"
        >
            <div class="md-error">
                {{ tagErrorMessage }}
            </div>
        </md-chips>
    </div>
</template>

<script>

import { validationMixin } from "vuelidate";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import { helpers } from "vuelidate/lib/validators";
const mapNameRegex = helpers.regex(
    "mapNameRegex",
    /^[a-zA-Z0-9 '*.,()!?åäöÅÄÖ+_-]+$/
);
const mapDescRegex = helpers.regex(
    "mapDescRegex",
    /^[a-zA-Z0-9 %:'*.,()!?åäöÅÄÖ+_-]+$/
);
const maxNameLen = 22;
const maxDescLen = 150;

export default {
    mixins: [validationMixin],
    props: {
        value: Object,
        saving: Boolean,
        existsInDb: Boolean,
    },
    data() {
        return {
            content: this.value,

            tagError: false,
            tagErrorMessage: "",

            maxNameLen: maxNameLen,
            maxDescLen: maxDescLen,
        }
    },
    watch: {
        // Watch value?
        content: function () {
            this.$emit('input', this.content);
        }
    },
    methods: {
        getValidationClass(fieldName) {
            const field = this.$v.content[fieldName];

            if (field) {
                return {
                    "md-invalid": field.$invalid && field.$dirty,
                };
            }
        },
        validate() {
            this.$v.$touch();
            return !this.$v.$invalid;
        },
        formatTag(tag) {
            this.tagError = false;
            if (tag.length > 0 && !/^[a-zA-Z0-9 _-]+$/.test(tag)) {
                this.tagErrorMessage = "Illegal character(s)";
                this.tagError = true;
                return false;
            } else if (tag.length > 2 && tag.length <= 9) {
                return tag[0].toUpperCase() + tag.substr(1).toLowerCase();
            } else if (tag.length > 9) {
                this.tagErrorMessage = "Tag too long (max 9)";
                this.tagError = true;
            } else {
                return false;
            }
        },
    },
    validations: {
        content: {
            mapName: {
                required,
                mapNameRegex,
                minLength: minLength(3),
                maxLength: maxLength(maxNameLen),
            },
            mapDesc: {
                mapDescRegex,
                minLength: minLength(3),
                maxLength: maxLength(maxDescLen),
            },
        },
    },
};
</script>

<style scoped>
</style>