<template>
    <div class="md-layout">
        <md-card md-with-hover v-if="filterOnlyMyMaps">
            <div v-on:click="createNewMap">
                <md-empty-state
                    md-icon="add"
                    md-label="Create a new map"
                    md-description="Create your own custom map and share it with others."
                >
                </md-empty-state>
            </div>
        </md-card>
        <md-card v-if="filterOnlyFavorites && cards.length === 0">
            <div>
                <md-empty-state
                    md-icon="favorite"
                    md-label="Add favorites"
                    md-description="Add favorites by clicking the heart icon in the top left of any map."
                >
                </md-empty-state>
            </div>
        </md-card>
        <MapCard
            v-for="(card, index) in cards"
            :key="`${card.mapName}+${index}`"
            :title="card.mapName"
            :desc="card.mapDesc"
            :tags="card.tags"
            :nrPlays="card.nrPlays"
            :nrFavorites="card.nrFavorites"
            :mapId="card.id"
            :imgUrl="card.imgUrl"
            :favorite="card.favorite"
            :canEdit="card.canEdit"
            :canFavorite="canFavorite"
            :ownerName="card.ownerName"
            :ownerId="card.ownerId"
            :officialMap="card.officialMap"
            :hasLobby="hasLobby"
            :canRegionGuess="card.canRegionGuess"
        />
    </div>
</template>

<script>
import MapCard from "../components/MapCard";

export default {
    name: "MapCards",
    components: {
        MapCard,
    },
    props: {
        cards: Array,
        filterOnlyMyMaps: Boolean,
        filterOnlyFavorites: Boolean,
        hasLobby: Boolean,
        canFavorite: {
            default: true,
            type: Boolean,
        },
    },
    methods: {
        createNewMap() {
            this.$router.push({
                name: "EditMap",
                params: { mapId: 0 },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.md-card {
    width: 312px;
    height: 312px;
    margin: 9px;
    display: inline-block;
    vertical-align: top;
    text-align: start;
}

.md-layout {
    margin: 10px;
}

.md-empty-state {
    padding: 16px 36px;
}
</style>