import Vue from "vue";
import Vuex from "vuex";

import topbar from "./modules/topbar";
import game from "./modules/game";
import googleloader from "./modules/googleloader";
import partygame from "./modules/partygame";

Vue.use(Vuex);

import createPersistedState from "vuex-persistedstate";

const store = new Vuex.Store({
    plugins: [
        createPersistedState({
            storage: window.sessionStorage,
            paths: ["topbar", "game"],
        }),
    ],
    modules: {
        topbar,
        game,
        googleloader,
        partygame,
    },
});

export default store;
