<template>
    <div class="flex-down-center">
        <div>
            <span class="md-headline" style="text-align: left"
                >Select any free image from unsplash as thumbnail</span
            >
            <div class="flex-left-center">
                <md-field class="small-field">
                    <label>Image ID</label>
                    <md-input v-model="imageId"></md-input>
                </md-field>
                <md-button
                    class="md-raised md-primary"
                    @click="selectImage"
                    :disabled="!imageLoaded || imageId == lastImageId"
                >
                    Select
                </md-button>
                <md-button
                    class="md-icon-button md-raised"
                    @click="openUnsplash"
                >
                    <md-icon>travel_explore</md-icon>
                </md-button>
                <md-button
                    class="md-icon-button md-raised"
                    @click="openHelp = true"
                >
                    <md-icon>help</md-icon>
                </md-button>
            </div>
        </div>
        <div class="flex-start">
            <span class="md-subheading" style="text-align: left"
                >Select thumbnail area</span
            >
        </div>
        <div class="image-thumbnail-container">
            <img
                ref="image"
                class="image"
                :src="imageUrl"
                @load="onLoad"
                @error="onLoadError"
            />
            <div
                ref="thumbnail"
                class="thumbnail"
                :style="thumbnailStyle"
                @mousedown="startDrag"
                @touchstart.prevent="startDrag"
            >
                <div
                    class="resize-handle bottom-right"
                    @mousedown="startResize"
                    @touchstart.prevent="startResize"
                />
            </div>
            <div v-if="!imageLoaded" class="loading-overlay">
                <md-progress-spinner
                    class="md-accent"
                    md-mode="indeterminate"
                ></md-progress-spinner>
            </div>
        </div>
        <div v-if="debug">
            Image {{ $refs && $refs.image ? $refs.image.width : "" }}x{{
                $refs && $refs.image ? $refs.image.height : ""
            }}
            Thumbnail {{ Math.ceil(thumbnail.width) }}x{{
                Math.ceil(thumbnail.height)
            }}+{{ thumbnail.x }}+{{ thumbnail.y }}
        </div>
        <div class="flex-end">
            <md-button class="md-raised md-accent" v-if="!hideUpdate" @click="save" :disabled="saving"
                >Update thumbnail</md-button
            >
        </div>

        <md-dialog-alert
            :md-active.sync="openHelp"
            md-content="You find the image id last in an image's url<br>e.g. 'NdKAOj89lmc' in<br>https://unsplash.com/photos/city-buildings-near-body-of-water-during-daytime-<b>NdKAOj89lmc</b>"
            md-confirm-text="Close"
        />
    </div>
</template>

<script>
import axiosMixin from "../mixins/axiosMixin";
export default {
    data() {
        return {
            defaultImage: "/img/generic.webp",
            imageUrl: this.imageIdInit ? this.serverImgUrl(this.imageIdInit) : "/img/generic.webp",
            thumbnail: {
                width: 320,
                height: 133,
                aspectRatio: 2.4,
                x: 0,
                y: 0,
                dragging: false,
                resizing: false,
                resizeHandle: "",
            },
            imageLoaded: false,
            openHelp: false,
            imageId: this.imageIdInit || "",
            lastImageId: this.imageIdInit || "",
            saving: false,
            debug: false,
        };
    },
    props: {
      mapId: Number,
      imageIdInit: String,
      hideUpdate: Boolean,
      initPos: Object,
    },
    mixins: [axiosMixin],
    computed: {
        thumbnailStyle() {
            return {
                width: `${this.thumbnail.width}px`,
                height: `${this.thumbnail.height}px`,
                left: `${this.thumbnail.x}px`,
                top: `${this.thumbnail.y}px`,
            };
        },
    },
    methods: {
        selectImage() {
            this.imageLoaded = false;
            this.lastImageId = this.imageId;
            this.imageUrl = this.serverImgUrl(this.imageId);
        },
        serverImgUrl(imgId) {
            return `${process.env.VUE_APP_IMAGE_URL}getFullImage?image=${encodeURIComponent(imgId)}`;
        },
        save(emit = true) {
            this.saving = true;
            this.constrainThumbnail();
            const params = {
                mapId: this.mapId,
                imgId:
                    !this.imageId ||
                    this.imageId.length <= 0 ||
                    this.imageId == this.defaultImage
                        ? "generic"
                        : this.imageId,
            };

            const scale = this.getImageScale();
            const thumbnailInfo = {
                width: Math.round(this.thumbnail.width / scale),
                height: Math.round(this.thumbnail.height / scale),
                offsetX: Math.round(this.thumbnail.x / scale),
                offsetY: Math.round(this.thumbnail.y / scale),
            };

            const newImage = this.imageUrl.includes("unsplash");
            const method =  newImage ? "put" : "post"
            const url = process.env.VUE_APP_SERVER_URL + (newImage ? "map/saveUnsplashImage" : "map/updateImageThumbnail")

            this.makeRequest(
                method,
                url,
                params,
                thumbnailInfo
            ).then((resp) => {
                this.saving = false;
                if (!(resp.status === 200 || resp.status === 201)) {
                    console.log("Could not save image"); // TODO display error
                } else if (emit) {
                    this.$emit("saved");
                }
            });
        },
        getImageScale() {
            // The image is 1080xH so scale based on display width
            const image = this.$refs.image;
            return image.width / 1080;
        },
        openUnsplash() {
            window.open("https://unsplash.com/", "_blank");
        },
        onLoad() {
            this.imageLoaded = true;

            if (this.imageId === this.imageIdInit) {
                this.onLoadedImageWithThumbnailInfo();
            }
            else {
                this.thumbnail.x = 0;
                this.thumbnail.y = 0;
                this.thumbnail.width = this.$refs.image.width;
                this.thumbnail.height = 
                    this.thumbnail.width / this.thumbnail.aspectRatio;
            }
        },
        onLoadedImageWithThumbnailInfo() {
            const scale = this.getImageScale();

            this.thumbnail.x = this.initPos.offsetX * scale;
            this.thumbnail.y = this.initPos.offsetY * scale;
            this.thumbnail.width = this.initPos.width * scale;
            this.thumbnail.height = this.initPos.height * scale;
        },
        onLoadError(e) {
            if (this.imageUrl.startsWith("https://unsplash.com/")) {
                this.imageUrl = this.defaultImage;
            } else {
                this.imageUrl = `https://unsplash.com/photos/${this.imageId}/download?force=true&w=1080&fm=jpg`;
            }
        },
        startDrag(event) {
            if (this.imageLoaded && !this.thumbnail.resizing) {
                this.thumbnail.dragging = true;
                const { clientX, clientY } = this.getClientCoordinates(event);
                const offsetX = clientX - this.thumbnail.x;
                const offsetY = clientY - this.thumbnail.y;

                const moveHandler = (event) => {
                    if (this.thumbnail.dragging) {
                        const { clientX, clientY } =
                            this.getClientCoordinates(event);
                        this.thumbnail.x = clientX - offsetX;
                        this.thumbnail.y = clientY - offsetY;
                        this.constrainThumbnail();
                    }
                };

                const upHandler = () => {
                    this.thumbnail.dragging = false;
                    this.removeEventListeners(moveHandler, upHandler);
                    this.constrainThumbnail();
                };
                this.addEventListeners(moveHandler, upHandler);
            }
        },
        startResize(event) {
            if (this.imageLoaded) {
                const { clientX, _ } = this.getClientCoordinates(event);
                this.thumbnail.resizing = true;
                //this.thumbnail.resizeHandle = event.target.classList[1];
                const offsetX = clientX - this.thumbnail.width;

                const resizeHandler = (event) => {
                    const { clientX, _ } = this.getClientCoordinates(event);
                    const image = this.$refs.image;
                    const newWidth = clientX - offsetX;
                    if (
                        this.thumbnail.resizing &&
                        newWidth < image.width &&
                        (this.thumbnail.width < newWidth ||
                            newWidth > this.minWidth())
                    ) {
                        if (newWidth + 4 >= image.width) {
                            this.thumbnail.width = image.width;
                            this.thumbnail.x = 0;
                        } else {
                            this.thumbnail.width = newWidth;
                        }
                        this.thumbnail.height =
                            this.thumbnail.width / this.thumbnail.aspectRatio;

                        this.constrainThumbnail();
                    }
                };

                const upHandler = () => {
                    this.thumbnail.resizing = false;
                    this.removeEventListeners(resizeHandler, upHandler);
                    this.constrainThumbnail();
                };
                this.addEventListeners(resizeHandler, upHandler);
            }
        },
        minWidth() {
            return (this.$refs.image.width / 108) * 32; // * 1080 / 320 (all images are 1080xH and 320 is the size of the thumbnail)
        },
        constrainThumbnail() {
            // Adjust thumbnail position and size to prevent going outside the image boundaries
            const image = this.$refs.image;
            const thumbnail = this.$refs.thumbnail;

            if (this.thumbnail.width < this.minWidth()) {
                this.thumbnail.width = this.minWidth();
                this.thumbnail.height =
                    this.thumbnail.width / this.thumbnail.aspectRatio;
            }

            if (this.thumbnail.width > image.width) {
                this.thumbnail.width = image.width;
                this.thumbnail.height =
                    this.thumbnail.width / this.thumbnail.aspectRatio;
            }
            
            if (thumbnail.offsetLeft < 0) {
                this.thumbnail.x = 0;
            }

            if (thumbnail.offsetTop < 0) {
                this.thumbnail.y = 0;
            }

            if (
                thumbnail.offsetLeft + thumbnail.offsetWidth >
                image.offsetWidth
            ) {
                this.thumbnail.x = image.offsetWidth - thumbnail.offsetWidth;
            }

            if (
                thumbnail.offsetTop + thumbnail.offsetHeight >
                image.offsetHeight
            ) {
                this.thumbnail.y = image.offsetHeight - thumbnail.offsetHeight;
            }

            if (this.thumbnail.width < 0) {
                this.thumbnail.width = 10;
            }

            if (this.thumbnail.height < 0) {
                this.thumbnail.height = 10;
            }
        },
        addEventListeners(moveHandler, upHandler) {
            document.addEventListener("mousemove", moveHandler);
            document.addEventListener("mouseup", upHandler);
            document.addEventListener("touchmove", moveHandler);
            document.addEventListener("touchend", upHandler);
        },
        removeEventListeners(moveHandler, upHandler) {
            document.removeEventListener("mousemove", moveHandler);
            document.removeEventListener("mouseup", upHandler);
            document.removeEventListener("touchmove", moveHandler);
            document.removeEventListener("touchend", upHandler);
        },
        getClientCoordinates(event) {
            return {
                clientX: event.type.includes("touch")
                    ? event.touches[0].clientX
                    : event.clientX,
                clientY: event.type.includes("touch")
                    ? event.touches[0].clientY
                    : event.clientY,
            };
        },
    },
};
</script>

<style scoped>
.image-thumbnail-container {
    position: relative;
    overflow: hidden;
    max-width: 480px;
}

.image {
    height: auto;
    display: block;
    min-width: 250px;
}

.thumbnail {
    touch-action: none;
    position: absolute;
    border: 2px solid #007bff;
    background-color: rgba(0, 123, 255, 0.3);
    cursor: move;
    user-select: none;
}

.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.resize-handle {
    position: absolute;
    width: 16px;
    height: 16px;
    background-color: #007bff;
    cursor: nwse-resize;
    user-select: none;
}

.bottom-right {
    bottom: -8px;
    right: -8px;
}

.small-field {
    margin: 1px 0 12px;
}

@media screen and (max-width: 400px) {
    .resize-handle {
        width: 20px;
        height: 20px;
    }

    .bottom-right {
        bottom: -10px;
        right: -10px;
    }
}
</style>
