<template>
    <div id="pano-container">
        <div v-if="!settings.canRotate || isCountingDown" :class="{'countdown':isCountingDown}" id="pano-cover">
            <CountdownCircle :countdownEndTime="countdownEndTime" @countdownStopped="isCountingDown = false" :damageMultiplier="damageMultiplier" />
        </div>

        <div id="pano">
            <md-button
                id="btn-return"
                v-if="settings.canMove"
                v-on:click="returnToStartPos"
                class="md-icon-button md-raised md-primary"
            >
                <md-icon>cached</md-icon>
            </md-button>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import CountdownCircle from "./CountdownCircle.vue";

export default {
    name: "Panorama",
    components: {
        CountdownCircle, 
    },
    data() {
        return {
            panorama: null,
            triggerChange: false,
            isCountingDown: true,
        };
    },
    props: {
        settings: Object,
        location: Object,
        countdownEndTime: {
            type: Number,
            default: -1,
        },
        damageMultiplier: Number,
    },
    watch: {
        location: function () {
            this.changeLoc(this.location);
            this.triggerChange = false;
        },
    },
    methods: {
        ...mapActions(["loadGoogle"]),

        changeLoc(loc) {
            this.panorama.setPano(loc.id);
            this.panorama.setPov({
                heading: loc.heading,
                pitch: loc.pitch,
                zoom: 0,
            });
        },

        /**
         * Return the panorama to the original position if the player has moved
         */
        returnToStartPos() {
            console.log(this.location);
            if (this.panorama && this.location) {
                this.changeLoc(this.location);
            }
        },

        // Retrieve a new panorama instance with the google javascript api
        getPanorama(google, loc, settings) {
            this.panorama = new google.maps.StreetViewPanorama(
                document.getElementById("pano"),
                {
                    pano: loc.id,
                    pov: {
                        heading: loc.heading,
                        pitch: loc.pitch,
                        zoom: 0,
                    },
                    linksControl: settings.canMove,
                    enableCloseButton: false,
                    motionTracking: false,
                    motionTrackingControl: false,
                    addressControl: false,
                    showRoadLabels: false,
                    fullscreenControl: false,
                    panControl: true,
                    panControlOptions: {
                        position: google.maps.ControlPosition.LEFT_BOTTOM,
                    },
                    clickToGo: settings.canMove,
                    scrollwheel: settings.canZoom,
                    zoomControl: settings.canZoom,
                    zoomControlOptions: {
                        position: google.maps.ControlPosition.LEFT_BOTTOM,
                    },
                }
            );

            window.google.maps.event.addDomListener(
                this.panorama,
                "position_changed",
                () => {
                    // Will trigger when the pano is changed, use this to approx when pano is loaded
                    if (!this.triggerChange) {
                        this.triggerChange = true;
                        this.$emit("loaded");
                    }
                }
            );
        },
    },
    created() {
        this.loadGoogle().then(() => {
            this.getPanorama(window.google, this.location, this.settings);
        });
    },
};
</script>

<style scoped>
#pano-container {
    flex: 1;
}

#pano {
    height: 100%;
    width: 100%;
    margin: auto;
    background: white;
    position: relative;
}

#btn-return {
    left: 65px;
    bottom: 35px;
    position: absolute;
    z-index: 2;
}

#pano-cover {
    position: absolute;
    width: 100%;
    height: calc(100vh - 48px);
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
}

#pano-cover.countdown {
    background: rgba(0, 0, 0, 0.51);
    z-index: 102;
}
</style>