<template>
    <div class="game" @keyup.space="triggerGuessIfMarker">
        <div v-if="!getSettings.canRotate && !getGuessMade" id="pano-cover" />

        <md-button
            v-if="getGuessMade && !isScoreDialogVisible"
            class="md-raised md-primary"
            id="show-score-btn"
            @click="() => setScoreDialogVis(true)"
        >
            Show Score
        </md-button>

        <div class="overlay-bar">
            <div class="overlay-left">
                <span
                    >{{ screen.width > 600 ? "Round: " : ""
                    }}{{ getRoundText }}</span
                >
            </div>
            <div>
                <span>{{ mapName }}</span>
            </div>
            <div v-if="isRegionGuessing" class="overlay-right">
                <span>{{ getScore }}</span>
            </div>
        </div>

        <div id="pano"></div>

        <TimerBox :endTime="getEndTime" @end="onTimerEnd" />

        <GuessMap :canPlaceMarker="!getGuessMade" :visible="!getGuessMade" />

        <md-button
            id="btn-return"
            v-if="getSettings.canMove"
            v-on:click="returnToStartPos"
            class="md-icon-button md-raised md-primary"
        >
            <md-icon>cached</md-icon>
        </md-button>
        <!-- <button id="btn-new" v-on:click="newPano">New</button> -->

        <ScoreDialog />
    </div>
</template>

<script>
import GuessMap from "../components/GuessMap";
import ScoreDialog from "../components/ScoreDialog";
import TimerBox from "../components/TimerBox.vue";
import axiosMixin from "../mixins/axiosMixin";

import { mapActions, mapGetters } from "vuex";

export default {
    name: "Game",
    mixins: [axiosMixin],
    components: {
        GuessMap,
        ScoreDialog,
        TimerBox,
    },
    data() {
        return {
            panorama: null,
            unsubscribe: null,
            timer: null,
            lowTime: false,
            mapName: "",
            screen: null,
        };
    },
    computed: {
        ...mapGetters([
            "isGameOver",
            "isRegionGuessing",
            "getScore",
            "getCurrentLocation",
            "getCurrentPano",
            "getSettings",
            "getRoundText",
            "getEndTime",
            "getMapId",
            "getGuessMade",
            "isScoreDialogVisible",
        ]),
    },
    watch: {
        getGuessMade() {
            this.panorama.setOptions({
                linksControl: this.getSettings.canMove || this.getGuessMade,
                clickToGo: this.getSettings.canMove || this.getGuessMade,
                scrollwheel: this.getSettings.canZoom || this.getGuessMade,
                zoomControl: this.getSettings.canZoom || this.getGuessMade,
            });
        }
    },
    methods: {
        ...mapActions([
            "loadGoogle",
            "triggerGuess",
            "triggerGuessIfMarker",
            "setScoreDialogVis",
        ]),

        onTimerEnd() {
            this.triggerGuess();
        },

        /**
         * Return the panorama to the original position if the player has moved
         */
        returnToStartPos() {
            if (this.panorama && this.getCurrentPano) {
                // TODO? reset heading and pitch?
                this.panorama.setPano(this.getCurrentPano);
                const loc = this.getCurrentLocation;
                this.panorama.setPov({
                    heading: loc.heading,
                    pitch: loc.pitch,
                    zoom: 0,
                });
            }
        },

        // Retrieve a new panorama instance with the google javascript api
        getPanorama(google, loc, settings) {
            this.panorama = new google.maps.StreetViewPanorama(
                document.getElementById("pano"),
                {
                    pano: loc.id,
                    pov: {
                        heading: loc.heading,
                        pitch: loc.pitch,
                        zoom: 0,
                    },
                    linksControl: settings.canMove || this.getGuessMade,
                    enableCloseButton: false,
                    motionTracking: false,
                    motionTrackingControl: false,
                    addressControl: false,
                    showRoadLabels: false,
                    fullscreenControl: false,
                    panControl: true,
                    panControlOptions: {
                        position: google.maps.ControlPosition.LEFT_BOTTOM,
                    },
                    clickToGo: settings.canMove || this.getGuessMade,
                    scrollwheel: settings.canZoom || this.getGuessMade,
                    zoomControl: settings.canZoom || this.getGuessMade,
                    zoomControlOptions: {
                        position: google.maps.ControlPosition.LEFT_BOTTOM,
                    },
                }
            );
        },
    },
    created() {
        this.screen = screen;

        axiosMixin.methods
            .makeRequest("get", process.env.VUE_APP_SERVER_URL + "map/byId", {
                mapId: this.getMapId,
            })
            .then((resp) => {
                if (resp.status == 200) {
                    this.mapName = resp.data.mapName;
                }
            });

        // Listen to when a new round is committed
        this.unsubscribe = this.$store.subscribe((mutation) => {
            if (mutation.type === "commitNextRound") {
                if (this.isGameOver) {
                    this.$router.push({
                        name: "PostGame",
                    });
                    return;
                }

                const { id, heading, pitch } = this.getCurrentLocation;
                if (this.panorama && id) {
                    this.panorama.setPano(id);
                    this.panorama.setPov({ heading, pitch, zoom: 0 });
                }

                window.dispatchEvent(new Event("resize"));
            }
            this.lowTime = false;
        });

        this.loadGoogle().then(() => {
            this.getPanorama(
                window.google,
                this.getCurrentLocation,
                this.getSettings
            );
        });
    },
    beforeDestroy() {
        this.unsubscribe();
    },

    beforeRouteLeave(to, from, next) {
        // If we press back from game we should come to home.
        if (to.path == "/preGame") {
            next("/");
        } else {
            next();
        }
    },
};
</script>

<style scoped>
#pano {
    height: 100%;
    width: 100%;
    margin: auto;
    background: white;
}

#btn-return {
    left: 65px;
    bottom: 35px;
    position: absolute;
    z-index: 2;
}

#btn-new {
    left: 80px;
    bottom: 20px;
    position: absolute;
    z-index: 2;
}

.game {
    flex: auto;
}

#pano-cover {
    position: absolute;
    width: 100%;
    height: calc(100vh - 48px);
    z-index: 3;
    color: rgba(0, 0, 0, 0);
}

.md-overlay {
    z-index: 5000 !important;
}

.md-dialog {
    z-index: 10000 !important;
}

#show-score-btn {
    position: absolute;
    right: 20px;
    bottom: 50px;
    z-index: 10;
    width: 300px;
    height: 50px;
}
</style>

