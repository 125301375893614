export const USERNAME_VALIDATION = {
    min: 2,
    max: 20
}

import { mapActions } from "vuex";

export default {
    data() {
        return {
            userNameValidation: USERNAME_VALIDATION,
        }
    },

    methods: {
        ...mapActions([
            "toggleMenuVisible",
            "clearTopBar",
        ]),

        getTimeText(milliseconds) {
            if (!milliseconds) {
                return "-";
            }
            const minutes = Math.floor(milliseconds / (60 * 1000));
            const seconds = ((milliseconds / 10) % 6000) / 100;
            return (
                (minutes > 0 ? minutes + "m " : "") + seconds.toFixed(2) + "s"
            );
        },
        /**
         * Returns a string of formatted distance
         * @param {Number} distance Distance in meters
         * @returns
         */
        getDistanceString(distance) {
            if (distance === null || distance === undefined) {
                return '-';
            }
            if (distance < 5000) {
                return distance + " m"; // e.g. 4678 m
            } else if (distance > 20000) {
                // Between 5000 and 20000 m
                return Math.round(distance / 1000) + " km"; // e.g. 5.5 km
            } else {
                return Math.round(distance / 100) / 10 + " km"; // e.g. 21 km
            }
        },

        toggleFullscreenIfSmall() {  
            if (this.isSmall()) {
                this.toggleFullscreen();
            }
        },

        toggleFullscreen() {
            //const isFullscreen = !window.screenTop && !window.screenY;
            const doc = window.document;
            const docEl = doc.documentElement;

            const requestFullscreen =
                docEl.requestFullscreen ||
                docEl.mozRequestFullScreen ||
                docEl.webkitRequestFullScreen ||
                docEl.msRequestFullscreen;
            const cancelFullscreen =
                doc.exitFullscreen ||
                doc.mozCancelFullScreen ||
                doc.webkitExitFullScreen ||
                doc.msExitFullscreen;

            if (!this.isFullscreen()) {
                requestFullscreen.call(docEl);
            } else {
                cancelFullscreen.call(doc);
            }
        },

        isFullscreen() {
            /*const isFullscreen = doc.fullscreenElement ||
                doc.mozFullscreenElement ||
                doc.webkitFullscreenElement ||
                doc.msFullscreenElement;*/
            return !window.screenTop && !window.screenY;
        },

        isSmall() {
            return window.innerWidth < 1000;
        },

        validateUsername(username) {
            // Validate username
            let outUsername = username.trim();
            do {
                outUsername = outUsername.replaceAll("  ", " ");
            } while (outUsername.includes("  "));

            if (outUsername.length < USERNAME_VALIDATION.min) {
                return [false, `Username too short (min ${USERNAME_VALIDATION.min})`];
            } else if (outUsername.length > USERNAME_VALIDATION.max) {
                return [false, `Username too long (max ${USERNAME_VALIDATION.max})`];
            } else if (!/^[a-zA-Z0-9 _-]+$/.test(outUsername)) {
                // TODO remove?
                return [false, "Letters, nums, spaces and '_', '-'"];
            }

            let oneLetter = false;
            for (var i = 0; i < outUsername.length; i++) {
                if (outUsername.charAt(i).toLowerCase().match(/[a-z]/i)) {
                    oneLetter = [false, "Invalid character"];
                    break;
                }
            }
            if (!oneLetter) {
                return [false, "Must contain at least one letter"];
            }

            return [true, outUsername];
        },

        signOut() {
            localStorage.clear();
            this.closeSideMenuIfSmallScreen();
            this.clearTopBar();
            window.sessionStorage.clear();
            this.$router.push({
                name: "Login",
                query: this.userProfileSet
                    ? {}
                    : {
                          nextUrl: this.$route.fullPath,
                      },
            });
        },
        closeSideMenuIfSmallScreen() {
            if (window.innerWidth < 1000) {
                this.toggleMenuVisible();
            }
        },
        getFlagUrl(regionCode) {
            return '/img/flag/' + regionCode.toLowerCase() + '.webp';
        }
    },
};
