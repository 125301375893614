import axiosMixin from "../../mixins/axiosMixin";

const state = {
	gameInstance: {},
	encLocations: [],
	hmacToken: "",
	scoreDialogVisible: false,
	lastMarkerPosition: [],
	startTime: 0,
	guessMarkerPosition: null,
	center: { lat: 0, lng: 0 },
	zoom: 1,

	guessTrigger: false,
	guessTriggerIfMarker: false,
	guessMade: false,
};

const getters = {
	getGuessMade(state) {
		return state.guessMade;
	},
	getCenter(state) {
		return state.center;
	},
	getZoom(state) {
		return state.zoom;
	},

	gameInstanceId(state) {
		if (state.gameInstance) {
			return state.gameInstance.gameInstanceId;
		}
		return "";
	},

	isRegionGuessing(state) {
		if (state.gameInstance && state.gameInstance.settings) {
			return state.gameInstance.settings.guessRegion;	
		}
		return false;
	},

	getTotalTime(state) { // In milliseconds
		if (!state.gameInstance || !state.gameInstance.settings) {
			return;
		}
		const timeSetting = state.gameInstance.settings.time;
		if (timeSetting > 10) {
			return -1;
		}
		if (timeSetting > 0) {
			return timeSetting * 60 * 1000;
		}
		return (30 / (1 - timeSetting)) * 1000;
	},

	getEndTime(state, getters) {
		return getters.getTotalTime && getters.getTotalTime > 0 ? state.startTime + getters.getTotalTime : -1;
	},

	hasGameInstance(state) {
		return !!state.gameInstance && !!state.gameInstance.scores;
	},

	isGameOver(state, getters) {
		if (!state.gameInstance || !state.gameInstance.settings) {
			return false;
		}

		if (getters.isRegionGuessing) {
			return state.gameInstance.locations.length <= state.gameInstance.guessedLocations.length;
		}

		return (
			state.gameInstance.round >= state.gameInstance.settings.maxRounds
		);
	},

	guessTrigger(state) {
		return state.guessTrigger;
	},
	guessTriggerIfMarker(state) {
		return state.guessTriggerIfMarker;
	},

	isMultiplayer(state) {
		if (!state.gameInstance.multiplayer) {
			return false;
		}
		return state.gameInstance.multiplayer;
	},

	isScoreDialogVisible(state) {
		return state.scoreDialogVisible;
	},

	getSettings(state) {
		if (!state.gameInstance.settings) {
			return {};
		}
		return state.gameInstance.settings;
	},

	getMapId(state) {
		if (!state.gameInstance.mapId) {
			return -1;
		}
		return state.gameInstance.mapId;
	},
	getGameId(state) {
		if (!state.gameInstance.gameId) {
			return -1;
		}
		return state.gameInstance.gameId;
	},

	getMaxScore(state) {
		if (!state.gameInstance.settings) {
			return -1;
		}
		return state.gameInstance.settings.maxRoundScore;
	},

	getCurrentLocation(state) {
		if (!state.gameInstance.locations) {
			return {};
		}
		return state.gameInstance.locations[state.gameInstance.round];
	},
	getCurrentPano(state) {
		if (!state.gameInstance.locations) {
			return "";
		}
		return state.gameInstance.locations[state.gameInstance.round].id;
	},

	getScores(state) {
		if (!state.gameInstance.scores) {
			return [];
		}
		return state.gameInstance.scores;
	},
	getCurrentScore(state) {
		if (state.gameInstance.scores[state.gameInstance.round]) {
			return state.gameInstance.scores[state.gameInstance.round].score;
		}
		return -1;
	},
	getScore(state, getters) {
		if (!state.gameInstance.scores) {
			return -1;
		}

		if (getters.isRegionGuessing) {
			return getters.getRound - 2;
		}

		return state.gameInstance.scores
			.map((score) => score.score)
			.reduce((a, b) => a + b, 0);
	},

	getDistances(state) {
		if (!state.gameInstance.distances) {
			return [];
		}
		return state.gameInstance.distances;
	},
	getCurrentDistance(state) {
		if (!state.gameInstance.distances) {
			return -1;
		}
		return state.gameInstance.distances.slice(-1)[0];
	},

	getRound(state) {
		return state.gameInstance.round + 1;
	},
	getMaxRound(state) {
		if (!state.gameInstance.settings) {
			return -1;
		}
		return state.gameInstance.settings.maxRounds;
	},

	guessMarkerIsPlaced(state) {
		if (!state.guessMarkerPosition) {
			return false;
		}
		return !!state.guessMarkerPosition;
	},

	getMarkerPositions(state) {
		if (!state.gameInstance.guessedLocations) {
			return;
		}
		// Merge the guesses with the correct positions
		const markerPositions = state.gameInstance.guessedLocations
			.map((guessLoc, index) => {
				const loc = state.gameInstance.locations[index];
				const correct = loc ? {coord: loc} : null;
				const guesses = guessLoc ? [{coord: guessLoc}] : [];
				return {
					correct,
					guesses,
				};
			});

		return markerPositions;
	},
	getLastMarkerPosition(state) {
		if (state.lastMarkerPosition) {
			return state.lastMarkerPosition;
		} else {
			return [
				{
					guesses: [{ coord: {lat: 0, lng: 0} }],
					correct: { coord: {lat: 0, lng: 0} },
				},
			];
		}
	},

	getRoundText(state, getters) {
		if (getters.isRegionGuessing) {
			return `${state.gameInstance.round + 1}`
		}

		return `${Math.min(
			state.gameInstance.round + 1,
			state.gameInstance.settings.maxRounds
		)}/${state.gameInstance.settings.maxRounds}`;
	},
};

const actions = {
	// ------------------- OTHER ----------------------

	placeGuessMarker({ commit }, position) {
		commit("commitMarkerPosition", position);
	},
	clearGuessMarker({ commit }) {
		commit("commitMarkerPosition", null);
	},

	setScoreDialogVis({ commit }, vis) {
		commit("commitScoreDialogVisible", vis);
	},
	setGameInstance({ commit }, gameInstance) {
		if (gameInstance) {
			commit("commitGameInstance", gameInstance);
			commit("setStartTime");
		} else {
			commit("clearGameInstance");
		}
	},
	triggerGuess({ state, commit }) {
		commit("commitTriggerGuess");
	},
	triggerGuessIfMarker({ commit }) {
		commit("commitTriggerGuessIfMarker");
	},
	nextRound({ state, commit }) {
		axiosMixin.methods
			.makeRequest(
				"post",
				process.env.VUE_APP_SERVER_URL + "game/nextRound",
				null,
				{
					hmacToken: state.hmacToken,
					encLocations: state.encLocations,
					gameInstance: state.gameInstance,
				}
			)
			.then((resp) => {
				if (resp.status === 200) {
					// Hide the score window
					commit("commitScoreDialogVisible", false);

					// Change gameInstance
					commit("commitGameInstance", resp.data);

					commit("commitNextRound");

					commit("setStartTime");
				} else {
					console.log("Error fetching next round", resp);
				}
			});
	},
	makeGuess({ state, commit }) {
		// Update with the guessed location before sending back to server
		state.gameInstance.guessedLocations.push(state.guessMarkerPosition);
		commit("commitHasGuessed");

		axiosMixin.methods
			.makeRequest(
				"post",
				process.env.VUE_APP_SERVER_URL + "game/makeGuess",
				null,
				{
					hmacToken: state.hmacToken,
					encLocations: state.encLocations,
					gameInstance: state.gameInstance,
				}
			)
			.then(
				(resp) => {
					if (resp.status === 200) {
						// Change gameInstance
						commit("commitGameInstance", resp.data);

						// Show score window
						commit("commitScoreDialogVisible", true);
					} else {
						console.log("Error sending guess", resp);
					}
				},
				(error) => {
					console.log(error);
				}
			);
	},
};

const mutations = {
	commitGameInstance: (state, data) => {
		state.hmacToken = data.hmacToken;
		state.encLocations = data.encLocations;
		state.gameInstance = data.gameInstance;

		// Update the last marker position if there exists one
		const round = state.gameInstance.round;
		if (
			state.gameInstance.guessedLocations &&
			state.gameInstance.guessedLocations.length > round &&
			state.gameInstance.locations.length > round
		) {
			const guess = state.gameInstance.guessedLocations[round];
			const correct = state.gameInstance.locations[round];
			const markerPosition = [
				{
					guesses: guess ? [
						{
							coord: guess,
						},
					] : null,
					correct: correct ? {
						coord: correct,
					} : correct,
				},
			];
			state.lastMarkerPosition = markerPosition;
		} else {
			state.lastMarkerPosition = [];
		}
	},
	clearGameInstance: (state) => {
		state.gameInstance = {};
	},
	commitScoreDialogVisible: (state, isShown) => {
		state.scoreDialogVisible = isShown;
	},
	commitNextRound: (state) => {
		state.guessMade = false;
	},
	setStartTime: (state) => {
		state.startTime = new Date().getTime();
	},
	clearStartTime: (state) => {
		state.startTime = -1;
	},
	commitMarkerPosition: (state, position) => {
		state.guessMarkerPosition = position;
	},
	commitCenterZoom: (state, centerZoom) => {
		state.center = centerZoom.center;
		state.zoom = centerZoom.zoom;
	},
	commitTriggerGuess: (state) => {
		if (!state.guessMade) {
			state.guessTrigger = !state.guessTrigger;
		}
	},
	commitTriggerGuessIfMarker: (state) => {
		if (!state.guessMade) {
			state.guessTriggerIfMarker = !state.guessTriggerIfMarker;
		}
	},
	commitHasGuessed: (state) => {
		state.guessMade = true;
	},
	commitHasNotGuessed: (state) => {
		state.guessMade = false;
	}
};

export default {
	state,
	getters,
	actions,
	mutations,
};
