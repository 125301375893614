<template>
    <div class="fill-big">
        <FlagAndName style="z-index: 401" />
        <PartyGuessMap class="fill-big large-map" :startLarge="true">
            <div class="overlay-bar">
                <div class="overlay-left">
                    <span>{{ getRoundText }}</span>
                </div>
                <div class="overlay-right">
                    <md-icon>{{ playerImg }}</md-icon>
                </div>
            </div>
            <TimerBox
                :endTime="endTime"
                :startTime="startTime"
                :useBonus="
                    info.settings.elimination &&
                    info.settings.partyGameType === 0
                "
            />
        </PartyGuessMap>
        <div class="cover" v-if="isCountingDown">
            <CountdownCircle
                :countdownEndTime="startTime"
                @countdownStopped="isCountingDown = false"
                :damageMultiplier="damageMultiplier"
            />
        </div>
        <BattleTable />
    </div>
</template>

<script>
import BattleTable from "../../components/party/BattleTable.vue";
import PartyGuessMap from "../../components/party/PartyGuessMap.vue";
import FlagAndName from "../../components/party/FlagAndName.vue";

import { mapGetters, mapState } from "vuex";

import CountdownCircle from "../../components/CountdownCircle.vue";
import TimerBox from "../../components/TimerBox.vue";

export default {
    mixins: [],
    components: {
        BattleTable,
        PartyGuessMap,
        CountdownCircle,
        FlagAndName,
        TimerBox,
    },
    data() {
        return {
            screen: null,
            isCountingDown: true,
        };
    },
    computed: {
        ...mapState("partygame", [
            "info",
            "endTime",
            "playerImg",
            "startTime",
            "damageMultiplier",
        ]),
        ...mapGetters("partygame", ["getRoundText"]),
    },
    methods: {},

    created() {
        this.screen = screen;
    },
};
</script>

<style scoped>
.fill-big {
    min-height: 100%;
    min-width: 100%;
}

.cover {
    position: absolute;
    width: 100%;
    height: calc(100vh - 48px);
    z-index: 1100;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    background: rgba(0, 0, 0, 0.51);
}
</style>

<style>
#battle-table {
    bottom: 0px;
    right: 0px;
}
</style>
