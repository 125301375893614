<template>
    <div class="flex-down" style="margin: 0">
        <div
            class="flex-center"
            style="padding: 5px 15px; position: relative"
            :style="{ 'background-color': team ? team.color : 'white' }"
        >
            <md-icon class="md-size-2x" style="margin: 5px 10px">{{
                playerImg ? playerImg : "person"
            }}</md-icon>
            <span class="md-display-2">{{ username }}</span>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
    computed: {
        ...mapState("partygame", ["playerImg", "username", "team"]),
        ...mapGetters("partygame", ["isTeams"]),
    },
};
</script>

<style scoped>

@media screen and (max-width: 400px) {
    .md-display-2 {
        font-size: 34px;
        line-height: 40px;
    }
}
</style>