<template>
    <div class="flex-grow flex flex-column">
        <div class="flex-grow flex" style="position: relative">
            <Panorama
                :settings="info.settings"
                :location="location"
                :countdownEndTime="startTime"
                :damageMultiplier="damageMultiplier"
            />
            <FlagAndName />
            <TimerBox
                :endTime="endTime"
                :startTime="startTime"
                :useBonus="
                    info.settings.elimination &&
                    info.settings.partyGameType === 0
                "
            />
            <div class="overlay-bar">
                <div class="overlay-left">
                    <span>{{
                        screen.width > 600 ? getRoundText : info.round
                    }}</span>
                </div>
                <div>
                    <span>{{ info.mapName }}</span>
                </div>
                <div class="overlay-right">
                    <span id="roomCodeSpan">{{ roomCode }}</span>
                </div>
            </div>
        </div>

        <slot />

        <md-button
            id="close-round-btn"
            class="md-primary md-raised"
            v-if="info.host"
            v-on:click="confirmClose = true"
            >Close Round</md-button
        >

        <md-dialog-confirm
            :md-active.sync="confirmClose"
            md-title="End round early?"
            md-content="Players who haven't guessed yet will not get any points"
            md-confirm-text="Confirm"
            md-cancel-text="Cancel"
            @md-cancel="confirmClose = false"
            @md-confirm="closeRound"
        />
    </div>
</template>

<script>
import Panorama from "../../components/Panorama";
import { mapState, mapGetters } from "vuex";
import FlagAndName from "../../components/party/FlagAndName.vue";
import TimerBox from "../../components/TimerBox.vue";

export default {
    components: {
        Panorama,
        FlagAndName,
        TimerBox,
    },
    data() {
        return {
            screen: null,
            confirmClose: false,
            lowTime: false,
            countDown: -1,
            started: false,
            timerIsNew: false,
        };
    },
    computed: {
        ...mapState("partygame", [
            "socket",
            "roomCode",
            "info",
            "endTime",
            "location",
            "startTime",
            "damageMultiplier",
        ]),
        ...mapGetters("partygame", ["getRoundText"]),
    },
    methods: {
        closeRound() {
            this.socket.emit("closeRound", {
                roomCode: this.roomCode,
                authHeader: "Bearer " + localStorage.getItem("token"),
            });
        },
    },
    created() {
        this.screen = screen;
        this.regionCode = this.currentRegionCode;
    },
};
</script>

<style scoped>
#close-round-btn {
    position: absolute;
    top: 45px;
    z-index: 104;
    margin-left: auto;
    margin-right: auto;
    right: 5px;
    text-align: center;
    max-width: fit-content;
    opacity: 0.75;
}

#close-round-btn:hover {
    opacity: 1;
}
</style>
