<template>
    <md-avatar v-if="imageExists" class="md-avatar-icon"
        ><img
            :src="finalUrl"
            @error="imageLoadError"
            alt="User avatar"
    /></md-avatar>
    <md-avatar v-else class="md-avatar-icon">{{
        finalUserName[0]
    }}</md-avatar>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    methods: {
        imageLoadError() {
            // If rate limit is exceeded the image cant be loaded
            this.imageExists = false;
        },
    },
    data() {
        return {
            imageExists: false,
        };
    },
    props: {
        imgUrl: String,
        userName: String
    },
    computed: {
        ...mapGetters(["userProfile"]),
        finalUrl() {
            return this.imgUrl || this.userProfile.imgUrl;
        },
        finalUserName() {
            return this.userName || this.userProfile.userName;
        }
    },
    created() {
        this.imageExists = !!this.userProfile && !!this.finalUrl;
    },
};
</script>

<style>
</style>