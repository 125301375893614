<template>
    <div class="main-container">
        <MapCards
            @toggleFavorite="toggleFavorite"
            :cards="maps"
            :filterOnlyMyMaps="filterOnlyMyMaps"
            :filterOnlyFavorites="filterOnlyFavorites"
            :hasLobby="hasLobby"
        />
    </div>
</template>

<script>
import MapCards from "../components/MapCards";
import axiosMixin from "../mixins/axiosMixin";

export default {
    name: "Home",
    mixins: [axiosMixin],
    components: {
        MapCards,
    },
    data() {
        return {
            allMaps: [],
            maps: [],
            hasLobby: false,
        };
    },
    methods: {
        updateMapCards() {
            if (this.filterOnlyFavorites) {
                this.maps = this.allMaps.filter((m) => m.favorite);
            } else {
                this.maps = this.allMaps;
            }
        },
        toggleFavorite(fav) {
            const i = this.allMaps.findIndex((m) => m.id == fav.mapId);
            if (i >= 0) {
                this.allMaps[i].favorite = fav.favorite;
            }
        },
        loadMaps() {
            this.makeRequest(
                "get",
                process.env.VUE_APP_SERVER_URL +
                    "map/" +
                    (this.filterOnlyMyMaps ? "byUserId" : "all") // TODO remake to filter on canEdit
            ).then((resp) => {
                this.allMaps = resp.data;
                this.updateMapCards();
            });

            this.makeRequest(
                "get",
                process.env.VUE_APP_SERVER_URL + "user/hasLobby"
            ).then((resp) => {
                this.hasLobby = resp.data.hasLobby;
            });
        },
    },
    mounted() {
        this.loadMaps();
    },
    props: {
        filterOnlyFavorites: Boolean,
        filterOnlyMyMaps: Boolean,
    },
    watch: {
        filterOnlyFavorites: function () {
            this.updateMapCards();
        },
        filterOnlyMyMaps: function () {
            this.loadMaps();
        },
    },
};
</script>

<style lang="scss" scoped>
.main-container {
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 48px);
    padding-bottom: 40px;
    background: rgb(56, 66, 80);
    background: linear-gradient(
        270deg,
        rgba(56, 66, 80, 1) 0%,
        rgba(68, 92, 106, 1) 31%,
        rgb(95, 160, 154) 100%
    );
}
</style>
