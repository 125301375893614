<template>
    <md-list>
        <md-list-item to="/" @click="closeSideMenuIfSmallScreen" exact>
            <md-icon>map</md-icon>
            <span class="md-list-item-text">Browse Maps</span>
        </md-list-item>

        <md-list-item to="/favorites" @click="closeSideMenuIfSmallScreen">
            <md-icon>favorite</md-icon>
            <span class="md-list-item-text">Favorite Maps</span>
        </md-list-item>

        <md-list-item to="/myMaps" @click="closeSideMenuIfSmallScreen">
            <md-icon>edit</md-icon>
            <span class="md-list-item-text">My Maps</span>
        </md-list-item>

        <md-list-item>
            <md-icon>people</md-icon>
            <span class="md-list-item-text">Friends</span>
            <md-badge class="md-square" md-content="COMING" />
        </md-list-item>

        <md-list-item to="/multiplayer" @click="closeSideMenuIfSmallScreen">
            <md-icon>groups</md-icon>
            <span class="md-list-item-text">Join Game</span>
        </md-list-item>

        <md-list-item md-expand v-if="isAdmin" :md-expanded.sync="expandAdmin">
            <md-icon>admin_panel_settings</md-icon>
            <span class="md-list-item-text">Admin Actions</span>

            <md-list slot="md-expand">
                <md-list-item to="/whitelist" @click="closeSideMenuIfSmallScreen">
                    <md-icon>fact_check</md-icon>
                    <span class="md-list-item-text">Whitelist</span>
                    <md-badge
                        class="md-square"
                        style="background-color: #c42f2f; color: white"
                        v-if="pendingWhitelist > 0"
                        :md-content="pendingWhitelist"
                    />
                </md-list-item>
            </md-list>
        </md-list-item>

        <md-list-item to="/about" class="last-item">
            <md-icon>info</md-icon>
            <div class="md-list-item-text">
                <span>About</span>
                <span class="md-caption">Version: {{ version }}</span>
            </div>
            <md-badge
                class="md-square md-accent"
                md-content="NEW"
                v-if="isNewVersion"
            />
        </md-list-item>

        <md-list-item @click="signOut" class="">
            <md-icon v-if="userProfileSet">logout</md-icon>
            <md-icon v-else>login</md-icon>
            <span class="md-list-item-text">{{
                userProfileSet ? "Sign Out" : "Sign in"
            }}</span>
        </md-list-item>
    </md-list>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import axiosMixin from "../mixins/axiosMixin";
import utilMixin from "../mixins/utilMixin";

export default {
    name: "SideMenu",
    mixins: [axiosMixin, utilMixin],
    data() {
        return {
            isAdmin: false,
            expandAdmin: true,
        };
    },
    computed: mapGetters([
        "userProfile",
        "pendingWhitelist",
        "isNewVersion",
        "version",
        "userProfileSet",
    ]),
    watch: {
        userProfile: function () {
            this.checkIfAdmin();
        },
    },
    methods: {
        ...mapActions([
            "updatePendingWhitelist",
            "updateVersion",
        ]),
        checkIfAdmin() {
            if (this.userProfile.role === 1) {
                this.isAdmin = true;
                this.updatePendingWhitelist();
            } else {
                this.isAdmin = false;
            }
        },
    },
    mounted() {
        this.checkIfAdmin();
        this.updateVersion();
    },
};
</script>

<style scoped>
ul {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.last-item {
    margin-top: auto;
}

.md-list {
    background: rgb(232, 240, 239);
}
</style>