<template>
    <div id="profile-container">
        <ProfileAvatar
            :imgUrl="user.imgUrl"
            :userName="user.userName"
            class="profile-icon md-large"
        />

        <div class="flex-left-center">
            <span class="md-headline">{{ user.userName }}</span>
            <div v-if="user.role == 1">
                <img
                    id="admin-icon"
                    src="/img/logo.webp"
                    alt="WhereRU logo"
                />
                <md-tooltip md-direction="top">Admin</md-tooltip>
            </div>
        </div>

        <span
            >Joined
            {{
                user.joinedDate ? `${user.joinedDate}` : "a long time a go"
            }}</span
        >

        <div id="info-overview">
            <span class="md-caption"
                >Games played: {{ stats.userStats.gamesPlayed || 0
                }}<span>&#183;</span></span
            >
            <span class="md-caption"
                >Maps published: {{ stats.mapsMade || 0
                }}<span>&#183;</span></span
            >
            <span class="md-caption"
                >Map plays: {{ stats.playsOnMaps || 0 }}</span
            >
        </div>

        <md-tabs
            id="profile-tabs"
            class="md-primary"
            md-alignment="centered"
            md-sync-route
        >
            <md-tab
                md-label="Stats"
                class="md-layout md-alignment-top-center"
                :to="`/profile${
                    $route.query.userId ? '?userId=' + $route.query.userId : ''
                }`"
                exact
            >
                <div
                    id="stats-container"
                    class="md-layout md-alignment-top-center"
                >
                    <div class="md-layout-item md-xsmall-size-100">
                        <md-toolbar :md-elevation="1">
                            <span class="md-title">Overall</span>
                        </md-toolbar>
                        <div class="md-layout">
                            <md-list class="md-double-line md-layout-item">
                                <md-subheader class="md-primary"
                                    >Games Played</md-subheader
                                >
                                <md-list-item>
                                    <md-icon>bar_chart</md-icon>
                                    <div class="md-list-item-text">
                                        <span>{{
                                            stats.userStats.gamesPlayed || 0
                                        }}</span>
                                        <span>Games Played</span>
                                    </div>
                                </md-list-item>
                                <md-list-item class="">
                                    <md-icon>do_not_step</md-icon>
                                    <div class="md-list-item-text">
                                        <span
                                            >{{
                                                stats.userStats.noMovePlayed ||
                                                0
                                            }}
                                            {{
                                                getPercentageOfPlays(
                                                    stats.userStats.noMovePlayed
                                                )
                                            }}</span
                                        >
                                        <span>No move games played</span>
                                    </div>
                                </md-list-item>
                                <md-list-item class="">
                                    <md-icon>search_off</md-icon>
                                    <div class="md-list-item-text">
                                        <span
                                            >{{
                                                stats.userStats.noZoomPlayed ||
                                                0
                                            }}
                                            {{
                                                getPercentageOfPlays(
                                                    stats.userStats.noZoomPlayed
                                                )
                                            }}</span
                                        >
                                        <span>No zoom games played</span>
                                    </div>
                                </md-list-item>
                                <md-list-item class="">
                                    <md-icon>do_not_touch</md-icon>
                                    <div class="md-list-item-text">
                                        <span
                                            >{{
                                                stats.userStats.noPanPlayed || 0
                                            }}
                                            {{
                                                getPercentageOfPlays(
                                                    stats.userStats.noPanPlayed
                                                )
                                            }}</span
                                        >
                                        <span>No pan games played</span>
                                    </div>
                                </md-list-item>
                                <md-divider />
                            </md-list>
                            <md-list class="md-double-line md-layout-item">
                                <md-subheader class="md-primary"
                                    >Published Maps</md-subheader
                                >
                                <md-list-item class="">
                                    <md-icon>public</md-icon>
                                    <div class="md-list-item-text">
                                        <span>{{ stats.mapsMade || 0 }}</span>
                                        <span>Published Maps</span>
                                    </div>
                                </md-list-item>
                                <md-list-item class="">
                                    <md-icon>bar_chart</md-icon>
                                    <div class="md-list-item-text">
                                        <span>{{
                                            stats.playsOnMaps || 0
                                        }}</span>
                                        <span>Map plays</span>
                                    </div>
                                </md-list-item>
                                <md-list-item class="">
                                    <md-icon>favorite</md-icon>
                                    <div class="md-list-item-text">
                                        <span>{{
                                            stats.favoritesOnMaps || 0
                                        }}</span>
                                        <span>Favorites on published maps</span>
                                    </div>
                                </md-list-item>
                                <md-divider />
                            </md-list>
                        </div>
                    </div>

                    <div
                        id="per-game-type"
                        class="md-layout-item md-xsmall-size-100"
                    >
                        <md-toolbar :md-elevation="1">
                            <span class="md-title">Per Game Type</span>
                        </md-toolbar>
                        <div class="md-layout">
                            <md-list class="md-double-line md-layout-item">
                                <md-subheader class="md-primary"
                                    >Single Player</md-subheader
                                >
                                <md-list-item>
                                    <div class="md-list-item-text">
                                        <span>{{
                                            stats.userStats.spPlayed || 0
                                        }}</span>
                                        <span>Games Played</span>
                                    </div>
                                </md-list-item>
                                <md-divider />
                            </md-list>

                            <md-list class="md-double-line md-layout-item">
                                <md-subheader class="md-primary"
                                    >Multiplayer</md-subheader
                                >
                                <md-list-item class="">
                                    <div class="md-list-item-text">
                                        <span>{{
                                            stats.userStats.mpPlayed || 0
                                        }}</span>
                                        <span>Games Played</span>
                                    </div>
                                </md-list-item>
                                <md-list-item class="">
                                    <div class="md-list-item-text">
                                        <span>{{
                                            stats.userStats.mpHosted || 0
                                        }}</span>
                                        <span>Games Hosted</span>
                                    </div>
                                </md-list-item>
                                <md-divider />
                            </md-list>

                            <md-list class="md-double-line md-layout-item">
                                <md-subheader class="md-primary"
                                    >Party Game</md-subheader
                                >
                                <md-list-item class="">
                                    <div class="md-list-item-text">
                                        <span>{{
                                            stats.userStats.pgPlayed || 0
                                        }}</span>
                                        <span>Games Played</span>
                                    </div>
                                </md-list-item>
                                <md-list-item class="">
                                    <div class="md-list-item-text">
                                        <span>{{
                                            stats.userStats.pgHosted || 0
                                        }}</span>
                                        <span>Games Hosted</span>
                                    </div>
                                </md-list-item>
                                <md-divider />
                            </md-list>

                            <md-list class="md-double-line md-layout-item">
                                <md-subheader class="md-primary"
                                    >Duels</md-subheader
                                >
                                <md-list-item class="">
                                    <div class="md-list-item-text">
                                        <span>{{
                                            stats.userStats.duelsPlayed || 0
                                        }}</span>
                                        <span>Duels Played</span>
                                    </div>
                                </md-list-item>
                                <md-list-item class="">
                                    <div class="md-list-item-text">
                                        <span
                                            >{{
                                                stats.userStats.duelsWon || 0
                                            }}
                                            {{
                                                stats.userStats.duelsWon &&
                                                stats.userStats.duelsPlayed
                                                    ? getPercentage(
                                                          stats.userStats
                                                              .duelsWon /
                                                              stats.userStats
                                                                  .duelsPlayed
                                                      )
                                                    : ""
                                            }}</span
                                        >
                                        <span>Duels Won</span>
                                    </div>
                                </md-list-item>
                                <md-divider />
                            </md-list>
                        </div>
                    </div>
                </div>
            </md-tab>

            <md-tab
                md-label="Maps"
                :to="`/profile/maps${
                    $route.query.userId ? '?userId=' + $route.query.userId : ''
                }`"
                id="map-tab"
            >
                <MapCards :cards="maps" :canFavorite="false" />
            </md-tab>

            <md-tab md-label="Friends" md-disabled> </md-tab>

            <md-tab
                md-label="Settings"
                v-if="!$route.query.userId"
                class="md-layout md-alignment-top-center"
                to="/profile/settings"
            >
                <md-card
                    class="full-card md-layout-item md-size-45 md-medium-size-50 md-small-size-100"
                    style="text-align: left"
                >
                    <md-card-header>
                        <div class="md-title">General</div>
                    </md-card-header>

                    <md-card-content>
                        <md-field :class="errorClass">
                            <label>Username</label>
                            <md-input
                                v-model="settings.userName"
                                :disabled="!isReadyForUsernameChange"
                                :md-counter="
                                    userNameChanged ? userNameValidation.max : 0
                                "
                            ></md-input>
                            <span
                                class="md-error"
                                v-if="!this.isReadyForUsernameChange"
                                >Username can be changed again
                                {{
                                    nextDateForUserNameChange.toLocaleDateString(
                                        "en-gb",
                                        {
                                            weekday: "long",
                                            month: "short",
                                            day: "numeric",
                                        }
                                    )
                                }}</span
                            >
                            <span class="md-error" v-if="userNameError">{{
                                userNameError
                            }}</span>
                        </md-field>

                        <md-field>
                            <label>Email</label>
                            <md-input
                                v-model="user.userEmail"
                                :disabled="true"
                            ></md-input>
                            <span class="md-suffix">Not changeable</span>
                        </md-field>

                        <div class="small-radio">
                            <span class="md-title">
                                OpenStreetMap link
                                <md-button
                                    class="md-icon-button"
                                    style="position: relative; bottom: 2px"
                                    @click="showOSMDialog = true"
                                >
                                    <md-icon> info </md-icon>
                                </md-button>
                            </span>
                            <div>
                                <md-radio v-model="localMapUrl" value="de"
                                    >German (recommended)</md-radio
                                >
                                <md-radio v-model="localMapUrl" value="default"
                                    >Standard OpenStreetMap</md-radio
                                >
                            </div>
                        </div>
                    </md-card-content>

                    <md-card-actions>
                        <md-button
                            class="md-accent"
                            :disabled="
                                !settingsChanged || !!userNameError || saving
                            "
                            @click="save"
                            >Save</md-button
                        >
                    </md-card-actions>
                </md-card>
            </md-tab>
            <md-dialog-confirm
                :md-active.sync="showUserNameChangeWarning"
                md-title="Change Username?"
                md-content="Are you sure you want to change username? You can only do this once every 7 days."
                md-confirm-text="Agree"
                md-cancel-text="Disagree"
                @md-confirm="forceSave"
            />

            <md-dialog :md-active.sync="showOSMDialog">
                <md-dialog-title>Selecting OSM tile set</md-dialog-title>

                <md-content style="padding: 0 23px; max-width: 400px">
                    <span class="md-subheading" style="font-weight: bold"
                        >German (recommended)</span
                    >
                    <p>
                        Uses the german fork of OSM
                        <a target="_blank" href="https://openstreetmap.de/karte/"
                            >[link]</a
                        >. Recommended as it uses latin characters for all
                        locations (e.g. locations in China could be hard to interpret with the standard OSM).
                    </p>
                    <span class="md-subheading" style="font-weight: bold"
                        >Standard OpenStreetMap</span
                    >
                    <p>
                        Uses the standard OSM map
                        <a
                            target="_blank"
                            href="https://www.openstreetmap.org/"
                            >[link]</a
                        >. Generally has better performance (loading time) than
                        the german fork. Also more up to date and has less risk
                        of being down due to server error.
                    </p>
                    <br />
                    <p>
                        Other tile sets are available
                        <a
                            target="_blank"
                            href="https://wiki.openstreetmap.org/wiki/Raster_tile_providers"
                            >[link]</a
                        >, however most cost money and we thus avoid those.
                    </p>
                </md-content>

                <md-dialog-actions>
                    <md-button class="md-primary" @click="showOSMDialog = false"
                        >Close</md-button
                    >
                </md-dialog-actions>
            </md-dialog>

            <md-snackbar :md-duration="4000" :md-active.sync="showSnackbar">
                <span>{{ savedMessage }}</span>
                <md-button class="md-primary" @click="showSnackbar = false"
                    >Close</md-button
                >
            </md-snackbar>
        </md-tabs>
    </div>
</template>

<script>
import ProfileAvatar from "../components/ProfileAvatar.vue";
import MapCards from "../components/MapCards.vue";

import axiosMixin from "../mixins/axiosMixin";
import utilMixin from "../mixins/utilMixin";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
    mixins: [axiosMixin, utilMixin],
    components: {
        ProfileAvatar,
        MapCards,
    },
    data() {
        return {
            stats: { userStats: {} },
            maps: [],
            user: {},
            settings: {
                userName: "",
                mapUrl: "",
            },
            showUserNameChangeWarning: false,
            showSnackbar: false,
            savedMessage: "",
            userNameError: "",
            tab: "stats",
            localMapUrl: "de",

            saving: false,

            orgMapUrl: null,

            showOSMDialog: false,
        };
    },
    watch: {
        userProfile: function () {
            this.user = this.userProfile;
            this.settings.userName = this.user.userName;
        },
        "settings.userName": function (newVal, oldVal) {
            if (newVal !== oldVal) {
                const [valid, text] = this.validateUsername(newVal);
                if (!valid) {
                    this.userNameError = text;
                } else {
                    this.userNameError = "";
                }
            }
        },
        localMapUrl: function () {
            this.settings.mapUrl = this.localMapUrl;
        },
    },
    computed: {
        ...mapGetters(["userProfile", "mapUrl"]),
        settingsChanged() {
            return this.userNameChanged || this.osmChanged;
        },
        userNameChanged() {
            return this.settings.userName !== this.user.userName;
        },
        nextDateForUserNameChange() {
            let d = this.user.userNameLastChanged;

            if (!d) {
                let f = new Date();
                f.setHours(0, 0, 0, 0);
                return f;
            }
            let e = new Date(d);
            e.setHours(0, 0, 0, 0);
            e = new Date(e.setDate(e.getDate() + 7));
            return e;
        },
        isReadyForUsernameChange() {
            let d = new Date();
            d.setHours(0, 0, 0, 0);
            return d - this.nextDateForUserNameChange >= 0;
        },
        errorClass() {
            return {
                "md-invalid":
                    !this.isReadyForUsernameChange || this.userNameError,
            };
        },
        osmChanged() {
            return this.orgMapUrl !== this.settings.mapUrl;
        },
    },
    methods: {
        ...mapActions(["setProfile"]),
        ...mapMutations(["setMapUrl"]),
        updateProfile() {
            this.setProfile();
            this.user = this.userProfile;
            this.settings.userName = this.user.userName;
        },
        getPercentageOfPlays(val) {
            return this.stats.userStats.gamesPlayed > 0
                ? this.getPercentage(val / this.stats.userStats.gamesPlayed)
                : "";
        },
        getPercentage(val) {
            return `(${Math.round(val * 100)}%)`;
        },
        save() {
            if (this.userNameChanged) {
                this.saving = true;
                const [valid, text] = this.validateUsername(
                    this.settings.userName
                );
                if (!valid) {
                    this.userNameError = text;
                } else {
                    this.userNameError = "";
                    this.settings.userName = text;
                    this.showUserNameChangeWarning = true;
                }
            } else if (this.osmChanged) {
                this.saving = true;
                this.forceSave();
            }
        },
        forceSave() {
            this.setMapUrl(this.localMapUrl);
            this.orgMapUrl = this.localMapUrl;
            axiosMixin.methods
                .makeRequest(
                    "put",
                    process.env.VUE_APP_SERVER_URL + "user/updateSettings",
                    null,
                    this.settings
                )
                .then((resp) => {
                    this.saving = false;
                    this.showSnackbar = true;
                    this.setProfile();

                    if (resp.status == 200) {
                        this.savedMessage = "Saved!";
                    } else {
                        this.savedMessage = "Error saving";
                    }
                });
        },
        loadUser(userId) {
            if (!userId) {
                this.updateProfile();
                userId = this.user.userId;
                this.settings.mapUrl = this.user.mapUrl;
                this.localMapUrl = this.user.mapUrl;
                this.orgMapUrl = this.user.mapUrl;
            } else {
                axiosMixin.methods
                    .makeRequest(
                        "get",
                        process.env.VUE_APP_SERVER_URL + "user/info",
                        {
                            userId,
                        }
                    )
                    .then((resp) => {
                        if (resp.status == 200) {
                            this.user = resp.data;
                            this.settings.userName = this.user.userName;
                            this.settings.mapUrl = this.user.mapUrl;
                            this.localMapUrl = this.user.mapUrl;
                            this.orgMapUrl = this.user.mapUrl;
                        }
                    });
            }

            axiosMixin.methods
                .makeRequest(
                    "get",
                    process.env.VUE_APP_SERVER_URL + "user/statistics",
                    {
                        userId,
                    }
                )
                .then((resp) => {
                    if (resp.status == 200) {
                        this.stats = resp.data;
                    }
                });

            axiosMixin.methods
                .makeRequest(
                    "get",
                    process.env.VUE_APP_SERVER_URL + "map/byUserId",
                    {
                        userId,
                    }
                )
                .then((resp) => {
                    if (resp.status === 200) {
                        this.maps = resp.data;
                    }
                });
        },
    },
    created() {
        this.loadUser(this.$route.query.userId);
        this.orgMapUrl = this.mapUrl;
        this.localMapUrl = this.mapUrl;
    },
    beforeRouteLeave(to, from, next) {
        if (
            to.path.startsWith("/profile/") &&
            to.query.userId !== from.query.userId
        ) {
            this.loadUser(to.query.userId);
        }
        next();
    },
    beforeRouteUpdate(to, from, next) {
        if (to.query.userId !== from.query.userId) {
            this.loadUser(to.query.userId);
        }
        next();
    },
};
</script>

<style scoped>
#stats-container {
    max-width: 1200px;
}

#profile-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.full-card {
    margin: 0;
}

.profile-icon {
    margin-top: 10px;
    margin-bottom: 10px;
}

#info-overview span span {
    margin-left: 5px;
    margin-right: 5px;
}

#profile-tabs {
    width: 100%;
    margin-top: 10px;
}

@media only screen and (min-height: 600px) {
    #profile-tabs {
        max-height: calc(100vh - 215px);
    }
    #profile-tabs /deep/ .md-tab {
        max-height: calc(100vh - 260px);
        overflow-y: auto;
    }
}

@media screen and (min-width: 500px) {
    #per-game-type {
        margin-left: 10px;
    }
}

#admin-icon {
    height: 25px;
    margin-left: 5px;
}

#map-tab {
    padding-bottom: 40px;
}

.small-radio .md-radio {
    margin: 4px 15px 4px 0;
}
</style>