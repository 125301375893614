<template>
    <md-table class="high-score-table md-layout-item" v-if="highscores.length > page * pageSize || page == 0">
        <md-table-row>
            <md-table-head>#</md-table-head>
            <md-table-head>User</md-table-head>
            <md-table-head>Score</md-table-head>
            <md-table-head>Time</md-table-head>
            <md-table-head>Date</md-table-head>
        </md-table-row>

        <md-table-row
            v-for="(highscore, index) in highscores.slice(
                page * pageSize,
                (page + 1) * pageSize
            )"
            :key="`highscore-list-item-${index}`"
            @click="goToProfile(highscore.userId)"
            class="selectable"
        >
            <md-table-cell>{{ index + 1 + page * pageSize }}</md-table-cell>
            <md-table-cell>{{ highscore.userName }}</md-table-cell>
            <md-table-cell>{{ highscore.score }}</md-table-cell>
            <md-table-cell>{{ getTimeText(highscore.time) }}</md-table-cell>
            <md-table-cell>{{ new Date(highscore.date).toLocaleDateString("en-UK") }}</md-table-cell>
        </md-table-row>
    </md-table>
</template>

<script>
import utilMixin from "../mixins/utilMixin";

export default {
    name: "HighscoreColumn",
    mixins: [utilMixin],
    props: {
        page: Number,
        pageSize: Number,
        highscores: Array,
    },
    methods: {
        goToProfile(userId) {
            this.$router.push({
                name: "Profile",
                query: {
                    userId,
                },
            });
        }
    }
};
</script>

<style scoped>
.high-score-table /deep/ .selectable {
    cursor: pointer;
}
</style>