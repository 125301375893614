var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md-layout flex-grow",attrs:{"id":"display-answers-container"}},[_c('div',{staticClass:"md-layout-item md-small-size-50 md-xsmall-size-100",staticStyle:{"position":"relative"}},[_c('InteractiveMap',{attrs:{"id":"display-answers-map","markerGroups":_vm.guessMarkers,"settings":_vm.center,"centerMapAtStart":true}}),(!_vm.isTotal)?[_c('div',{staticClass:"overlay-bar"}),_c('span',{staticClass:"md-headline overlay top left"},[_vm._v(_vm._s(_vm.getRoundText))]),_c('span',{staticClass:"md-headline overlay top right",attrs:{"id":"roomCodeSpan"}},[_vm._v(_vm._s(_vm.roomCode))])]:_vm._e()],2),_c('div',{staticClass:"md-layout-item md-small-size-50 md-xsmall-size-100 score-table-container flex-down"},[(_vm.info.roles.includes('GUESSER'))?_c('PlayerBanner'):_vm._e(),(_vm.isEnd)?_c('span',{staticClass:"md-display-3"},[_vm._v("Final "+_vm._s(_vm.info.settings.duel ? "Standings" : "Scores"))]):(_vm.correctRegionCode)?[_c('div',{staticClass:"flex-center",staticStyle:{"padding-left":"5px","padding-right":"5px","max-width":"100vw"}},[(
                        _vm.info.settings.elimination &&
                        _vm.info.settings.partyGameType === 0 &&
                        _vm.info.roles.includes('GUESSER')
                    )?_c('BonusGuessDisplay'):_vm._e()],1),_c('CorrectRegionDisplay',{attrs:{"correctRegionCode":_vm.correctRegionCode}})]:_vm._e(),_c('md-table',{staticClass:"flex-grow",attrs:{"id":"score-table","md-fixed-header":""},scopedSlots:_vm._u([{key:"md-table-row",fn:function({ item, index }){return _c('md-table-row',{staticClass:"big-row",class:{ 'player-self': item.playerId === _vm.publicPlayerId },style:({
                    'background-color': item.color ? item.color : 'none',
                })},[(item.img && !item.teamId)?_c('md-table-cell',{attrs:{"md-label":""}},[_c('md-icon',{class:{ 'md-size-2x': _vm.screenWidth > 1400 }},[_vm._v(_vm._s(item.img))])],1):(item.teamId && !_vm.isTotal)?_c('md-table-cell',{attrs:{"md-label":""}},[_vm._v(_vm._s(item.playerName ? item.playerName : "-"))]):_vm._e(),_c('md-table-cell',{attrs:{"md-label":""}},[_vm._v(_vm._s(item.text ? item.text : item.teamName ? item.teamName : item.playerName))]),_c('md-table-cell',{class:{ emph: index == 0 },attrs:{"md-label":_vm.useHealth ? 'Health' : _vm.isTotal ? 'Total' : 'Score'}},[(!_vm.useHealth && !_vm.info.settings.guessRegion)?[_vm._v(" "+_vm._s(Math.max( _vm.totalScore(item.playerId, item.teamId), 0 ))+" ")]:(
                            !_vm.info.settings.elimination &&
                            !_vm.info.settings.guessRegion
                        )?[_vm._v(" "+_vm._s(Math.max( _vm.remainingHealth(item.playerId, item.teamId), 0 ))+" ")]:(!_vm.info.settings.elimination)?[_vm._v(" "+_vm._s(Math.max( _vm.totalCorrectRegions( item.playerId, item.teamId ), 0 ))+" ")]:_c('div',{staticClass:"flex-start"},[(
                                _vm.remainingHealth(
                                    item.playerId,
                                    item.teamId
                                ) > 0
                            )?_vm._l((_vm.remainingHealth(
                                    item.playerId,
                                    item.teamId
                                )),function(h,i){return _c('md-icon',{key:i,staticClass:"heart",class:{
                                    'md-size-2x': _vm.screenWidth > 1400,
                                }},[_vm._v(" favorite ")])}):_c('span',[_vm._v("Out")])],2)],2),(!_vm.isTotal)?[(!_vm.info.settings.guessRegion)?_c('md-table-cell',{class:{ emph: _vm.isBestThisRound(item.score) },attrs:{"md-label":"Round"}},[_vm._v(_vm._s(item.score)+" ")]):_vm._e(),(_vm.info.settings.guessRegion)?_c('md-table-cell',{attrs:{"md-label":"Guess"}},[_c('div',{staticClass:"flex-center"},[(
                                    item.regionCode && item.regionCode.code
                                )?[_c('div',{on:{"mouseover":function($event){_vm.showRegionName = true},"mouseleave":function($event){_vm.showRegionName = false},"click":function($event){_vm.showRegionName = !_vm.showRegionName}}},[_c('img',{staticClass:"guess-flag",attrs:{"src":_vm.getFlagUrl(item.regionCode.code)}}),_c('md-tooltip',{attrs:{"md-direction":"left","md-active":_vm.showRegionName},on:{"update:mdActive":function($event){_vm.showRegionName=$event},"update:md-active":function($event){_vm.showRegionName=$event}}},[_vm._v(_vm._s(item.regionCode.name))])],1),(
                                        _vm.correctRegionCode.code ===
                                        item.regionCode.code
                                    )?_c('md-icon',{staticStyle:{"margin":"0"}},[_vm._v(" done ")]):_c('md-icon',{staticStyle:{"margin":"0"}},[_vm._v(" close ")])]:_c('div',[_vm._v("-")])],2)]):_vm._e(),_c('md-table-cell',{attrs:{"md-label":"Distance"}},[_vm._v(_vm._s(item.distance > 0 ? _vm.getDistanceString(Math.round(item.distance)) : "-"))])]:_vm._e()],2)}}]),model:{value:(_vm.listRows),callback:function ($$v) {_vm.listRows=$$v},expression:"listRows"}}),(_vm.info.host)?_c('BigButton',{staticClass:"bottom-right md-primary",on:{"click":_vm.nextRound}},[_vm._v(_vm._s(_vm.isEnd ? "Continue" : "Next"))]):_c('div',{staticClass:"flex-end"},[_c('p',{staticClass:"bottom-right"},[_vm._v(" "+_vm._s(_vm.isEnd ? "Wait for host" : "Wait for host to start next round")+" ")])])],2),_c('md-dialog',{staticStyle:{"margin":"auto"},attrs:{"md-active":_vm.showScoreClash,"md-click-outside-to-close":false,"md-fullscreen":false},on:{"update:mdActive":function($event){_vm.showScoreClash=$event},"update:md-active":function($event){_vm.showScoreClash=$event}}},[_c('ScoreClash',{staticStyle:{"width":"min(100vw, 500px)"},attrs:{"team1":_vm.team1,"team2":_vm.team2,"round":_vm.info.round,"duelHP":_vm.info.settings.duelHP},on:{"close":function($event){_vm.showScoreClash = false}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }