<template>
    <div class="player-lobby-container">
        <div v-if="!team" style="margin-top: 50px;"/>
        <span class="sf-big bold" id="room-code">{{ roomCode }}</span>
        <PlayerBanner />
        <div
            v-if="partyGameType === 1"
            class="md-layout md-alignment-center-center"
        >
            <md-button
                v-for="t in filteredTeamList"
                :key="t.teamId"
                class="md-layout-item md-xlarge-size-20 md-medium-size-40 md-xsmall-size-75 md-raised team-button"
                :style="{'background-color': t.color}"
                @click="joinTeam(t.teamId)"
                :disabled="awaitJoinedTeam || (team != null && team.teamId === t.teamId)"
                >{{ t.name }}</md-button
            >
        </div>
        <span
            v-if="team == null && partyGameType === 1"
            style="color:red;"
            class="flex-center info-text md-headline"
            >You must join a team or you will be kicked when the host starts the game</span
        >
        <span
            v-else
            class="flex-center info-text md-headline"
            >Waiting for host to start. Can you see your name on the host's
            screen?</span
        >
        <div id="countdown" style="margin-top: 10px" v-if="intermission">
            <span class="sf-big">Game starts soon</span> <!-- TODO make countdown accurate -->
        </div>
    </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex';
import PlayerBanner from '../../components/party/PlayerBanner.vue';

export default {
    components: {
        PlayerBanner
    },

    data() {
        return {
            awaitJoinedTeam: false,
        }
    },

    props: {
        intermission: Boolean,
    },

    computed: {
        ...mapState("partygame", ["socket", "teamList", "playerId", "team", "playerImg", "username", "roomCode",]),
        ...mapGetters("partygame", ["partyGameType"]),
        filteredTeamList() {
            if (!this.intermission) {
                return this.teamList;
            }

            return this.teamList.filter(t => this.team.teamId === t.teamId);
        },
    },

    methods: {
        ...mapMutations("partygame", ["setTeam", "commitTeam", "deleteTeam"]),

        joinTeam(teamId) {
            this.awaitJoinedTeam = true;
            this.socket.emit("joinTeam", {
                teamId,
                playerId: this.playerId,
            });
        }
    },

    created() {
        this.socket.on("joined_team", (resp) => {
            this.awaitJoinedTeam = false;
            this.setTeam(resp.team);
        });
    },
    beforeDestroy() {
        this.socket.removeAllListeners("joined_team");
    }
};
</script>

<style scoped>
#room-code {
    position: absolute;
    top: 0;
    right: 5px;
}
</style>

<style>
.team-button {
    margin-left: 0px;
    margin-top: 20px;
    margin-bottom: 20px;
    height: 70px;
    font-size: 40px;
    max-width: 200px !important;
}

.info-text {
    margin: 5px 20px;
}
</style>