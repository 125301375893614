<template>
    <md-table>
        <md-table-row>
            <md-table-head width="50px">Round</md-table-head>
            <md-table-head>Score</md-table-head>
            <md-table-head>Distance</md-table-head>
            <md-table-head>Time</md-table-head>
        </md-table-row>

        <md-table-row
            v-for="(score, index) in scores"
            :key="`score-list-item-${index}`"
        >
            <md-table-cell>{{ index + 1 }}</md-table-cell>
            <md-table-cell>{{ score.score }}</md-table-cell>
            <md-table-cell>{{
                getDistanceString(distances[index])
            }}</md-table-cell>
            <md-table-cell>{{ getTimeText(score.milliseconds) }}</md-table-cell>
        </md-table-row>
        <md-table-row v-if="sumRow && scores.length > 1">
            <md-table-cell class="md-title"> Total </md-table-cell>
            <md-table-cell class="md-title">
                {{
                    scores
                        .map((score) => score.score)
                        .reduce((a, b) => a + b, 0)
                }}
            </md-table-cell>
        </md-table-row>
    </md-table>
</template>

<script>
import utilMixin from "../mixins/utilMixin";

export default {
    name: "ScoreTable",
    mixins: [utilMixin],
    props: {
        scores: Array,
        sumRow: Boolean,
        distances: Array,
    },
};
</script>

<style>
.md-table {
    text-align: left;
}
</style>