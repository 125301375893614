<template>
    <div id="guess-map" :class="{ large: isLarge }" v-show="visible">
        <InteractiveMap
            ref="interactiveMap"
            class="flex-grow"
            :canPlaceMarker="canPlaceMarker"
            :canLarge="true"
            :isLarge="isLarge"
            :settings="settings"
            :triggerClear="triggerMapClear"
            @toggleMapSize="toggleMapSize"
            @markerPlaced="setMarkerPlaced"
            @markerPosUpdate="updateMarkerPos"
            :guessRegionCode="guessRegionCode"
        />
        <p
            id="btn-guess"
            class="unselectable-text"
            v-bind:class="{ 'btn-green-on-hover': markerPlaced }"
            v-on:click="onMakeGuess(false)"
            :disabled="!canPlaceMarker"
        >
            {{ markerPlaced ? "MAKE GUESS" : "PLACE MARKER" }}
        </p>
    </div>
</template>

<script>
import axiosMixin from "../mixins/axiosMixin";
import InteractiveMap from "../components/InteractiveMap.vue";

import { mapActions, mapGetters } from "vuex";

export default {
    name: "GuessMap",
    mixins: [axiosMixin],
    components: { InteractiveMap },
    props: {
        canPlaceMarker: Boolean,
        visible: Boolean,
    },
    data() {
        return {
            settings: {
                zoom: 0,
                center: { lat: 0, lng: 0 },
                clickableIcons: false,
                keyboardShortcuts: false,
                disableDefaultUI: true,
            },
            isLarge: false,
            markerPlaced: false,
            markerPos: null,
            triggerMapClear: false,
            guessRegionCode: null,
        };
    },
    watch: {
        guessTrigger: function () {
            this.onMakeGuess(true);
        },
        guessTriggerIfMarker: function () {
            this.onMakeGuess(false);
        },
    },
    computed: {
        ...mapGetters([
            "getZoom",
            "getCenter",
            "guessTrigger",
            "guessTriggerIfMarker",
            "gameInstanceId",
            "isRegionGuessing",
        ]),
    },
    methods: {
        ...mapActions(["makeGuess", "placeGuessMarker", "clearGuessMarker"]),

        /**
         * Called when the "MAKE GUESS" button is pressed
         */
        onMakeGuess(makeGuessAlways) {
            if (this.markerPlaced || makeGuessAlways) {
                this.isLarge = false;
                this.placeGuessMarker(
                    this.markerPlaced ? this.markerPos : null
                );
                this.makeGuess();
                this.triggerMapClear = !this.triggerMapClear;
                this.clearGuessMarker();
            }
        },
        setMarkerPlaced(markerPlaced) {
            this.markerPlaced = markerPlaced;
        },
        updateMarkerPos(markerPos) {
            this.markerPos = markerPos;
            if (this.isRegionGuessing) {
                this.makeRequest(
                    "get",
                    process.env.VUE_APP_SERVER_URL + "game/getRegionCode",
                    {
                        lat: markerPos.lat,
                        lng: markerPos.lng,
                        gameInstanceId: this.gameInstanceId,
                    }
                ).then((resp) => {
                    if (resp.status == 200) {
                        this.guessRegionCode = resp.data;
                    }
                });
            }
        },
        toggleMapSize() {
            this.isLarge = !this.isLarge;
        },
    },
    created() {
        this.settings.zoom = this.getZoom;
        this.settings.center = this.getCenter;
    },
};
</script>


<!------------------Styling---------------->
<style scoped>
#guess-map {
    position: absolute;
    bottom: 18px;
    right: 15px;
    z-index: 401;
    display: flex;
    flex-flow: column;
    max-height: calc(100% - 25px);
    min-height: 200px;
    max-width: calc(100% - 30px);
    height: 35vh;
    width: max(25vw, 200px);
    opacity: 0.5;
}

#guess-map:hover {
    opacity: 1;
    transition-property: opacity;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
}

#guess-map.large {
    opacity: 1;
    height: max(80vh, 700px);
    width: max(60vw, 400px);
}

#btn-guess {
    margin-top: 5px;
    margin-bottom: 0;
    padding: 10px;
    background: rgba(100, 100, 100, 0.8);
    color: rgba(200, 200, 200, 0.9);

    font-family: "Coda";
    font-size: 16px;
    font-weight: bold;
}

.btn-green-on-hover:hover {
    background-color: rgba(0, 201, 33, 0.8) !important;
    color: rgba(255, 255, 255, 0.9) !important;
}
</style>