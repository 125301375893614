<template>
    <md-toolbar class="md-primary md-dense md-layout md-alignment-center-right">
        <div>
            <md-button
                class="md-icon-button"
                @click="toggleMenuVisible"
                v-if="hasSideMenu"
            >
                <md-icon>menu</md-icon>
            </md-button>
            <router-link to="/" v-else>
                <md-button class="md-icon-button">
                    <md-icon>home</md-icon>
                </md-button>
            </router-link>
        </div>

        <div class="md-layout-item">
            <router-link
                class="md-title unselectable-text"
                to="/"
                custom
                v-slot="{ href, navigate }"
            >
                <a :href="href" @click="navigate">WhereRU</a>
            </router-link>
        </div>

        <div class="" style="display: contents">
            <div class="vl" />
            
            <md-menu
                md-size="medium"
                md-align-trigger
                v-if="userProfileSet && userProfile.imgUrl"
                class="profile-container"
            >
                <md-button md-menu-trigger class="is-icon">
                    <ProfileAvatar/>
                    <span class="md-subheading">{{
                        userProfile.userName
                    }}</span>
                </md-button>

                <md-menu-content>
                    <md-menu-item to="/profile"><md-icon>account_circle</md-icon><span class="md-list-item-text">Profile</span></md-menu-item>
                    <md-menu-item to="/profile/settings"><md-icon>settings</md-icon><span class="md-list-item-text">Settings</span></md-menu-item>
                    <md-divider class="md-inset"></md-divider>
                    <md-menu-item @click="signOut"><md-icon>logout</md-icon><span class="md-list-item-text">Sign Out</span></md-menu-item>
                </md-menu-content>
            </md-menu>

            <div v-else class="profile-container">
                <md-button @click="goToLogin" style="margin-left: 16px"
                    >LOGIN</md-button
                >
            </div>
        </div>
    </md-toolbar>
</template>

<script>
import ProfileAvatar from "./ProfileAvatar.vue";
import { mapGetters } from "vuex";
import utilMixin from "../mixins/utilMixin";

export default {
    name: "TopBar",
    mixins: [utilMixin],
    components: {
        ProfileAvatar
    },
    computed: mapGetters([
        "userProfile",
        "hasSideMenu",
        "userProfileSet",
    ]),
    methods: {
        goToLogin() {
            this.$router.push({ 
                name: "Login",
                query: {
                    nextUrl: this.$route.fullPath,
                },
            });
        }
    },
};
</script>

<style scoped>
.profile-container {
    margin-left: 5px;
    height: 100%;
} 

.profile-container button {
    text-transform: none;
}

.profile-container button.is-icon {
    height: 100%;
}

.md-toolbar {
    text-align: left;
}

.md-avatar-icon {
    margin-right: 10px;
    margin-left: 0px;
}

.bar-el-container {
    display: contents;
}

.bar-element {
    display: grid;
    text-align: center;
    margin: 0 18px;
}

.vl {
    border-left: 2px solid #3c4659;
    height: 30px;
}
</style>