<template>
    <div class="flex-down flex-grow">
        <!-- Team banner -->
        <!-- TODO USE PLAYERBANNER -->
        <span
            style="padding: 15px 0"
            :style="{ 'background-color': team.color }"
            class="text fat medium"
            v-if="isTeams"
        >
            {{ team ? team.name : "Error" }}
        </span>
        <div v-else class="flex-center" style="margin: 0px 0 35px 0"></div>

        <!-- Player banner -->
        <div class="flex-center" style="margin: 10px 0 10px 0">
            <span class="md-display-2">{{ username }}</span>
            <md-icon class="md-size-2x" style="margin: 5px 10px">{{
                playerImg ? playerImg : "person"
            }}</md-icon>
        </div>

        <span class="text medium" v-if="!isEnd">Waiting for next round</span>

        <div
            style="width: 100%; margin-top: 10px"
            class="flex-center"
            v-if="
                info.settings.elimination &&
                info.settings.partyGameType === 0 &&
                info.roles.includes('GUESSER') &&
                !isEnd &&
                isAlive
            "
        >
            <BonusGuessDisplay />
        </div>

        <div v-if="isTeams && !info.settings.guessRegion">
            <md-button
                class="md-raised"
                :class="{ 'md-primary': displayTeamScore }"
                @click="displayTeamScore = true"
                >TEAM SCORE</md-button
            >
            <md-button
                class="md-raised"
                :class="{ 'md-primary': !displayTeamScore }"
                @click="displayTeamScore = false"
                >YOUR SCORE</md-button
            >
        </div>

        <div id="info-content" v-if="info.settings.guessRegion">
            <div class="info-row flex-center">
                <div class="flex-down">
                    <span class="text fat small">Guess: </span>
                    <span
                        v-if="guesserResponse.playerScore.regionCode"
                        class="text fat medium region-text"
                        style="max-width: 320px"
                        :class="{
                            correct:
                                guesserResponse.playerScore.roundCorrectRegion,
                        }"
                        >{{ guesserResponse.playerScore.regionCode.name }}</span
                    >
                </div>
                <img
                    v-if="guesserResponse.playerScore.regionCode"
                    :src="
                        getFlagUrl(guesserResponse.playerScore.regionCode.code)
                    "
                />
                <span class="text fat medium" style="margin-left: 5px" v-else>
                    No guess
                </span>
            </div>
            <md-divider />
            <ScoreDispText
                v-if="!info.settings.elimination"
                :header="isEnd ? '' : 'Overall'"
                :posText="
                    isTeams
                        ? isEnd
                            ? 'Your team placed:'
                            : 'Your team is:'
                        : isEnd
                        ? 'You placed:'
                        : 'You are:'
                "
                :pos="
                    isTeams
                        ? guesserResponse.teamScore.position
                        : guesserResponse.playerScore.position
                "
                scoreString="Correct guesses:"
                :score="
                    isTeams
                        ? guesserResponse.teamScore.totalCorrectRegions
                        : guesserResponse.playerScore.totalCorrectRegions
                "
            />
            <div v-else class="flex-down">
                <span class="text fat small">
                    {{ isAlive ? "Remaining health" : "You are out :(" }}</span
                >
                <div class="flex-center">
                    <md-icon
                        style="color: red; margin: 0"
                        class="md-size-2x"
                        v-for="(h, i) in isTeams
                            ? guesserResponse.teamScore.health
                            : guesserResponse.playerScore.health"
                        :key="i"
                    >
                        favorite
                    </md-icon>
                </div>
            </div>
        </div>
        <div id="info-content" v-else>
            <template v-if="!displayTeamScore">
                <template v-if="!isEnd">
                    <ScoreDispText
                        posText="This round you placed:"
                        :pos="guesserResponse.playerScore.roundPosition"
                        scoreString="This round you scored:"
                        :score="guesserResponse.playerScore.roundScore"
                        :regionCode="guesserResponse.playerScore.regionCode"
                    />
                    <md-divider />
                </template>

                <ScoreDispText
                    :header="isEnd ? '' : 'Overall'"
                    :posText="isEnd ? 'You placed:' : 'You are:'"
                    :pos="guesserResponse.playerScore.position"
                    :scoreString="isEnd ? 'You scored:' : 'Total score:'"
                    :score="guesserResponse.playerScore.totalScore"
                />
            </template>

            <template v-if="isTeams && displayTeamScore">
                <template v-if="!isEnd">
                    <ScoreDispText
                        posText="This round your team placed:"
                        :pos="guesserResponse.teamScore.roundPosition"
                        scoreString="This round your team scored:"
                        :score="guesserResponse.teamScore.roundScore"
                        :regionCode="guesserResponse.teamScore.regionCode"
                    />
                    <md-divider />
                </template>

                <ScoreDispText
                    :header="isEnd ? '' : 'Overall'"
                    :posText="isEnd ? 'Your team placed:' : 'Your team is:'"
                    :pos="guesserResponse.teamScore.position"
                    :scoreString="isEnd ? 'Your team scored:' : 'Team score:'"
                    :score="guesserResponse.teamScore.totalScore"
                />
            </template>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import ScoreDispText from "../../components/party/ScoreDispText.vue";
import BonusGuessDisplay from "../../components/party/BonusGuessDisplay.vue";
import utilMixin from "../../mixins/utilMixin";

export default {
    mixins: [utilMixin],
    data() {
        return {
            displayTeamScore: false,
        };
    },
    props: {
        isEnd: Boolean,
    },
    components: {
        ScoreDispText,
        BonusGuessDisplay,
    },
    computed: {
        ...mapState("partygame", [
            "info",
            "guesserResponse",
            "team",
            "playerImg",
            "username",
        ]),
        ...mapGetters("partygame", ["isTeams"]),
        isAlive() {
            return (
                (this.isTeams
                    ? this.guesserResponse.teamScore.health
                    : this.guesserResponse.playerScore.health) > 0
            );
        },
    },
    methods: {
        getOrdinal(number) {
            if (10 < number && number < 20) {
                return "th";
            }

            switch (number % 10) {
                case 1:
                    return "st";
                case 2:
                    return "nd";
                case 3:
                    return "rd";
                default:
                    return "th";
            }
        },
    },
    created() {
        this.displayTeamScore = this.isTeams;
    },
};
</script>

<style scoped>
#info-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: top;
    row-gap: 2.5vh;
    margin-top: 10px;
}
.text {
    line-height: normal;
}
.fat {
    font-weight: bold;
}
.small {
    font-size: 18px;
}
.medium {
    font-size: 36px;
}
.big {
    font-size: 72px;
}
</style>