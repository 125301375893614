<template>
    <div style="width: 100%; overflow: hidden; height: calc(100vh - 48px);">
    <MapImage class="background" :imgUrl="backgroundImg" :largeImg="true"/>
    <md-card class="invite-card">
        <md-card-header>
            <div class="creator-container">
                <span class="md-title" v-if="creator"
                    ><b>{{ creator }}</b> has invited you to play</span
                >
            </div>
            <div class="mapname-container">
                <span class="md-display-2">{{ mapName }}</span>
            </div>
        </md-card-header>

        <md-divider></md-divider>

        <md-card-actions>
            <div class="center-span">
                <md-button
                    class="md-raised md-accent"
                    v-on:click="onStart"
                    :disabled="!gameId"
                    >Join Game</md-button
                >
            </div>
        </md-card-actions>
    </md-card>
    </div>
</template>

<script>
import axiosMixin from "../mixins/axiosMixin";
import MapImage from "../components/MapImage.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
    name: "JoinGame",
    components: {
        MapImage,
    },
    mixins: [axiosMixin],
    data() {
        return {
            gameId: null,
            mapName: null,
            creator: null,
            backgroundImg: null,
        };
    },
    methods: {
        ...mapActions([
            "setGameInstance",
            "setScoreDialogVis",
        ]),
        ...mapMutations([
            "commitCenterZoom"
        ]),
        returnHome() {
            this.$route.push({ name: "Home" });
        },
        onStart() {
            axiosMixin.methods
                .makeRequest(
                    "get",
                    process.env.VUE_APP_SERVER_URL + "map/byGameId",
                    { gameId: this.gameId }
                )
                .then((resp) => {
                    if (resp.status == 200) {
                        const mapResp = resp.data;
                        this.commitCenterZoom({
                            center: mapResp.center,
                            zoom: mapResp.zoom,
                        });
                        this.start();
                    }
                });
        },
        start() {
            axiosMixin.methods
                .makeRequest(
                    "get",
                    process.env.VUE_APP_SERVER_URL + "game/joinGame",
                    {
                        gameId: this.gameId,
                    }
                )
                .then((resp) => {
                    if (resp.status != 200) {
                        return;
                    }
                    this.setGameInstance(resp.data);
                    this.setScoreDialogVis(false);
                    this.$router.push({
                        name: "Game",
                    });
                });
        }
    },
    created() {
        this.gameId = this.$route.query.gameId;
        if (!this.gameId) {
            this.returnHome();
        }

        axiosMixin.methods
            .makeRequest(
                "get",
                process.env.VUE_APP_SERVER_URL + "game/inviteInfo",
                {
                    gameId: this.gameId,
                }
            )
            .then((resp) => {
                this.creator = resp.data.creator;

                this.mapName = resp.data.mapName;
                this.backgroundImg = resp.data.imgUrl;
            });
    },
};
</script>

<style scoped>
.background {
    width: 100%;

    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.invite-card {
    width: 400px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

.creator-container {
    margin-top: 15px;
}

.mapname-container {
    margin-top: 15px;
    margin-bottom: 15px;
}

.btn-div {
    margin-top: 15px;
    margin-bottom: 15px;
}

.md-app-content {
    padding: 0;
    position: relative;
}

.center-span {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    padding-top: 10px;
    padding-bottom: 10px;
}
</style>
