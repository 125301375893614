<template>
    <div id="login-container">
        <div class="background">
        </div>
        
        <div class="login-display">
            <img
                class="logo"
                src="img/logoLarge.webp"
                alt="WhereRU logo"
            />
            <md-card class="login-card md-elevation-24">
                <md-card-header style="padding-bottom: 5px">
                    <div class="md-title">Login using google</div>
                    <div class="flex-center">
                        <span class="md-caption" style="max-width: 75%"
                            >Using a cookie blocker might cause the login to
                            not work</span
                        >
                    </div>
                </md-card-header>

                <transition name="slide" mode="out-in">
                    <md-card-actions v-if="showSignIn" key="1">
                        <div class="center-span">
                            <md-button
                                @click="loginClick"
                                class="g-signin-button"
                            >
                                <img
                                    src="glogo.png"
                                    height="20"
                                    width="24.5"
                                    alt="Google logo"
                                />
                                <span> Sign in with Google </span>
                            </md-button>
                            <router-link to="/multiplayer">
                                <md-button
                                    class="md-accent md-raised"
                                    style="margin-top: 10px; width: 100%"
                                >
                                    Join Game
                                </md-button>
                            </router-link>
                        </div>
                    </md-card-actions>
                    <md-card-actions v-if="!showSignIn" key="2">
                        <div class="center-span">
                            <md-field :class="getUsernameClass">
                                <label>Username</label>
                                <md-input
                                    v-model="userName"
                                    type="text"
                                    :md-counter="userNameValidation.max"
                                /><br />
                                <span class="md-error">{{
                                    userNameError
                                }}</span>
                            </md-field>
                            <md-button
                                @click="setUserName"
                                class="md-primary md-raised"
                                >Confirm</md-button
                            >
                        </div>
                    </md-card-actions>
                </transition>
            </md-card>
            <span class="md-headline app-name">WhereRU</span>

            <div id="about-div" @click="toAbout">
                <md-icon>info</md-icon>
                <span class="md-headline">About</span>
            </div>

            <!-- Popup informing the user that they where denied login -->
            <md-snackbar
                md-position="center"
                :md-duration="isInfinity ? Infinity : 5000"
                :md-active.sync="showDeniedSnackbar"
                md-persistent
            >
                <span>{{ snackbarMessage }}</span>
                <md-button
                    class="md-accent"
                    @click="openWhitelistRequest"
                    v-if="requestWhitelist"
                    >Request Whitelist</md-button
                >
                <!-- Your email is not part of the BETA program! -->
            </md-snackbar>
        </div>
        
        <div class="overlay" />

        <md-dialog-prompt
            class="whitelist-req-dialog"
            :md-active.sync="showWhitelistRequest"
            v-model="whitelistComment"
            :md-title="`Send whitelist request`"
            md-input-maxlength="60"
            :md-content="`Name: ${profile ? profile.fullName : ''}<br>Email: ${
                profile ? profile.email : ''
            }`"
            md-input-placeholder="(Optional) Add a comment for the admin."
            md-confirm-text="Send"
            @md-confirm="sendWhitelistRequest()"
        />
    </div>
</template>

<script>
import axiosMixin from "../mixins/axiosMixin";
import utilMixin from "../mixins/utilMixin";
import { mapActions } from "vuex";

export default {
    name: "Login",
    mixins: [axiosMixin, utilMixin],
    data() {
        return {
            userName: "",
            userNameError: "",
            showSignIn: true,
            showDeniedSnackbar: false,
            snackbarMessage: "ERROR: Could not login",
            isInfinity: false,
            requestWhitelist: false,
            nextUrl: null,
            profile: null,
            whitelistComment: "",
            showWhitelistRequest: false,
        };
    },
    computed: {
        getUsernameClass() {
            return {
                "md-invalid": this.userNameError,
            };
        },
    },
    watch: {
        userName: function (newVal) {
            const [valid, text] = this.validateUsername(newVal);
            if (!valid) {
                this.userNameError = text;
            } else {
                this.userNameError = "";
            }
        },
    },
    methods: {
        ...mapActions(["setProfile"]),

        loginClick() {
            this.$gAuth
                .signIn()
                .then((res) => {
                    this.onSignInSuccess(res);
                })
                .catch((error) => this.onSignInError(error));
        },
        onSignInSuccess(googleUser) {
            // See https://developers.google.com/identity/sign-in/web/reference#users
            var id_token = googleUser.getAuthResponse().id_token;
            const prof = googleUser.getBasicProfile();
            this.profile = {
                email: prof.getEmail(),
                familyName: prof.getFamilyName(),
                givenName: prof.getGivenName(),
                id: prof.getId(),
                img: prof.getImageUrl(),
                fullName: prof.getName(),
            };

            //Send to database
            this.makeRequest(
                "post",
                process.env.VUE_APP_SERVER_URL + "user/login",
                null,
                { idToken: id_token },
                false
            ).then((resp) => {
                if (resp && (resp.status === 200 || resp.status === 201)) {
                    // Store the token
                    localStorage.setItem("token", resp.data);

                    // Load appropriate page
                    if (resp.status === 200) {
                        // Updating the topbar with the profile info is done in the router
                        if (this.nextUrl) {
                            this.$router.push({ path: this.nextUrl });
                        } else {
                            this.$router.push({ name: "Home" });
                        }
                    } else {
                        //User not registered. Show username input prompt
                        this.showSignIn = false;
                    }
                } else {
                    if (resp && resp.status === 402) {
                        this.showSnackBarMessage(
                            "You are not on the whitelist",
                            true,
                            true
                        );
                    } else if (resp && resp.status === 403) {
                        this.showSnackBarMessage(
                            "ERROR: Account has been blocked"
                        );
                    } else {
                        this.showSnackBarMessage("ERROR: Could not login");
                    }
                    this.showDeniedSnackbar = true;
                }
            });
        },
        showSnackBarMessage(message, inf = false, isRequestWhitelist = false) {
            this.isInfinity = inf;
            this.requestWhitelist = isRequestWhitelist;
            this.snackbarMessage = message;
            this.showDeniedSnackbar = true;
        },
        onSignInError(error) {
            // `error` contains any error occurred.
            console.log("Login error", error);
        },
        setUserName() {
            const [valid, text] = this.validateUsername(this.userName);
            if (!valid) {
                this.userNameError = text;
            } else {
                this.userNameError = "";
                this.userName = text;
                this.makeRequest(
                    "put",
                    process.env.VUE_APP_SERVER_URL + "user/changeUsername",
                    { userName: this.userName }
                ).then((resp) => {
                    if (resp.status == 200) {
                        this.setProfile();
                        if (this.nextUrl) {
                            this.$router.push({ path: this.nextUrl });
                        } else {
                            this.$router.push({ name: "Home" });
                        }
                    }
                });
            }
        },
        openWhitelistRequest() {
            this.showWhitelistRequest = true;
            this.showDeniedSnackbar = false;
        },
        sendWhitelistRequest() {
            this.makeRequest(
                "put",
                process.env.VUE_APP_SERVER_URL + "user/requestWhitelist",
                null,
                { profile: this.profile, comment: this.whitelistComment },
                false
            ).then((resp) => {
                if (!resp) {
                    return;
                }
                if (resp.status === 409) {
                    this.showSnackBarMessage("ERROR: Request already pending");
                } else if (resp.status == 200) {
                    this.showSnackBarMessage("Sent request");
                }
            });
        },
        toAbout() {
            this.$router.push({ name: "About" });
        },
    },
    created() {
        if (this.$route.query.nextUrl) {
            const next = this.$route.query.nextUrl;
            if (next !== "/" && !next.startsWith("/login")) {
                this.nextUrl = next;
            }
        }
    },
};
</script>

<style scoped>
.background {
    background-image: url("../../public/img/nasa-globe-center.webp");
    background-repeat: no-repeat;
    background-size: cover;
    height: 110%;
    width: 110%;
    position: absolute;
    top: -5%;
    left: -5%;

    filter: blur(4px);
}

.login-display {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

#login-container {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: relative;
}

.center-span {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
}

.login-card {
    width: 25%;
    padding-bottom: 25px;
    max-width: 350px;
    min-width: 350px;
    overflow: hidden;
}

.logo {
    height: 20%;
    margin-bottom: 50px;
}
.app-name {
    margin-top: 100px;
    color: white;
    font-size: 50px;
    margin-bottom: 10px;
}

@media only screen and (max-height: 700px) {
    .app-name {
        margin-top: 4vh;
    }

    .logo {
        height: 20%;
        margin-bottom: 4vh;
    }
}

.g-signin-button {
    display: inline-block;
    padding: 8px 12px;
    border-radius: 3px;
    background-color: #44546a;
    box-shadow: 0 3px 0 #3e4d61;
    position: relative;
}

.g-signin-button:hover {
    background-color: #3e4d61;
}

.g-signin-button img {
    height: 20px;
    padding-right: 5px;
}

.g-signin-button span {
    position: relative;
    top: 3px;
    color: #fff;
}

.slide-leave-active,
.slide-enter-active {
    transition: 0.5s;
}
.slide-enter {
    transform: translate(100%, 0);
}
.slide-leave-to {
    transform: translate(-100%, 0);
}

.whitelist-req-dialog >>> .md-dialog-container {
    width: 400px;
}

#about-div {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-content: center;
    justify-content: flex-start;
    user-select: none;
    margin: 2px;
    cursor: pointer;
}

#about-div i {
    color: white;
}

#about-div span {
    color: white;
    margin-left: 5px;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.1);
}
</style>