<template>
    <div class="floating-map" v-bind:class="{ 'large-map': isLarge }">
        <div id="map"></div>
        <div style="display: none">
            <div
                class="maptype-control"
                v-bind:class="{ 'maptype-control-is-sat': isSat }"
            >
                <md-button
                    id="maptype-control-btn"
                    title="Toggle satellite/map"
                    v-on:click="toggleIsSat"
                    class="md-fab md-mini md-primary"
                >
                    <md-icon v-if="isSat"> map </md-icon>
                    <md-icon v-else> satellite </md-icon>
                </md-button>
            </div>
            <div class="controls size-control">
                <md-button
                    id="size-control-btn"
                    title="Toggle map size"
                    v-on:click="toggleSize"
                    class="md-fab md-mini md-primary"
                >
                    <md-icon v-if="isLarge"> unfold_less </md-icon>
                    <md-icon v-else> unfold_more </md-icon>
                </md-button>
            </div>
        </div>
    </div>
</template>

<script>
import axiosMixin from "../mixins/axiosMixin";

import { mapActions, mapGetters } from "vuex";

export default {
    name: "FloatingMap",
    mixins: [axiosMixin],
    data() {
        return {
            isSat: false,
            map: null,
            isLarge: false,
            center: { lat: 0, lng: 0 },
            zoom: 0,
            marker: null,
            locationMarkers: [],
        };
    },
    props: {
        canPlaceMarker: Boolean,
        settings: Object,
        panorama: Object,
        bounds: Object,
        locations: Array,
        defaultCenterAndZoom: Boolean,
    },
    computed: mapGetters(["getMapId", "guessMarkerIsPlaced"]),
    watch: {
        panorama: function (value) {
            if (value && this.map) {
                this.setStreetView(value);
            }
        },
        bounds: function (value) {
            if (value) {
                this.map.fitBounds(value);
            }
        },
        locations: function () {
            // Place markers if they exists
            this.loadGoogle().then(() => {
                if (this.locations) {
                    this.locationMarkers.forEach((l) => l.setMap(null));
                    this.locationMarkers = [];
                    for (const i in this.locations) {
                        const newMarker = new window.google.maps.Marker({
                            position: this.locations[i],
                            map: this.map,
                            title: this.locations[i].description,
                        });
                        newMarker.addListener("click", () => {
                            this.map.setZoom(10);
                            this.map.setCenter(newMarker.getPosition());
                            this.$emit("clickedMarker", i);
                        });
                        this.locationMarkers.push(newMarker);
                    }
                }
            });
        },
    },
    methods: {
        ...mapActions([
            "placeGuessMarker",
            "clearGuessMarker",
            "loadGoogle",
        ]),
        toggleIsSat() {
            this.isSat = !this.isSat;
            this.setIsSat(this.isSat);
        },

        setIsSat(sat) {
            this.isSat = sat;
            this.map.setMapTypeId(sat ? "hybrid" : "roadmap");
        },

        setStreetView(pano) {
            this.map.setStreetView(pano);
        },

        placeMarker(position) {
            const google = window.google;
            if (this.marker) {
                this.marker.setMap(this.map);
                this.marker.setPosition(position);
            } else {
                this.marker = new google.maps.Marker({
                    position,
                    map: this.map,
                    icon: "/img/logo.webp",
                });
            }

            this.placeGuessMarker(position);
        },

        toggleSize() {
            this.isLarge = !this.isLarge;
        },

        getMap() {
            const google = window.google;
            this.map = new google.maps.Map(
                document.getElementById("map"),
                this.settings
            );

            if (this.panorama) {
                this.setStreetView(this.panorama);
            }

            // Get the default center and zoom of the map
            // TODO can this axios call be removed?
            if (!this.defaultCenterAndZoom) {
                axiosMixin.methods
                    .makeRequest(
                        "get",
                        process.env.VUE_APP_SERVER_URL + "map/byId",
                        { mapId: this.getMapId }
                    )
                    .then((resp) => {
                        const mapResp = resp.data;
                        this.center = mapResp.center;
                        this.zoom = mapResp.zoom;

                        this.map.setCenter(this.center);
                        this.map.setZoom(this.zoom);
                    });
            } else {
                this.center = this.settings.center;
                this.zoom = this.settings.zoom;

                this.map.setCenter(this.center);
                this.map.setZoom(this.zoom);
            }

            // Set up satellite and size buttons
            const mapTypeDiv = document.querySelector(".maptype-control");
            this.map.controls[google.maps.ControlPosition.RIGHT_TOP].push(
                mapTypeDiv
            );

            const sizeDiv = document.querySelector(".size-control");
            this.map.controls[google.maps.ControlPosition.LEFT_TOP].push(
                sizeDiv
            );

            // Setup adding markers by clicking
            if (this.canPlaceMarker) {
                const _this = this;
                google.maps.event.addListener(
                    this.map,
                    "click",
                    function (event) {
                        _this.placeMarker(event.latLng);
                    }
                );
            }
        },
    },
    created() {
        this.loadGoogle().then(() => {
            this.getMap();

            // Emit event if map bounds change Bias the SearchBox results towards current map's viewport.
            this.map.addListener("bounds-changed", () => {
                this.$emit("bounds_changed", this.map.getBounds());
            });
        });
    },
};
</script>


<!------------------Styling---------------->
<style scoped>
#map {
    background: #42b983;
    min-height: max(200px, 25vh);
    min-width: min(500px, 25vw);
    height: 100%;
}

.large-map {
    height: 80vh;
    width: 60vw;
}
</style>