<template>
    <div id="canvas" style="position: relative">
        <div id="correct-guess-dialog" v-if="displayCorrectGuess && getCorrectRegion">
            <div>
                <img :src="getFlagUrl(getCorrectRegion.code)" />
                <span class="md-title">Correct guess</span>
            </div>
        </div>
        <md-button
            v-if="
                !(
                    roomState === State.NONE ||
                    roomState === State.IN_GAME ||
                    (roomState === State.END && hosting)
                )
            "
            class="overlay md-raised md-icon-button"
            style="
                top: 3px;
                left: 0px;
                color: white;
                background: rgb(206, 58, 58);
            "
            @click="showConfirmLeave = true"
        >
            <md-icon>exit_to_app</md-icon>
        </md-button>

        <!-- Before having joined a lobby -->
        <div v-if="roomState === State.NONE">
            <PrePartyGame
                :waitingForCreation="waitingForCreation"
                :refresh="refreshPreGame"
                @joinRoom="joinRoom"
                ref="prePartyGame"
            />
        </div>

        <!-- LOBBY and INTERMISSION -->

        <template
            v-else-if="
                info.roles.includes(Role.SCORE_ACCESS) &&
                roomState === State.LOBBY
            "
        >
            <HostLobby @start="startGame" />
        </template>

        <template
            v-else-if="
                info.roles.includes(Role.SCORE_ACCESS) &&
                roomState === State.INTERMISSION
            "
        >
            <HostIntermission @start="nextRound" @cancel="onCancel" />
        </template>

        <template
            v-else-if="
                !info.roles.includes(Role.HOST) &&
                (roomState === State.LOBBY || roomState === State.INTERMISSION)
            "
        >
            <PlayerLobby :intermission="roomState === State.INTERMISSION" />
        </template>

        <!-- IN GAME -->

        <template v-else-if="roomState === State.IN_GAME">
            <template
                v-if="
                    info.roles.includes(Role.PANORAMA_ACCESS) &&
                    info.roles.includes(Role.MAP_ACCESS)
                "
            >
                <HostInGame>
                    <PnMInGame />
                </HostInGame>
            </template>
            <template v-else-if="info.roles.includes(Role.PANORAMA_ACCESS)">
                <HostInGame />
            </template>
            <template v-else>
                <PlayerInGame />
            </template>
        </template>

        <!-- DISPLAY ANSWERS and END -->

        <template v-else-if="roomState == State.DISPLAYING_ANSWERS">
            <template v-if="info.roles.includes(Role.SCORE_ACCESS)">
                <HostDisplayAnswers
                    :isTotal="false"
                    :isEnd="false"
                    @nextRound="nextRound"
                />
            </template>
            <template v-else>
                <PlayerDisplayAnswers
                    style="display: flex; flex-direction: column"
                    :isEnd="false"
                />
            </template>
        </template>

        <template v-else-if="roomState === State.END">
            <template v-if="info.roles.includes(Role.SCORE_ACCESS)">
                <HostDisplayAnswers
                    :isTotal="true"
                    :isEnd="true"
                    @continue="closeGame"
                />
            </template>
            <template v-else>
                <PlayerDisplayAnswers
                    style="display: flex; flex-direction: column"
                    :isEnd="true"
                />
            </template>
        </template>

        <!-- POST LOBBY -->

        <template v-else-if="roomState === State.POST_LOBBY">
            <template v-if="info.roles.includes(Role.HOST)">
                <HostPostLobby @endGame="closeRoom" @rehost="rehost" />
            </template>
            <template v-else>
                <span class="md-display-2" style="margin-top: 20px"
                    >Waiting for host</span
                >
            </template>
        </template>

        <span v-else> Error state </span>

        <md-snackbar
            :md-position="'center'"
            :md-duration="4000"
            :md-active.sync="snackbarVisible"
            md-persistent
        >
            <span style="color: yellow">{{ message }}</span>
            <md-button style="color: white" @click="snackbarVisible = false"
                >Close</md-button
            >
        </md-snackbar>

        <md-dialog-confirm
            :md-active.sync="showConfirmLeave"
            :md-title="hosting ? 'Close Room?' : 'Leave?'"
            :md-content="
                hosting
                    ? 'Do you want to close the room for everyone (room is deleted)'
                    : `Do you want to leave the room? You can ${
                          roomState === State.LOBBY ? '' : 'NOT'
                      } rejoin.`
            "
            md-confirm-text="Yes"
            md-cancel-text="No"
            @md-confirm="leaveOrCloseRoom"
        />

        <md-dialog
            :md-active.sync="showDisconnected"
            :md-close-on-esc="false"
            :md-click-outside-to-close="false"
        >
            <md-dialog-title>You are disconnected!</md-dialog-title>
            <md-dialog-content>
                You are disconnected. You can try waiting a few seconds or
                refreshing the page.
            </md-dialog-content>

            <md-dialog-actions>
                <md-button @click="goHome"> Return Home </md-button>
                <md-button class="md-raised md-primary" @click="refresh">
                    Refresh
                </md-button>
            </md-dialog-actions>
        </md-dialog>
    </div>
</template>

<script>
import axiosMixin from "../mixins/axiosMixin";
import utilMixin from "../mixins/utilMixin";
import PlayerDisplayAnswers from "./Party/PlayerDisplayAnswers";
import PrePartyGame from "./Party/PrePartyGame";
import PlayerInGame from "./Party/PlayerInGame";
import HostLobby from "./Party/HostLobby";
import HostInGame from "./Party/HostInGame";
import HostDisplayAnswers from "./Party/HostDisplayAnswers";
import HostIntermission from "./Party/HostIntermission";
import PlayerLobby from "./Party/PlayerLobby";
import HostPostLobby from "./Party/HostPostLobby";
import PnMInGame from "./Party/PnMInGame.vue";
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";

export default {
    name: "Multiplayer",
    mixins: [axiosMixin, utilMixin],
    components: {
        PrePartyGame,
        HostLobby,
        HostInGame,
        HostDisplayAnswers,
        HostIntermission,
        HostPostLobby,
        PlayerInGame,
        PlayerDisplayAnswers,
        PlayerLobby,
        PnMInGame,
    },
    data() {
        return {
            waitingForCreation: false,

            showConfirmLeave: false,
            showDisconnected: false,

            snackbarVisible: false,

            justDisconnected: false,

            refreshPreGame: false,

            displayCorrectGuess: false,
        };
    },
    props: {
        settings: Object,
    },

    watch: {
        showSnackbar: function () {
            if (this.message && this.message.length > 0) {
                this.snackbarVisible = true;
            }
        },
        connected: function () {
            this.checkDisconnected();
        },
        displayCorrectGuess: function () {
            if (this.displayCorrectGuess) {
                setTimeout(() => {
                    this.displayCorrectGuess = false;
                }, 2000);
            }
        }
    },

    computed: {
        ...mapState("partygame", [
            "socket",
            "roomState",
            "username",
            "roomCode",
            "playerId",
            "info",
            "hosting",
            "showSnackbar",
            "message",
            "location",
            "playerMap",
            "teamList",
            "team",
        ]),
        ...mapGetters("partygame", ["partyGameType", "getCorrectRegion"]),

        connected() {
            return !!this.socket && this.socket.connected;
        },
    },

    methods: {
        ...mapActions("partygame", [
            "connectSocket",
            "disconnectSocket",
            "setUsername",
            "setPlayerId",
            "setPublicPlayerId",
            "setRoomCode",
            "sendEvent",
            "incRound",
            "setPlayerList",
            "addPlayer",
            "removePlayer",
            "setGuesserResponse",
        ]),

        ...mapMutations("partygame", [
            "setRoomState",
            "showNewMessage",
            "setInfo",
            "setMapResponse",
            "setHosting",
            "setEndTime",
            "setStartTime",
            "setScoreResponse",
            "setToAnswer",
            "setCenter",
            "setTeamList",
            "resetData",
            "setTeammateAnswers",
            "setTeam",
            "setPlayerImg",
            "commitTeam",
            "deleteTeam",
            "setLocation",
            "setRoundEndTime",
            "setDamageMultiplier",
            "setCurrentRegionCode",
            "setCorrectRegionCode",
            "addIncorrectRegionCode",
            "incGuessesLeft",
        ]),

        createRoom(settings) {
            const newGameRequest = {
                ...settings,
                authHeader: "Bearer " + localStorage.getItem("token"),
            };
            this.socket.emit("create", newGameRequest);
        },

        checkDisconnected() {
            if (!this.connected && !this.showDisconnected) {
                const _this = this;
                setTimeout(function () {
                    if (!_this.connected) {
                        _this.showDisconnected = true;
                    }
                }, 2000);
            } else {
                this.showDisconnected = false;
            }
        },

        sendDisconnect() {
            this.socket.disconnect();
        },

        refresh() {
            window.location.reload(true);
        },
        goHome() {
            this.$router.push({
                name: "Home",
            });
        },

        startGame() {
            this.socket.emit("startGame", {
                roomCode: this.roomCode,
                authHeader: "Bearer " + localStorage.getItem("token"),
            });
        },
        nextRound() {
            this.socket.emit("nextRound", {
                roomCode: this.roomCode,
                authHeader: "Bearer " + localStorage.getItem("token"),
            });
        },
        onCancel() {
            this.socket.emit("cancelIntermission", {
                roomCode: this.roomCode,
                authHeader: "Bearer " + localStorage.getItem("token"),
            });
        },

        joinRoom() {
            this.resetData();
            this.socket.emit("join", {
                roomCode: this.roomCode,
                authHeader: "Bearer " + localStorage.getItem("token"),
                username: this.username,
                playerId: this.playerId,
            });
        },

        leaveOrCloseRoom() {
            if (this.hosting) {
                this.closeRoom();
            } else {
                this.leaveRoom();
            }
            this.resetData();
            // TODO (?) check first that room has been deleted / execute after close response
            this.refreshPreGame = !this.refreshPreGame;
        },
        leaveRoom() {
            this.socket.emit("leave", this.playerId);
        },
        closeRoom() {
            this.socket.emit("close", {
                roomCode: this.roomCode,
                authHeader: "Bearer " + localStorage.getItem("token"),
            });
        },
        closeGame() {
            this.socket.emit("gameOver", {
                roomCode: this.roomCode,
                authHeader: "Bearer " + localStorage.getItem("token"),
            });
        },
        rehost() {
            this.socket.emit("rehost", {
                roomCode: this.roomCode,
                authHeader: "Bearer " + localStorage.getItem("token"),
            });
        },

        displayAnswers(payload) {
            this.setScoreResponse(payload.score);
            this.setRoomState(this.State.DISPLAYING_ANSWERS);
            if (payload.guesser) {
                this.setGuesserResponse(payload.guesser);
            }
        },

        handleNextRound(payload) {
            this.setEndTime(payload.common.endTime);
            this.setStartTime(payload.common.startTime);
            this.setDamageMultiplier(payload.common.damageMultiplier);

            if (payload.map) {
                this.setMapResponse(payload.map);
            }

            if (payload.panorama) {
                this.setLocation(payload.panorama.location);
            }
            if (payload.guesser) {
                this.setGuesserResponse(payload.guesser);
            }

            this.setRoomState(this.State.IN_GAME);

            if (this.$refs.interactiveMap) {
                // TODO? move
                this.$refs.interactiveMap.clear();
            }
        },

        handleEnd(payload) {
            this.setScoreResponse(payload.score);
            this.setRoomState(this.State.END);
        },

        joined(data) {
            const { info, common, panorama, map, score, guesser } = data;
            this.setInfo(info);

            const roomCode = info.roomId;
            this.setRoomCode(roomCode);
            this.setHosting(info.host);
            this.setRoomState(this.getRoomState(info.state));
            this.setTeamList(info.teams);
            this.setPlayerList(info.players);

            this.setPlayerId(common.playerId);
            this.setPublicPlayerId(common.publicPlayerId);
            this.setEndTime(common.endTime);
            this.setStartTime(common.startTime);
            this.setDamageMultiplier(common.damageMultiplier);

            if (guesser) {
                this.setTeam(guesser.team);
                this.setPlayerImg(guesser.img);
                this.setGuesserResponse(guesser);
            }

            if (panorama) {
                this.setLocation(panorama.location);
            }

            if (map) {
                this.setMapResponse(map);
                if (map.answers.length > 0) {
                    this.setCurrentRegionCode(
                        map.answers[map.answers.length - 1].regionCode
                    );
                }
            }

            if (score) {
                this.setScoreResponse(score);
            }
        },

        getRoomState(stateString) {
            switch (stateString) {
                case "LOBBY":
                    return this.State.LOBBY;
                case "INTERMISSION":
                    return this.State.INTERMISSION;
                case "WAITING_FOR_ANSWERS":
                    return this.State.IN_GAME;
                case "DISPLAYING_ANSWERS":
                    return this.State.DISPLAYING_ANSWERS;
                case "END_SCREEN":
                    return this.State.END;
                case "POST_LOBBY":
                    return this.State.POST_LOBBY;
                default:
                    return null;
            }
        },
    },

    created() {
        this.State = {
            NONE: -1,
            LOBBY: 0,
            IN_GAME: 1,
            DISPLAYING_ANSWERS: 2,
            END: 3,
            INTERMISSION: 4,
            POST_LOBBY: 5,
        };

        this.Role = {
            SCORE_ACCESS: "SCORE_ACCESS",
            MAP_ACCESS: "MAP_ACCESS",
            GUESSER: "GUESSER",
            PANORAMA_ACCESS: "PANORAMA_ACCESS",
            HOST: "HOST",
        };

        this.setRoomState(this.State.NONE);

        if (this.settings) {
            this.waitingForCreation = true;
        }

        this.connectSocket();
        if (this.roomState != this.State.NONE) {
            this.joinRoom();
        }

        this.socket.on("connect", () => {
            if (this.settings && !this.justDisconnected) {
                this.justDisconnected = false;
                this.createRoom(this.settings);
            }
        });
        this.socket.on("message", (msg) => {
            this.showNewMessage(msg);
            console.log("Received message:", msg);
        });
        this.socket.on("disconnect", function () {
            this.justDisconnected = true;
        });
        this.socket.on("reconnect", () => {
            if (this.roomState != this.State.NONE) {
                this.joinRoom();
            }
        });
        this.socket.on("starting", () => {
            this.setRoomState(this.State.INTERMISSION);
        });
        this.socket.on("startingHost", () => {
            this.setRoomState(this.State.INTERMISSION);
        });

        this.socket.on("created_team", (team) => {
            this.commitTeam(team);
        });
        this.socket.on("deleted_team", (teamId) => {
            this.deleteTeam(teamId);
            if (this.team && this.team.teamId == teamId) {
                this.setTeam(null);
            }
        });
        this.socket.on("roomClosed", () => {
            this.showNewMessage("Room closed");
            this.setRoomState(this.State.NONE);
            if (this.$refs.prePartyGame) {
                this.$refs.prePartyGame.checkIfHasRoom();
            }
        });

        this.socket.on("joined", (resp) => {
            this.joined(resp);
        });

        this.socket.on("nextRound", (payload) => {
            this.incRound();
            this.handleNextRound(payload);
            this.setCurrentRegionCode({});
        });

        this.socket.on("intermissionCanceled", () => {
            this.setRoomState(this.State.LOBBY);
        });

        this.socket.on("postLobby", () => {
            this.setRoomState(this.State.POST_LOBBY);
        });

        this.socket.on("lobby_rehosted", () => {
            this.setRoomState(this.State.LOBBY);
            this.setInfo({
                ...this.info,
                round: 0,
            });
        });

        this.socket.on("received_answer", (answer) => {
            this.setToAnswer(true);
        });

        this.socket.on("failed_to_answer", () => {
            this.incGuessesLeft();
        });

        this.socket.on("answer_region_code", (regionCode) => {
            this.setCurrentRegionCode(regionCode);
        });
        this.socket.on("correct_region_code", (regionCode) => {
            this.setCorrectRegionCode(regionCode);
            this.displayCorrectGuess = true;
        });
        this.socket.on("incorrect_region_code", (regionCode) => {
            this.addIncorrectRegionCode(regionCode);
        });

        this.socket.on("battle_royal_player_answered", (payload) => {
            this.setMapResponse(payload.map);
            this.setGuesserResponse(payload.guesser);
        });

        this.socket.on("roundEnded", (payload) => {
            this.setGuesserResponse(payload.guesser);
            this.setRoomState(this.State.DISPLAYING_ANSWERS);
        });

        this.socket.on("gameOver", (payload) => {
            this.setGuesserResponse(payload.guesser);
            this.setRoomState(this.State.END);
        });

        this.socket.on("kicked", () => {
            this.showNewMessage("You were kicked");
            this.setRoomState(this.State.NONE);
        });

        this.socket.on("timeToEnd", (payload) => {
            this.setEndTime(payload);
        });

        this.socket.on("settingsUpdated", (info) => {
            this.setInfo(info);
        });

        this.socket.on("showAnswers", (payload) => {
            this.displayAnswers(payload);
        });

        this.socket.on("endScreen", (payload) => {
            this.handleEnd(payload);
        });

        this.socket.on("created", (joinResponse) => {
            this.waitingForCreation = false;
            this.joined(joinResponse);
        });

        this.socket.on("player_joined", (player) => {
            this.addPlayer(player);
        });
        this.socket.on("player_left", (player) => {
            this.removePlayer(player);
        });

        this.checkDisconnected();
    },
    beforeDestroy() {
        this.disconnectSocket();
    },
};
</script>

<style lang="scss" scoped>
::v-deep .overlay {
    position: absolute;
    z-index: 500;
    color: white;
}

::v-deep .top {
    top: 5px;
}

::v-deep .center {
    left: 50%;
    transform: translate(-50%, 0);
}

::v-deep .left {
    left: 10px;
}

::v-deep .right {
    right: 10px;
}

::v-deep p {
    color: black;
}

::v-deep .md-field {
    width: 50%;
    margin: 10px auto;
}

#canvas {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

::v-deep .flex {
    display: flex;
}
::v-deep .flex-grow {
    flex-grow: 1;
}

#correct-guess-dialog {
    position: absolute;
    top: 200px;
    width: 100%;
    z-index: 1000;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
}

#correct-guess-dialog div {
    width: 200px;
    height: 100%;
    background: green;
    opacity: 0.9;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5%;
    color: white;
}

#correct-guess-dialog img {
    height: 85px;
}

</style>

<style>
#countdown {
    flex: 1;
    background: rgba(92, 92, 92, 0.411);
    padding: 10px;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    max-width: 70vw;
    top: 5px;
    left: 0;
    right: 0;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 20vh;
    max-height: 100px;
}
#countdown span {
    margin-left: 5px;
}
</style>
