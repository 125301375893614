var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"outer",style:({'--end-pos-damage-left':_vm.endPosDamageLeft, '--end-pos-damage-right':_vm.endPosDamageRight})},[_c('div',{staticClass:"flex-center"},[_c('span',{staticClass:"md-display-2",staticStyle:{"margin":"10px 0"}},[_vm._v("Round "+_vm._s(_vm.round))])]),_c('div',{staticStyle:{"min-width":"350px"}},[_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-size-50 flex-center",style:(`background:${_vm.team1.color}`)},[_c('span',{staticClass:"md-headline"},[_vm._v(_vm._s(_vm.team1.teamName))])]),_c('div',{staticClass:"md-layout-item md-size-50 flex-center",style:(`background:${_vm.team2.color}`)},[_c('span',{staticClass:"md-headline"},[_vm._v(_vm._s(_vm.team2.teamName))])])]),_c('div',{staticClass:"flex-center",staticStyle:{"margin-top":"10px","position":"relative","height":"10px"}},[_c('span',{staticClass:"md-headline",class:{ big: _vm.multiplierBig },attrs:{"id":"multiplier"}},[_vm._v("Multiplier x"+_vm._s(_vm.multiplier))])]),_c('div',{staticClass:"flex-center",staticStyle:{"height":"60px","margin-top":"25px","position":"relative"}},[_c('div',{ref:"leftScore",staticClass:"score",class:{ animate: _vm.animating },style:({ transform: `translateX(${_vm.scoreLeftStartPos}px)` })},[_vm._v(" "+_vm._s(_vm.leftScore)+" ")]),_c('div',{staticClass:"score",class:{ hidden: _vm.showDifference, animate: _vm.animating },style:({ transform: `translateX(${_vm.scoreLeftPos}px)` })},[_vm._v(" "+_vm._s(_vm.leftScore)+" ")]),_c('div',{ref:"rightScore",staticClass:"score",class:{ animate: _vm.animating },style:({
                    transform: `translateX(${_vm.scoreRightStartPos}px)`,
                })},[_vm._v(" "+_vm._s(_vm.rightScore)+" ")]),_c('div',{staticClass:"score",class:{ hidden: _vm.showDifference, animate: _vm.animating },style:({ transform: `translateX(${_vm.scoreRightPos}px)` })},[_vm._v(" "+_vm._s(_vm.rightScore)+" ")]),(_vm.showDifference)?_c('div',{staticClass:"difference",staticStyle:{"position":"relative"}},[_c('span',{staticClass:"md-display-1 mult-text old-diff",class:{ new: _vm.multiplierApplied }},[_vm._v(" "+_vm._s(_vm.difference)+" ")]),_c('span',{staticClass:"md-display-1 mult-text mult-diff",class:{ new: _vm.multiplierApplied }},[_vm._v(" "+_vm._s(_vm.differenceMult)+" ")]),(_vm.showFlyingMult)?_c('span',{staticClass:"md-display-1 mult-text mult-diff",class:{
                        'new': _vm.multiplierApplied,
                        'left': _vm.targetLeft,
                        'right': !_vm.targetLeft,
                    },attrs:{"id":"movable-mult"}},[_vm._v(" -"+_vm._s(_vm.differenceMult)+" ")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"flex-center md-layout",staticStyle:{"position":"relative"}},[_c('div',{staticClass:"md-layout-item md-size-50"},[_c('div',{staticStyle:{"margin":"10px","position":"relative"}},[_c('ProgressBar',{staticStyle:{"height":"50px"},attrs:{"value":_vm.leftHealthProg,"animate":_vm.animating,"invert":false,"color":_vm.team1.color}}),_c('div',{staticClass:"score",class:{ animate: _vm.animating },staticStyle:{"left":"10px","top":"15px"}},[_vm._v(" "+_vm._s(_vm.curLeftHealth > 0 ? _vm.curLeftHealth : "OUT!")+" ")])],1)]),_c('div',{staticClass:"md-layout-item md-size-50"},[_c('div',{staticStyle:{"margin":"10px","position":"relative"}},[_c('ProgressBar',{staticStyle:{"height":"50px"},attrs:{"value":_vm.rightHealthProg,"animate":_vm.animating,"invert":true,"color":_vm.team2.color}}),_c('div',{staticClass:"score",class:{ animate: _vm.animating },staticStyle:{"right":"10px","top":"15px"}},[_vm._v(" "+_vm._s(_vm.curRightHealth > 0 ? _vm.curRightHealth : "OUT!")+" ")])],1)])]),_c('div',{staticClass:"flex-center",staticStyle:{"height":"34px","margin-bottom":"15px"}},[_c('span',{staticClass:"sf-small",staticStyle:{"font-size":"clamp(20px, 2vw, 30px)"}},[_vm._v(_vm._s(_vm.hitText))])])]),_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('md-button',{staticClass:"md-icon-button",attrs:{"disabled":_vm.animating},on:{"click":_vm.animateScores}},[_c('md-icon',[_vm._v("restart_alt")])],1),_c('md-button',{staticClass:"md-raised md-primary",staticStyle:{"margin-left":"auto"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("Continue")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }