<template>
    <div style="width: 100%">
        <md-tabs class="md-primary non-scroll" md-alignment="centered">
            <template slot="md-tab" slot-scope="{ tab }">
                {{ tab.label }}
                <i class="badge" v-if="tab.data.badge">{{ tab.data.badge }}</i>
            </template>

            <md-tab
                id="tab-pending"
                md-label="Pending"
                class="fol"
                :md-template-data="{ badge: pendingWhitelist }"
            >
                <div>
                    <WhitelistList
                        @toWhitelist="toWhitelist"
                        @toBlacklist="toBlacklist"
                        @message="showMessage"
                        :elements="pendingArray"
                        :isPending="true"
                        :isBlacklist="false"
                    />
                </div>
            </md-tab>
            <md-tab id="tab-whitelist" class="fol" md-label="Whitelist">
                <div>
                    <WhitelistList
                        @toWhitelist="toWhitelist"
                        @toBlacklist="toBlacklist"
                        @message="showMessage"
                        :elements="whitelistArray"
                        :isPending="false"
                        :isBlacklist="false"
                    />
                </div>
            </md-tab>
            <md-tab id="tab-blacklist" class="fol" md-label="Blacklist">
                <div>
                    <WhitelistList
                        @toWhitelist="toWhitelist"
                        @toBlacklist="toBlacklist"
                        @message="showMessage"
                        :elements="blacklistArray"
                        :isPending="false"
                        :isBlacklist="true"
                    />
                </div>
            </md-tab>
        </md-tabs>
        <md-snackbar
            :md-duration="5000"
            :md-active.sync="showSnackbar"
            md-persistent
        >
            <span>{{snackbarMessage}}</span>
        </md-snackbar>
    </div>
</template>

<script>
import axiosMixin from "../mixins/axiosMixin";
import WhitelistList from "../components/Whitelist/WhitelistList.vue";
import { mapActions } from "vuex";

export default {
    components: {
        WhitelistList,
    },
    data() {
        return {
            whitelistArray: [],
            blacklistArray: [],
            pendingArray: [],
            showSnackbar: false,
            snackbarMessage: "",
        };
    },
    computed: {
        pendingWhitelist: function () {
            return this.pendingArray.length;
        },
    },
    mixins: [axiosMixin],

    methods: {
        ...mapActions(["updatePendingWhitelist"]),
        toWhitelist(el) {
            this.blacklistArray = this.blacklistArray.filter(
                (e) => e.userEmail != el.userEmail
            );
            this.pendingArray = this.pendingArray.filter(
                (e) => e.userEmail != el.userEmail
            );
            el.dateChanged = Date.now();
            this.whitelistArray.push(el);
            this.updatePendingWhitelist();
        },
        toBlacklist(el) {
            this.whitelistArray = this.whitelistArray.filter(
                (e) => e.userEmail != el.userEmail
            );
            this.pendingArray = this.pendingArray.filter(
                (e) => e.userEmail != el.userEmail
            );
            el.dateChanged = Date.now();
            this.blacklistArray.push(el);
            this.updatePendingWhitelist();
        },
        showMessage(message) {
            console.log("HI");
            this.showSnackbar = true;
            this.snackbarMessage = message;
        },
    },
    mounted() {
        this.makeRequest(
            "get",
            process.env.VUE_APP_SERVER_URL + "user/getWhitelist"
        ).then((resp) => {
            this.whitelistArray = resp.data.whitelist;
            this.blacklistArray = resp.data.blacklist;
            this.pendingArray = resp.data.pending;
        });
    },
};
</script>

<style scoped>
.non-scroll >>> .md-tabs-container {
    height: auto !important;
}

.fol {
    max-height: calc(100vh - 96px);
    overflow: auto;
    display: flex;
    justify-content: center;
}

.fol div {
    width: 500px;
}

.badge {
    width: 19px;
    height: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 2px;
    right: 2px;
    background: #c42f2f;
    border-radius: 100%;
    color: #fff;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -0.05em;
    font-family: "Roboto Mono", monospace;
}
</style>