<template>
    <div
        @mouseover="hover = true"
        @mouseleave="hover = false"
    >
        <slot></slot>
        <div
            class="
                md-tooltip md-tooltip-bottom md-theme-demo-light
                tooltip
            "
            v-if="hover"
        >
            {{ text }}
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            hover: false,
        }
    },
    props: {
        text: String,
    }
}
</script>

<style scoped>
.right .tooltip {
    right: 0;
}

.left .tooltip {
    left: 0;
} 

.tooltip {
    position: absolute;
    background-color: var(
        --md-theme-demo-light-tooltip-on-background,
        rgba(97, 97, 97, 0.9)
    );
    color: var(--md-theme-demo-light-text-primary-on-tooltip, #fff);
}
</style>