<template>
    <md-list class="md-triple-line" v-if="elements.length > 0">
        <WhitelistElement
            @toWhitelist="$emit('toWhitelist', $event)"
            @toBlacklist="$emit('toBlacklist', $event)"
            @message="$emit('message', $event)"
            v-for="(e, index) in sortedElements"
            :key="index"
            :data="e"
            :isPending="isPending"
            :isBlacklist="isBlacklist"
        />
    </md-list>
    <md-empty-state
        v-else
        class="md-primary"
        :md-icon="isPending ? 'done' : 'attribution'"
        :md-label="isPending ? 'No requests' : 'No entries'"
        :md-description="
            isPending
                ? 'There are currently no pending whitelist requests.'
                : isBlacklist
                ? 'There are no entries in the blacklist.'
                : 'There are no entries in the whitelist.'
        "
    >
    </md-empty-state>
</template>

<script>
import WhitelistElement from "./WhitelistElement.vue";

export default {
    components: {
        WhitelistElement,
    },
    computed: {
        sortedElements: function () {
            const _this = this;
            function compare(a, b) {
                const ad = _this.getDate(a);
                const bd = _this.getDate(b);

                if (ad && !bd) return -1;
                if (!ad && bd) return 1;
                if (ad > bd) return -1;
                if (ad < bd) return 1;

                if (a.userEmail < b.userEmail) return -1;
                if (a.userEmail > b.userEmail) return 1;

                return 0;
            }

            return this.elements.slice().sort(compare);
        },
    },
    props: {
        isPending: Boolean,
        isBlacklist: Boolean,
        elements: Array,
    },
    methods: {
        getDateString(date) {
            // 2012-02-13
            if (date) {
                return new Date(date).toISOString().substring(0, 10);
            }
            return null;
        },
        getDate(e) {
            return this.isPending
                ? this.getDateString(e.dateRequested)
                : this.getDateString(e.dateChanged);
        }
    },
};
</script>

<style>
</style>