<template>
    <md-card md-with-hover class="md-layout-item map-card">
        <div>
            <md-button
                v-if="canFavorite"
                class="md-icon-button favorite-btn"
                v-on:click.stop="toggleFavorite"
            >
                <md-icon v-bind:class="{ 'is-favorite': isFavorite }"
                    >favorite</md-icon
                >
            </md-button>

            <md-button
                v-if="isAdmin"
                class="md-icon-button adv-statistics-btn"
                v-on:click.stop="openAdvStatics"
            >
                <md-icon>insights</md-icon>
            </md-button>

            <md-card-media>
                <MapImage class="card-img" :imgUrl="imgUrl" :mapId="mapId" />
                <div class="md-caption creator-container" @click="openProfile">
                    <span>By:</span>
                    <img
                        src="/img/logo.webp"
                        alt="WhereRU logo"
                        v-if="officialMap"
                    />
                    <span>{{ (officialMap ? "" : "&nbsp;") + ownerName }}</span>
                </div>

                <HoverTooltip
                    id="can-region-guess"
                    class="right"
                    text="Region guessing game mode available"
                    v-if="canRegionGuess"
                >
                    <md-icon class="overall-plays"> public </md-icon>
                </HoverTooltip>
            </md-card-media>

            <md-card-header>
                <div class="md-title">{{ title }}</div>
                <div class="md-subhead">
                    {{ desc }}
                </div>

                <md-button
                    v-if="canEdit"
                    class="md-icon-button edit-btn"
                    v-on:click.stop="onEdit"
                >
                    <md-icon> edit </md-icon>
                </md-button>
            </md-card-header>

            <md-card-content>
                <div class="tag-container">
                    <md-chip v-for="tag in tags" :key="tag">
                        {{ tag }}
                    </md-chip>
                </div>
            </md-card-content>

            <md-card-actions style="justify-content: center">
                <md-button
                    class="highscore-btn md-raised"
                    @click="clickHighScore"
                    >High Scores</md-button
                >
                <md-button class="md-primary md-raised" @click="onClick"
                    >Play</md-button
                >
            </md-card-actions>

            <HoverTooltip
                id="nr-favorites-container"
                class="left"
                :text="`${nrFavoritesLocal} user${
                    nrFavoritesLocal !== 1 ? 's' : ''
                } favorite this map`"
            >
                <md-icon class="overall-favorites"> favorite </md-icon>
                <span class="md-caption">{{ nrFavoritesLocal }}</span>
            </HoverTooltip>

            <HoverTooltip
                id="nr-plays-container"
                class="right"
                :text="`Played ${nrPlays} time${nrPlays !== 1 ? 's' : ''}`"
            >
                <md-icon class="overall-plays"> equalizer </md-icon>
                <span class="overall-plays-caption md-caption">{{
                    nrPlays
                }}</span>
            </HoverTooltip>
        </div>
        <div
            v-if="hasLobby"
            id="add-to-lobby-btn"
            :class="{ disabled: loadingChange }"
        >
            <md-button
                class="md-icon-button md-dense md-raised"
                :disabled="loadingChange"
                @click="changeLobbyMap"
            >
                <md-icon>co_present</md-icon>
                <md-tooltip md-delay="500" md-direction="top"
                    >Change your Party Lobby to use this map.</md-tooltip
                >
            </md-button>
        </div>
    </md-card>
</template>

<script>
import HoverTooltip from "../components/HoverTooltip.vue";
import { mapGetters } from "vuex";
import axiosMixin from "../mixins/axiosMixin";
import MapImage from "../components/MapImage.vue";

export default {
    name: "MapCard",
    mixins: [axiosMixin],
    components: {
        HoverTooltip,
        MapImage,
    },
    props: {
        title: String,
        desc: String,
        tags: Array,
        mapId: Number,
        imgUrl: String,
        nrPlays: Number,
        nrFavorites: Number,
        favorite: Boolean,
        canEdit: Boolean,
        canFavorite: Boolean,
        officialMap: Boolean,
        ownerName: String,
        ownerId: String,
        hasLobby: Boolean,
        canRegionGuess: Boolean,
    },
    data() {
        return {
            isFavorite: false,
            nrFavoritesChange: 0,
            loadingChange: false,
        };
    },
    computed: {
        ...mapGetters(["isAdmin"]),
        nrFavoritesLocal: function () {
            return this.nrFavorites + this.nrFavoritesChange;
        },
    },
    methods: {
        onClick() {
            this.$router.push({
                name: "PreGame",
                params: {
                    mapIdIn: this.mapId,
                },
            });
        },
        openProfile() {
            this.$router.push({
                path: "/profile/maps",
                query: {
                    userId: this.ownerId,
                },
            });
        },
        toggleFavorite() {
            this.isFavorite = !this.isFavorite;
            this.$parent.$emit("toggleFavorite", {
                mapId: this.mapId,
                favorite: this.isFavorite,
            });
            if (this.isFavorite) {
                this.nrFavoritesChange++;
                axiosMixin.methods.makeRequest(
                    "put",
                    process.env.VUE_APP_SERVER_URL + "map/addFavorite",
                    { mapId: this.mapId }
                );
            } else {
                this.nrFavoritesChange--;
                axiosMixin.methods.makeRequest(
                    "delete",
                    process.env.VUE_APP_SERVER_URL + "map/removeFavorite",
                    { mapId: this.mapId }
                );
            }
        },
        openAdvStatics() {
            // Change to advanced statistics view
            this.$router.push({
                name: "AdvStatistics",
                params: { mapId: this.mapId },
            });
        },
        changeLobbyMap() {
            this.loadingChange = true;
            axiosMixin.methods
                .makeRequest(
                    "put",
                    process.env.VUE_APP_SERVER_URL + "game/updateLobbyMap",
                    { mapId: this.mapId }
                )
                .then(() => {
                    this.loadingChange = false;
                    this.$router.push({
                        name: "Multiplayer",
                    });
                });
        },
        onEdit() {
            // Change to edit view
            this.$router.push({
                name: "EditMap",
                params: { mapId: this.mapId },
            });
        },
        clickHighScore() {
            this.$router.push({
                name: "HighScore",
                params: { mapId: this.mapId },
            });
        },
    },
    created() {
        this.isFavorite = this.favorite;
    },
};
</script>

<style lang="scss" scoped>
.md-card {
    background: rgb(255, 255, 255);
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0.5) 95%
    );
    border-radius: 8px;
}
.md-card .md-title {
    overflow: hidden;
}

.md-card .md-subhead {
    overflow: auto;
    max-height: 40px;
    height: 40px;
    overflow-wrap: anywhere;
}

.tag-container {
    position: relative;
}

.favorite-btn {
    position: absolute;
    top: 5px;
}

.highscore-btn {
    background: rgb(232, 240, 239) !important;
}
.adv-statistics-btn {
    position: absolute;
    top: 5px;
    right: 0px;
    background: rgba(95, 95, 95, 0.6);
}

.adv-statistics-btn i {
    color: rgb(255, 255, 255) !important;
}

.md-card-header {
    padding-top: 5px;
    padding-bottom: 0;
}

.md-card-content {
    padding-bottom: 0;
}

#nr-plays-container {
    position: absolute;
    right: 0;
    bottom: 0;
}

#nr-plays-container span {
    position: relative;
    bottom: -3px;
}

#nr-favorites-container {
    position: absolute;
    left: 0;
    bottom: 0;
}

#nr-favorites-container span {
    position: relative;
    bottom: -3px;
}

.is-favorite {
    color: #d9445e !important;
}

.card-img {
    max-height: 133px;
    object-fit: cover;
}

.edit-btn {
    position: absolute;
    top: 1px;
    right: 4px;
}

.md-card-header {
    position: relative;
}

.creator-container {
    position: absolute;
    left: 0px;
    bottom: 4px;
    background: #2b2b2bc2;
    padding: 1px 5px;
    color: white;
    display: inline-flex;
}

.creator-container:hover {
    background: #888888b3;
}

.creator-container span {
    height: 20px;
    position: relative;
    top: 1px;
}

.creator-container img {
    height: 20px;
}

#add-to-lobby-btn {
    position: absolute;
    top: 60px;
    right: 0;
}

#add-to-lobby-btn button {
    background: #5dac5d;
}

#add-to-lobby-btn.disabled button {
    background: grey;
}

#add-to-lobby-btn i {
    font-size: 22px !important;
    color: white;
}

.overall-favorites {
    margin-left: 5px;
    margin-bottom: 5px;
}

.overall-plays {
    margin-bottom: 5px;
}

.overall-plays-caption {
    margin-right: 5px;
    margin-bottom: 5px;
}

.md-card {
    min-width: 280px;
    max-width: 400px;
}

#can-region-guess {
    position: absolute;
    bottom: 0;
    right: 5px;
}

#can-region-guess i {
    color: #0c6c12;
    background: #338dca;
    border-radius: 50%;
}

</style>