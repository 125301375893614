<template>
    <div ref="outer" :style="{'--end-pos-damage-left':endPosDamageLeft, '--end-pos-damage-right':endPosDamageRight}">
        <div class="flex-center">
            <span class="md-display-2" style="margin: 10px 0"
                >Round {{ round }}</span
            >
        </div>
        <div style="min-width: 350px">
            <div class="md-layout">
                <div
                    class="md-layout-item md-size-50 flex-center"
                    :style="`background:${team1.color}`"
                >
                    <span class="md-headline">{{ team1.teamName }}</span>
                </div>
                <div
                    class="md-layout-item md-size-50 flex-center"
                    :style="`background:${team2.color}`"
                >
                    <span class="md-headline">{{ team2.teamName }}</span>
                </div>
            </div>
            <div
                class="flex-center"
                style="margin-top: 10px; position: relative; height: 10px"
            >
                <span
                    class="md-headline"
                    :class="{ big: multiplierBig }"
                    id="multiplier"
                    >Multiplier x{{ multiplier }}</span
                >
            </div>
            <div
                class="flex-center"
                style="height: 60px; margin-top: 25px; position: relative"
            >
                <div
                    class="score"
                    ref="leftScore"
                    :class="{ animate: animating }"
                    :style="{ transform: `translateX(${scoreLeftStartPos}px)` }"
                >
                    {{ leftScore }}
                </div>
                <div
                    class="score"
                    :class="{ hidden: showDifference, animate: animating }"
                    :style="{ transform: `translateX(${scoreLeftPos}px)` }"
                >
                    {{ leftScore }}
                </div>

                <div
                    class="score"
                    ref="rightScore"
                    :class="{ animate: animating }"
                    :style="{
                        transform: `translateX(${scoreRightStartPos}px)`,
                    }"
                >
                    {{ rightScore }}
                </div>
                <div
                    class="score"
                    :class="{ hidden: showDifference, animate: animating }"
                    :style="{ transform: `translateX(${scoreRightPos}px)` }"
                >
                    {{ rightScore }}
                </div>
                <div
                    v-if="showDifference"
                    class="difference"
                    style="position: relative"
                >
                    <span
                        class="md-display-1 mult-text old-diff"
                        :class="{ new: multiplierApplied }"
                    >
                        {{ difference }}
                    </span>
                    <span
                        class="md-display-1 mult-text mult-diff"
                        :class="{ new: multiplierApplied }"
                    >
                        {{ differenceMult }}
                    </span>
                    <span
                        class="md-display-1 mult-text mult-diff"
                        id="movable-mult"
                        v-if="showFlyingMult"
                        :class="{
                            'new': multiplierApplied,
                            'left': targetLeft,
                            'right': !targetLeft,
                        }"
                    >
                        -{{ differenceMult }}
                    </span>
                </div>
            </div>
            <div class="flex-center md-layout" style="position: relative">
                <div class="md-layout-item md-size-50">
                    <div style="margin: 10px; position: relative">
                        <ProgressBar
                            :value="leftHealthProg"
                            :animate="animating"
                            :invert="false"
                            style="height: 50px"
                            :color="team1.color"
                        ></ProgressBar>
                        <div
                            class="score"
                            :class="{ animate: animating }"
                            style="left: 10px; top: 15px"
                        >
                            {{ curLeftHealth > 0 ? curLeftHealth : "OUT!" }}
                        </div>
                    </div>
                </div>
                <div class="md-layout-item md-size-50">
                    <div style="margin: 10px; position: relative">
                        <ProgressBar
                            :value="rightHealthProg"
                            :animate="animating"
                            :invert="true"
                            style="height: 50px"
                            :color="team2.color"
                        ></ProgressBar>
                        <div
                            class="score"
                            :class="{ animate: animating }"
                            style="right: 10px; top: 15px"
                        >
                            {{ curRightHealth > 0 ? curRightHealth : "OUT!" }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex-center" style="height: 34px; margin-bottom: 15px">
                <span
                    class="sf-small"
                    style="font-size: clamp(20px, 2vw, 30px)"
                    >{{ hitText }}</span
                >
            </div>
        </div>
        <div style="display: flex; justify-content: space-between">
            <md-button
                @click="animateScores"
                :disabled="animating"
                class="md-icon-button"
                ><md-icon>restart_alt</md-icon></md-button
            >
            <md-button
                @click="$emit('close')"
                class="md-raised md-primary"
                style="margin-left: auto"
                >Continue</md-button
            >
        </div>
    </div>
</template>

<script>
import ProgressBar from "../../components/ProgressBar";

export default {
    components: {
        ProgressBar,
    },
    data() {
        return {
            leftScore: 0,
            rightScore: 0,

            leftHealth: 0,
            rightHealth: 0,
            prevLeftHealth: 0,
            prevRightHealth: 0,
            curLeftHealth: 0,
            curRightHealth: 0,
            prgLeftHealth: 0,
            prgRightHealth: 0,

            scoreLeftStartPos: 0,
            scoreRightStartPos: 0,

            scoreLeftPos: 0,
            scoreRightPos: 0,

            endPosDamageLeft: '170px',
            endPosDamageRight: '-170px',

            multiplierBig: false,
            multiplierApplied: false,
            showFlyingMult: false,
            showDifference: false,
            animating: false,

            hitText: "",

            debug: false,

            refs: {
                leftScore: null,
                rightScore: null,
                outer: null,
            },
        };
    },
    props: {
        team1: Object,
        team2: Object,
        round: Number,
        duelHP: Number,
    },
    watch: {
        team1(v) {
            this.leftScore = v.roundScore;
            this.setHealth();
        },
        team2(v) {
            this.rightScore = v.roundScore;
            this.setHealth();
        },
    },
    computed: {
        difference() {
            return Math.abs(this.leftScore - this.rightScore);
        },
        differenceMult() {
            return Math.floor(this.difference * this.multiplier);
        },
        leftHealthProg() {
            return Math.round((this.prgLeftHealth / this.duelHP) * 100);
        },
        rightHealthProg() {
            return Math.round((this.prgRightHealth / this.duelHP) * 100);
        },
        multiplier() {
            if (this.round < 6) {
                return 1;
            }

            return this.round * 0.5 - 1;
        },
        targetLeft() {
            return this.rightScore > this.leftScore;
        },
    },
    methods: {
        getHitText() {
            const damage = this.differenceMult;
            const smallHit = ["You aight'!", "Hit!", "Ouch!", "OOF"];

            var textArray = smallHit;

            if (this.difference === 5000) {
                textArray = ["Now that's a lot of damage!", "Strike!"];
            } else if (damage === 123) {
                textArray = ["1 2 3 - You're it!"];
            } else if (damage === 234) {
                textArray = ["Hut! 2, 3, 4!"];
            } else if (damage === 1) {
                textArray = ["One small step"];
            } else if (damage === 2) {
                textArray = ["What's better than 1?"];
            } else if (damage === 1337) {
                textArray = [
                    "Leet.",
                    "Leet.",
                    "Här kan man va.",
                    "Sluta upp Lars-Olof!",
                ];
            } else if (damage === 69) {
                textArray = ["Nice."];
            } else if (damage === 420) {
                textArray = ["20th of March?"];
            } else if (damage === 1944) {
                textArray = ["D-Day upon us."];
            } else if (damage === 1989) {
                textArray = ["This year nothing happened Apr 15th."];
            } else if (damage > 20000) {
                textArray = [
                    "To infinity and beyond!",
                    "Snyggt byggt, bra score.",
                ];
            } else if (damage > 9000) {
                textArray = [
                    "IT'S OVER 9000!",
                    "Gimme the loot! Gimme the loot!",
                ];
            } else if (damage > 6000) {
                textArray = [
                    "Never stood a chance",
                    "Omae wa mou shindeiru",
                    "Gimme the loot! Gimme the loot!",
                ];
            } else if (this.leftHealth <= -4000 || this.rightHealth <= -4000) {
                textArray = ["OVERKILL!", "MONSTER KILL"];
            } else if (this.leftHealth <= 0 || this.rightHealth <= 0) {
                if (damage < 100) {
                    textArray = [
                        "The straw that broke it.",
                        "GG IZI",
                        "So close, yet...",
                        "Better luck next time",
                    ];
                } else {
                    textArray = [
                        "Fatality!",
                        "All your health are belong to us!",
                        "TKO",
                        "You're done kid.",
                        "Another one bites the dust.",
                        "You're out!",
                    ];
                }
            } else if (damage > 3000) {
                textArray = [
                    "Now that's gotta hurt!",
                    "Critical hit!",
                    "Lucky strike?",
                    "But a scratch!",
                    "You are going nite nite!",
                    "Spare!",
                    "Finish him!",
                    "Fräsigt.",
                ];
            } else if (damage > 1000) {
                textArray = [
                    "Solid hit",
                    "Get some minis!",
                    "They pierced our shields!",
                    "Taking in water!",
                    "Solid!",
                ];
            } else if (damage < 100) {
                textArray = [
                    "You call that a knife?!",
                    "An even match!",
                    "That's a bug bite!",
                    "oof (in d minor)",
                ];
            }
            const randomNumber = Math.floor(Math.random() * textArray.length);
            return textArray[randomNumber];
        },
        setHealth() {
            this.leftHealth = this.team1.health;
            this.rightHealth = this.team2.health;
            if (this.leftScore > this.rightScore) {
                this.prevLeftHealth = this.leftHealth;
                this.prevRightHealth = Math.max(
                    this.rightHealth + this.differenceMult,
                    0
                );
            } else {
                this.prevLeftHealth = Math.max(
                    this.leftHealth + this.differenceMult,
                    0
                );
                this.prevRightHealth = this.rightHealth;
            }
            this.curLeftHealth = this.prevLeftHealth;
            this.curRightHealth = this.prevRightHealth;
            this.prgLeftHealth = this.prevLeftHealth;
            this.prgRightHealth = this.prevRightHealth;
        },

        animateScores() {
            this.hitText = "";
            this.showDifference = false;
            this.curLeftHealth = this.prevLeftHealth;
            this.curRightHealth = this.prevRightHealth;
            this.prgLeftHealth = this.prevLeftHealth;
            this.prgRightHealth = this.prevRightHealth;
            this.multiplierApplied = false;

            this.$nextTick(() => {
                if (this.difference === 0) {
                    this.hitText = "No damage!";
                    return;
                }

                setTimeout(() => {
                    this.animating = true;
                    this.scoreLeftPos = 0;
                    this.scoreRightPos = 0;
                    setTimeout(() => {
                        this.scoreLeftPos = this.scoreLeftStartPos;
                        this.scoreRightPos = this.scoreRightStartPos;
                        this.showDifference = true;

                        setTimeout(() => {
                            this.multiplierBig = this.multiplier != 1;
                            this.hitText = this.getHitText();
                            setTimeout(
                                () => {
                                    this.multiplierApplied = true;
                                    this.showFlyingMult = true;
                                    this.$nextTick(() => {
                                        this.$el
                                            .querySelector("#movable-mult")
                                            .addEventListener(
                                                "animationend",
                                                () => {
                                                    this.showFlyingMult = false;
                                                }
                                            );
                                    });
                                    setTimeout(() => {
                                        this.multiplierBig = false;
                                        this.lowerHealth();
                                        setTimeout(() => {
                                            this.animating = false;
                                        }, 2000);
                                    }, 500);
                                },
                                this.multiplier != 1 ? 800 : 0
                            );
                        }, 200);
                    }, 900);
                }, 1000);
            });
        },
        lowerHealth() {
            const stepSize = 30;
            const animTime = 1000;
            this.prgLeftHealth = Math.max(0, this.leftHealth);
            this.prgRightHealth = Math.max(0, this.rightHealth);

            if (
                this.prevLeftHealth > this.leftHealth &&
                this.curLeftHealth > this.leftHealth &&
                this.curLeftHealth > 0
            ) {
                const stepLeft = Math.ceil(
                    (this.prevLeftHealth - this.leftHealth) / stepSize
                );
                setTimeout(() => {
                    this.curLeftHealth -= Math.min(
                        stepLeft,
                        Math.abs(this.curLeftHealth - this.leftHealth),
                        this.curLeftHealth
                    );
                    this.lowerHealth();
                }, Math.floor(animTime / stepSize));
            }
            if (
                this.prevRightHealth > this.rightHealth &&
                this.curRightHealth > this.rightHealth &&
                this.curRightHealth > 0
            ) {
                const stepRight = Math.ceil(
                    (this.prevRightHealth - this.rightHealth) / stepSize
                );
                setTimeout(() => {
                    this.curRightHealth -= Math.min(
                        stepRight,
                        Math.abs(this.curRightHealth - this.rightHealth),
                        this.curRightHealth
                    );
                    this.lowerHealth();
                }, Math.floor(animTime / stepSize));
            }
        },
        handleResize() {
            this.calcStartingPositions();
        },
        calcStartingPositions() {
            const width = this.refs.outer.offsetWidth;
            this.scoreLeftStartPos =
                -width / 2 + this.refs.leftScore.offsetWidth / 2 + 20;
            this.scoreRightStartPos =
                width / 2 - this.refs.rightScore.offsetWidth / 2 - 20;
            this.scoreLeftPos = this.scoreLeftStartPos;
            this.scoreRightPos = this.scoreRightStartPos;
            this.endPosDamageLeft = `${this.scoreLeftStartPos}px`;
            this.endPosDamageRight = `${this.scoreRightStartPos-10}px`;
        },
    },
    mounted() {
        this.animateScores();
        this.leftScore = this.team1.roundScore;
        this.rightScore = this.team2.roundScore;

        this.refs = {
            leftScore: this.$refs.leftScore,
            rightScore: this.$refs.rightScore,
            outer: this.$refs.outer,
        };

        this.setHealth();

        this.$nextTick(() => {
            this.calcStartingPositions();
            window.addEventListener("resize", this.handleResize.bind(this));
        });
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.handleResize.bind(this));
    },
};
</script>

<style>
.score {
    font-size: 36px;
    position: absolute;
}

.score.animate {
    transition: transform 0.7s ease-in-out;
}

.difference {
    position: absolute;
}

.hidden {
    opacity: 0;
    pointer-events: none;
    /* transition: opacity 0.5s ease-in-out; */
}

#multiplier {
    position: absolute;
    top: 0px;
    transition: font-size 0.5s ease;
}

#multiplier.big {
    font-size: 34px;
}

.old-diff,
.mult-diff {
    transition: opacity 0.5s linear;
}

.old-diff.new {
    opacity: 0;
}

.mult-diff {
    opacity: 0;
}
.mult-diff.new {
    opacity: 1;
}

.mult-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#movable-mult {
    color: red;
}
#movable-mult.new.right {
    animation: move-right 1.5s linear;
}
#movable-mult.new.left {
    animation: move-left 1.5s linear;
}

@keyframes move-left {
    from {
        top: 0px;
        left: 0px;
    }
    to {
        top: 70px;
        left: var(--end-pos-damage-left);
    }
}

@keyframes move-right {
    from {
        top: 0px;
        left: 0px;
    }
    to {
        top: 70px;
        left: var(--end-pos-damage-right);
    }
}
</style>
