<template>
    <div class="settings-form">
        <span
            class="md-display-2"
            style="margin-top: 20px"
            :class="{ hidden: loadingMapInfo }"
            >{{ mapName }}</span
        >
        <md-divider style="margin-bottom: 5px" />

        <!-- Single/Challenge selector -->
        <div id="player-mode" class="md-gutter md-layout">
            <!-- Single player selector -->
            <div class="player-mode-group md-layout">
                <md-content
                    class="md-raised btn-player-mode"
                    v-on:click="locked ? null : (mode = 'single')"
                    v-bind:class="{
                        'md-primary': mode === 'single',
                        'md-accent': mode !== 'single',
                    }"
                >
                    <img
                        type="image/svg+xml"
                        class="gamemode-icon"
                        src="img/singleplayer.svg"
                        style="max-height: 30%; pointer-events: none"
                    />
                </md-content>
                <span class="md-headline">Singleplayer</span>
            </div>

            <!-- Challenge selector -->
            <div class="player-mode-group md-layout">
                <md-content
                    class="md-raised btn-player-mode"
                    v-on:click="mode = 'challenge'"
                    v-bind:class="{
                        'md-primary': mode === 'challenge',
                        'md-accent': mode !== 'challenge',
                    }"
                >
                    <img
                        type="image/svg+xml"
                        class="gamemode-icon"
                        src="img/challenge.svg"
                        style="max-height: 30%; pointer-events: none"
                    />
                </md-content>
                <span class="md-headline">Challenge</span>
            </div>

            <!-- Multiplayer selector -->
            <div class="player-mode-group md-layout">
                <md-content
                    class="md-raised btn-player-mode"
                    v-on:click="locked ? null : (mode = 'multi')"
                    v-bind:class="{
                        'md-primary': mode === 'multi',
                        'md-accent': mode !== 'multi',
                    }"
                >
                    <img
                        type="image/svg+xml"
                        class="gamemode-icon"
                        src="img/multiplayer.svg"
                        style="max-height: 50%; pointer-events: none"
                    />
                </md-content>
                <span class="md-headline">Multiplayer</span>
            </div>

            <!-- Party selector -->
            <div class="player-mode-group md-layout">
                <md-content
                    class="md-raised btn-player-mode"
                    v-on:click="locked ? null : (mode = 'party')"
                    v-bind:class="{
                        'md-primary': mode === 'party',
                        'md-accent': mode !== 'party',
                    }"
                >
                    <img
                        type="image/svg+xml"
                        class="gamemode-icon"
                        src="img/partygame.svg"
                        style="max-height: 50%; pointer-events: none"
                    />
                </md-content>
                <span class="md-headline">Party</span>
            </div>
        </div>

        <div style="margin-top: 15px; position: relative" class="info-text">
            <md-icon style="position: relative; bottom: 1px">info</md-icon>
            <span class="md-caption">
                {{ gamemodeInfoText }}
            </span>
        </div>

        <md-divider class="md-divider" style="margin-top: 5px" />

        <GameSettings
            :mode="mode"
            :mapId="mapId"
            :canRegionGuess="canRegionGuess"
            :locked="locked"
            :initialSettings="settings"
            @validChanged="settingsValid = $event"
            @settingsChanged="settingsChanged"
        />

        <template v-if="(mode === 'single') && canRegionGuess">
            <div id="single-player-settings" class="flex-down">
                <span class="md-headline" style="text-align: center; margin-bottom: 10px">{{
                    "Singleplayer Settings"
                }}</span>
                <div v-if="canRegionGuess">
                    <switches
                        v-model="gameSettings.guessRegion"
                        class="switch"
                        :disabled="locked"
                        color="blue"
                    ></switches
                    ><span>Region streak</span>
                    <md-tooltip md-direction="bottom" md-delay="500"
                        >Continue playing until you get the region wrong</md-tooltip
                    >
                </div>
            </div>
        </template>

        <!-- Lock settings button -->
        <div class="btn-div" v-if="mode === 'challenge' && !locked">
            <md-button
                class="md-raised md-accent"
                v-on:click="lockSettings"
                :disabled="disableStartButton"
                >Lock Settings</md-button
            >
        </div>

        <!-- Div containing Challenge link -->
        <template v-if="loading">
            <md-progress-bar
                style="margin-top: 15px"
                class="md-accent"
                md-mode="indeterminate"
            ></md-progress-bar>
        </template>
        <div class="link-div" v-else-if="mpLink">
            <div>
                <label for="mp-link" class="md-caption unselectable-text"
                    >Share this link with others for them to play on the same
                    map:</label
                >
                <br />
                <span id="mp-link">{{ mpLink }}</span>
            </div>

            <!-- Button to copy link -->
            <md-button
                id="btn-copy"
                v-on:click="copy()"
                class="md-icon-button md-raised md-primary"
            >
                <md-icon> content_copy </md-icon>
            </md-button>
        </div>

        <!-- Start game button -->
        <div class="btn-div">
            <md-button
                type="submit"
                class="md-raised md-accent"
                v-if="hasNewGameInstance || mode !== 'challenge'"
                v-on:click="onStart"
                :disabled="disableStartButton"
                >{{
                    mode === "single" || mode === "challenge"
                        ? "Start Game"
                        : "Create Lobby"
                }}</md-button
            >
        </div>

        <!-- Popup informing the user that they have copied the link -->
        <md-snackbar
            md-position="center"
            :md-duration="2000"
            :md-active.sync="showSnackbar"
            md-persistent
        >
            <span>{{ message }}</span>
        </md-snackbar>
    </div>
</template>

<script>
import GameSettings from "../components/GameSettings.vue";
import axiosMixin from "../mixins/axiosMixin";
import utilMixin from "../mixins/utilMixin";
import { mapActions, mapMutations } from "vuex";
import Switches from "vue-switches";

export default {
    name: "PreGame",
    mixins: [axiosMixin, utilMixin],
    props: {
        mapIdIn: Number,
        settings: Object,
    },
    components: {
        GameSettings,
        Switches,
    },
    data() {
        return {
            mapName: ".....",
            mapId: null,
            mode: "single",

            locked: false,
            loading: false,
            loadingMapInfo: true,

            mpLink: "",

            showSnackbar: false,
            hasClickedStart: false,
            hasNewGameInstance: false,
            gameId: null,

            message: "",

            canRegionGuess: false,

            settingsValid: true,
            gameSettings: {},
        };
    },
    computed: {
        disableStartButton() {
            return this.hasClickedStart || !this.isValid;
        },
        isValid() {
            return (
                this.settingsValid &&
                !(this.partyGameType == 1 && isNaN(parseInt(this.duelHP)))
            );
        },
        gamemodeInfoText() {
            const t = {
                single: "Play solo and climb the highscore tables",
                challenge:
                    "Play solo and generate a link you can send to your friends, challenging them to beat your score",
                party: "Host a lobby where you control the panorama and the other players guess the location",
                multi: "Host a lobby where all players can control their own panorama and guess",
            };
            const text = t[this.mode];

            return text ? text : "Loading";
        },
    },
    methods: {
        ...mapActions(["setGameInstance", "setScoreDialogVis"]),
        ...mapMutations(["commitCenterZoom", "commitHasNotGuessed"]),

        lockSettings() {
            this.newGame();
            this.locked = true;
            this.loading = true;

            axiosMixin.methods
                .makeRequest(
                    "post",
                    process.env.VUE_APP_SERVER_URL + "game/newChallengeGame",
                    null,
                    this.gameSettings
                )
                .then((resp) => {
                    this.loading = false;

                    const _this = this;
                    setTimeout(function () {
                        _this.hasNewGameInstance = true;
                        _this.gameId = resp.data;
                        _this.mpLink =
                            window.location.origin +
                            "/joinGame?gameId=" +
                            _this.gameId;
                    }, 500);
                });
        },
        togglePlayerMode(value) {
            this.active = value;
        },

        copy() {
            this.$clipboard(this.mpLink);
            this.showSnackbar = true;
            this.message = "Copied to clipboard";
        },

        onStart() {
            if (!this.isValid) {
                return;
            }
            this.hasClickedStart = true;

            if (this.mode === "challenge" || this.mode === "single") {
                this.newGame();
                axiosMixin.methods
                    .makeRequest(
                        "get",
                        process.env.VUE_APP_SERVER_URL + "map/byId",
                        { mapId: this.mapId }
                    )
                    .then((resp) => {
                        if (resp.status == 200) {
                            const mapResp = resp.data;
                            this.commitCenterZoom({
                                center: mapResp.center,
                                zoom: mapResp.zoom,
                            });
                            this.start();
                        } else {
                            this.showSnackbar = true;
                            this.message = "Could not fetch map";
                        }
                    });
            } else {
                this.$router.push({
                    name: "Multiplayer",
                    params: {
                        settings: this.gameSettings,
                    },
                });
            }
        },
        start() {
            if (this.mode === "challenge") {
                // If challenge player
                axiosMixin.methods
                    .makeRequest(
                        "get",
                        process.env.VUE_APP_SERVER_URL + "game/joinGame",
                        {
                            gameId: this.gameId,
                        }
                    )
                    .then((resp) => {
                        if (resp.status == 200) {
                            this.setGameInstance(resp.data);
                            this.setScoreDialogVis(false);
                            this.$router.push({
                                name: "Game",
                            });
                        } else {
                            this.showSnackbar = true;
                            this.message = "Map not valid.";
                        }
                    });
                return;
            } else if (this.mode === "single") {
                // If single player
                axiosMixin.methods
                    .makeRequest(
                        "post",
                        process.env.VUE_APP_SERVER_URL + "game/newGame",
                        null,
                        this.gameSettings
                    )
                    .then((resp) => {
                        if (resp.status == 200) {
                            this.setGameInstance(resp.data);
                            this.setScoreDialogVis(false);
                            this.$router.push({
                                name: "Game",
                            });
                        } else {
                            this.showSnackbar = true;
                            this.message = "Map not valid.";
                        }
                    });
            }
        },

        newGame() {
            this.commitHasNotGuessed();
        },
        
        settingsChanged(event) {
            if (this.mode === "single" || this.mode === "challenge") {
                const reg = this.gameSettings.guessRegion;
                this.gameSettings = event;
                this.gameSettings.guessRegion = reg;
            } else {
                this.gameSettings = event;
            }
        }
    },
    created() {
        if (this.mapIdIn) {
            this.mapId = this.mapIdIn;
            localStorage.setItem("mapId", this.mapId);
        } else {
            this.mapId = parseInt(localStorage.getItem("mapId"));
        }

        if (!this.mapId) {
            // If we got no map id return to home
            this.$router.push({
                name: "Home",
            });
        }

        axiosMixin.methods
            .makeRequest("get", process.env.VUE_APP_SERVER_URL + "map/byId", {
                mapId: this.mapId,
            })
            .then((resp) => {
                this.loadingMapInfo = false;
                if (resp.status == 200) {
                    this.mapName = resp.data.mapName;
                    this.canRegionGuess = resp.data.canRegionGuess;
                }
            });

        if (this.settings) {
            this.mode =
                this.settings.mode !== undefined
                    ? this.settings.mode
                    : "single";
        }
    },
};
</script>

<style lang="scss" scoped>
.hidden {
    opacity: 0;
}

.settings-form {
    min-width: calc(min(420px, 100%));
    margin: 0 auto;
    position: relative;
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.md-content {
    width: calc(min(150px, 33vw, 33vh));
    height: calc(min(150px, 33vw, 33vh));
    display: inline-flex;
    justify-content: center;
}

.btn-player-mode {
    border-radius: 50%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.player-mode-group {
    display: flex;
    flex-direction: column;
    margin: 0 5px;
    margin-top: 15px;
}

.md-divider {
    margin-top: 15px;
    margin-bottom: 15px;
}

.link-div {
    text-align: left;
    border: 1px solid #ccc !important;
    border-radius: 10px;
    padding: 10px;
    position: relative;
    margin-top: 15px;
}

#btn-copy {
    position: absolute;
    right: 10px;
    top: 10px;
}

.btn-div {
    text-align: right;
    margin-top: 15px;
}

.btn-div .md-button {
    margin: 0;
}

.gamemode-icon {
    width: auto;
    height: auto;
}

@media (max-width: 800px) {
    .info-text {
        min-height: 44px;
    }
}

#single-player-settings{
    margin-top: 10px;
}

</style>

<style>
.switch {
    margin: 0 10px;
}
</style>