<template>
    <md-content class="md-dark flex-down">
        <span class="md-display-2" style="margin-bottom: 25px">Rematch?</span>
        <div class="button-container">
            <md-button v-if="!loading" class="md-raised md-button-mobile md-red" @click="$emit('endGame')">Close Lobby</md-button>
            <md-button class="md-raised md-button-mobile md-primary" @click="rehost" :disabled="loading">
                <span v-if="!loading">Rematch</span>
                <md-progress-spinner v-else md-mode="indeterminate" class="md-accent"></md-progress-spinner>
            </md-button>
        </div>
    </md-content>
</template>

<script>
export default {
    data() {
        return  {
            loading: false,
        }
    },
    methods: {
        rehost() {
            this.loading = true;
            this.$emit('rehost');
        },
    }
};
</script>

<style>
.button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media (max-width: 480px) {
  .button-container {
    flex-direction: column;
  }
}
</style>