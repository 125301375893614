<template>
    <div id="lobby-container" class="flex-down">
        <template v-if="info.host">
            <div class="top-container flex-center">
                <div
                    class="left-container flex-center"
                    style="flex-direction: row"
                >
                    <div class="player-a">
                        <md-content class="md-primary player-display">
                            <md-icon>person</md-icon>
                            <span>{{ nrPlayers }}</span>
                        </md-content>
                    </div>
                    <div class="middle-container flex-center">
                        <div id="pin-container" class="flex-down">
                            <span class="sf-big header-text">Enter PIN:</span>
                            <span class="sf-big bold">{{ roomCode }}</span>
                        </div>
                        <div>
                            <span class="sf-big header-text" style="margin: 2vw"
                                >OR</span
                            >
                        </div>
                        <div id="qr-container">
                            <img
                                id="qr-code"
                                :src="
                                    'https://api.qrserver.com/v1/create-qr-code/?size=150x150&format=svg&data=' +
                                    encodeURIComponent(inviteLink)
                                "
                                alt="QR Code"
                                @click="showQR = true"
                                @load="qrLoaded"
                                :class="{ 'image-hidden': !qrImageLoaded }"
                            />
                        </div>
                    </div>
                </div>
                <div class="align-right flex-left-center">
                    <md-content class="md-primary player-display player-b">
                        <md-icon>person</md-icon>
                        <span>{{ nrPlayers }}</span>
                    </md-content>
                    <BigButton
                        style="margin: 0 5px 0 auto"
                        class="md-accent max-sized"
                        @click="openMenu = !openMenu"
                        ><md-icon>{{
                            openMenu ? "expand_less" : "expand_more"
                        }}</md-icon>
                    </BigButton>
                    <BigButton
                        class="md-primary max-sized"
                        style="margin: 0"
                        :disabled="startDisabled.disabled"
                        @click="$emit('start')"
                        >Start
                        <md-tooltip v-if="startDisabled.disabled" md-delay="300">{{startDisabled.message}}</md-tooltip>
                        </BigButton
                    >
                </div>
            </div>

            <div class="more-container flex" v-if="openMenu">
                <BigButton class="md-accent" @click="toggleLocalFullscreen"
                    ><md-icon v-if="fullscreen">fullscreen_exit</md-icon>
                    <md-icon v-else>fullscreen</md-icon>
                    <md-tooltip md-direction="top"
                        >Toggle Fullscreen</md-tooltip
                    >
                </BigButton>
                <BigButton class="md-accent" @click="lock"
                    ><md-icon v-if="locked">lock</md-icon
                    ><md-icon v-else>lock_open</md-icon>
                    <md-tooltip md-direction="top"
                        >Click to {{ locked ? "allow" : "prevent" }} new
                        players</md-tooltip
                    >
                </BigButton>
                <BigButton class="md-accent" @click="copy"
                    ><md-icon>content_copy</md-icon>
                    <md-tooltip md-direction="top">Copy Invite Link</md-tooltip>
                </BigButton>
                <BigButton
                    class="md-accent"
                    @click="showSettings = !showSettings"
                    ><md-icon>settings</md-icon>
                    <md-tooltip md-direction="top">Settings</md-tooltip>
                </BigButton>
            </div>

            <md-divider style="margin: 10px" />
        </template>
        <template v-else>
            <PlayerBanner />
            <div class="disp-large">
                <md-button
                    class="overlay md-raised md-icon-button"
                    style="top: 13px; right: 60px; color: white"
                    @click="showQR = true"
                >
                    <md-icon>qr_code_2</md-icon>
                </md-button>
                <md-button
                    class="overlay md-raised md-icon-button"
                    style="top: 13px; right: 0px; color: white"
                    @click="showSettings = !showSettings"
                >
                    <md-icon>settings</md-icon>
                </md-button>
            </div>
            <div class="disp-small flex-end" style="margin-top: 5px">
                <md-button
                    class="md-raised md-accent md-icon-button"
                    style="color: white"
                    @click="showQR = true"
                >
                    <md-icon>qr_code_2</md-icon>
                </md-button>
                <md-button
                    class="md-raised md-accent md-icon-button"
                    style="color: white; margin-left: 15px"
                    @click="showSettings = !showSettings"
                >
                    <md-icon>settings</md-icon>
                </md-button>
            </div>
        </template>

        <template>
            <div
                class="flex-down flex-max"
                id="empty-container"
                v-if="!nrPlayers && !isTeams"
            >
                <div class="flex-down" id="empty-player-group">
                    <md-icon class="md-size-5x">groups</md-icon>
                    <strong class="scaling-font-big"
                        >Waiting for players...</strong
                    >
                </div>
            </div>
            <div
                v-else
                id="player-container"
                class="md-layout md-alignment-center-center"
            >
                <template v-if="isTeams">
                    <div
                        class="md-layout md-alignment-top-center"
                        id="team-list"
                    >
                        <template v-for="(t, index) in teamList">
                            <TeamCard
                                @join="joinTeam"
                                class="md-layout-item"
                                style="margin-bottom: 10px"
                                :key="index"
                                :localTeam="t"
                                :playerList="playerList"
                                :canDelete="
                                    info.host &&
                                    index >= 2 &&
                                    index == teamList.length - 1
                                "
                                :waitingForJoin="awaitJoinedTeam"
                                :cantRemovePlayers="!info.host"
                                @kick="kickPlayer"
                            />
                        </template>
                        <div
                            class="md-layout-item"
                            v-if="
                                info.host &&
                                teamList.length < (info.settings.duel ? 2 : 4)
                            "
                            style="padding: 5px 8px; height: 100%"
                        >
                            <md-content
                                class="new-team-div"
                                style="height: 100%"
                            >
                                <md-button
                                    @click="newTeam"
                                    id="new-team-button"
                                    class="md-icon-button md-raised md-accent"
                                >
                                    <md-icon class="pulse">add</md-icon>
                                </md-button>
                            </md-content>
                        </div>
                    </div>
                </template>

                <template v-else>
                    <div
                        v-for="player in playerList"
                        :key="player.id"
                        class="md-layout-item md-large-size-20 md-medium-size-20 md-small-size-33 md-xsmall-size-50 flex-center"
                    >
                        <PlayerPill
                            :player="player"
                            text="KICK"
                            :nonRemovable="!info.host"
                            v-on:kick="
                                kickPlayer({
                                    username: player.username,
                                    id: player.id,
                                })
                            "
                            :idOfActivePlayer="publicPlayerId"
                            :isTeams="false"
                        />
                    </div>
                </template>
            </div>
        </template>

        <md-dialog :md-active.sync="showQR" class="qr-dialog">
            <img
                style="
                    width: min(50vw, 50vh);
                    height: min(50vw, 50vh);
                    aspect-ratio: 1/1;
                    background: white;
                "
                :class="{ 'img-hidden': !qrImageLoaded }"
                :src="
                    'https://api.qrserver.com/v1/create-qr-code/?size=150x150&format=svg&data=' +
                    encodeURIComponent(inviteLink)
                "
                @load="qrLoaded"
                alt="QR Code"
            />
            <span style="color: white">
                {{ roomCode }}
            </span>
        </md-dialog>

        <SettingsDialog
            id="settingsDialog"
            :showSettingsTrigger="showSettings"
            :disableAll="!info.host"
        />

        <md-dialog-alert
            :md-active.sync="warnJoinTeam"
            md-title="Join team"
            md-content="You must join a team"
            md-confirm-text="OK"
        />
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import BigButton from "../../components/BigButton.vue";
import PlayerPill from "../../components/party/PlayerPill.vue";
import TeamCard from "../../components/party/TeamCard.vue";
import utilMixin from "../../mixins/utilMixin";
import SettingsDialog from "../../components/party/SettingsDialog.vue";
import PlayerBanner from "../../components/party/PlayerBanner.vue";

export default {
    mixins: [utilMixin],
    components: {
        BigButton,
        PlayerPill,
        TeamCard,
        SettingsDialog,
        PlayerBanner,
    },
    data() {
        return {
            locked: false,
            myStyle: {},
            fullscreen: false,
            showQR: false,
            showSettings: false,
            openMenu: false,
            warnJoinTeam: false,
            awaitJoinedTeam: false,
            qrImageLoaded: false,
        };
    },
    computed: {
        ...mapState("partygame", [
            "socket",
            "roomCode",
            "info",
            "teamList",
            "publicPlayerId",
            "team",
            "playerId",
        ]),
        ...mapGetters("partygame", ["playerList", "isTeams"]),
        inviteLink() {
            return (
                window.location.origin +
                "/multiplayer?roomCode=" +
                this.roomCode.toUpperCase()
            );
        },
        nrPlayers() {
            if (!this.playerList) {
                return 0;
            }
            return this.playerList.length;
        },
        startDisabled() {
            if (this.info.settings.duel) {
                return {
                    disabled: !this.teamList.every((t) =>
                        this.playerList.some((p) => p.teamId === t.teamId)
                    ),
                    message: "All teams must have players",
                }
            }
            if (this.info.settings.elimination) {
                return {
                    disabled: this.nrPlayers < 2,
                    message: "Must have at least 2 players",
                };
            }
            return {
                disabled: this.nrPlayers <= 0,
                message: "Must have at least 1 player",
            }
        },
    },
    watch: {
        info: function (newVal, oldVal) {
            if (newVal.locked != oldVal.locked) {
                this.locked = newVal.locked;
            }
        },
    },
    methods: {
        ...mapMutations("partygame", [
            "showNewMessage",
            "playerJoinedTeam",
            "setTeam",
        ]),
        ...mapActions("partygame", ["addTeam"]),
        qrLoaded() {
            this.qrImageLoaded = true;
        },
        kickPlayer({ username, id }) {
            this.socket.emit("kick", {
                roomCode: this.roomCode,
                authHeader: "Bearer " + localStorage.getItem("token"),
                payload: id,
            });
            this.showNewMessage(`Kicked ${username}`);
        },
        lock() {
            this.locked = !this.locked;
            this.socket.emit("toggleLocked", {
                roomCode: this.roomCode,
                authHeader: "Bearer " + localStorage.getItem("token"),
            });
        },
        copy() {
            this.$clipboard(this.inviteLink);
            this.showNewMessage("Copied invite link to clipboard");
        },
        newTeam() {
            this.addTeam("Team " + (this.teamList.length + 1));
        },
        toggleLocalFullscreen() {
            this.toggleFullscreen();
            this.fullscreen = !this.fullscreen;
        },
        joinTeam(teamId) {
            this.awaitJoinedTeam = true;
            this.socket.emit("joinTeam", {
                teamId,
                playerId: this.playerId,
            });
        },
    },
    created() {
        this.fullscreen = this.isFullscreen();
        this.locked = this.info.locked;

        this.socket.on("player_joined_team", (resp) => {
            this.playerJoinedTeam(resp);
        });

        this.socket.on("joined_team", (resp) => {
            this.awaitJoinedTeam = false;
            this.setTeam(resp.team);
        });

        this.warnJoinTeam = this.isTeams && !this.team;
    },

    beforeDestroy() {
        this.socket.removeAllListeners("joined_team");
    },
};
</script>

<style scoped>
#lobby-container {
    margin: 0;
    flex: 1;
}
#lobby-container .header-text {
    color: grey;
}
#lobby-container .bold {
    font-weight: bold;
}

.top-container {
    flex-direction: row;
    flex-wrap: wrap;
    margin: 5px 5px 0 5px;
    padding: 0 10px;
}

.left-container {
    flex: 1;
}

#pin-container {
    justify-content: center;
    text-align: left;
    align-items: flex-start;
}

#qr-code {
    max-height: 100%;
    max-width: 150px;
    background: red;
    display: block;
    aspect-ratio: 1/1;
    width: auto;
    height: auto;
    cursor: pointer;
}

.qr-dialog ::v-deep .md-dialog-container {
    min-width: 0;
}

@media screen and (min-width: 550px) {
    .align-right {
        flex: 0 !important;
        height: 100%;
        align-items: end !important;
    }

    .left-container {
        flex: 1;
    }

    .middle-container {
        margin: auto;
    }

    .player-b {
        display: none !important;
    }
}

@media screen and (max-width: 550px) {
    .player-a {
        display: none !important;
    }

    .middle-container {
        flex: 1;
    }

    #pin-container {
        margin: 0 auto;
    }

    #qr-container {
        margin: 0 auto;
    }
}

@media (max-width: 600px) {
    ::v-deep .md-dialog-fullscreen {
        width: auto;
        height: auto;
    }
}

.align-right {
    flex: 1;
    margin: 0 !important;
    padding-top: 5px;
    padding-bottom: 5px;
}

.align-right.max-sized {
    height: 70px;
}

.more-container {
    justify-content: end;
    align-items: end;
    margin-top: 5px;
    padding: 5px;
}
.more-container div {
    margin: 0 0 0 10px;
}

.player-display {
    padding: 10px 20px 10px 10px;
    border-radius: 5px;
    width: fit-content;
}
.player-display i {
    color: white;
}
.player-display span {
    color: white;
    font-size: 23px;
    vertical-align: middle;
    margin-left: 5px;
}

.player-a {
    height: 75% !important;
    max-height: 65px !important;
}

#empty-container {
    justify-content: center;
    flex: 1;
}

#player-container {
    flex: 1;
    margin: 5px;
}

/* ADD TEAM BUTTON */
.pulse {
    animation: pulse 3s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
        -webkit-transform: scale(1);
        -moz-transform: scale(1) rotate(0.02deg);
        -o-transform: scale(1);
        -ms-transform: scale(1);
    }
    50% {
        transform: scale(2);
        -webkit-transform: scale(2);
        -moz-transform: scale(2) rotate(0.02deg);
        -o-transform: scale(2);
        -ms-transform: scale(2);
    }
    100% {
        transform: scale(1);
        -webkit-transform: scale(1);
        -moz-transform: scale(1) rotate(0.02deg);
        -o-transform: scale(1);
        -ms-transform: scale(1);
    }
}

#new-team-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    width: 80px;
    border-radius: 50%;
}

#team-list {
    height: 100%;
}

.new-team-div {
    display: flex;
    justify-content: center;
    min-height: max(250px, 50vh);
    align-items: center;
    background: #44546a91;
    margin: 0;
    padding: 0 8px;
    border-radius: 2px;
}

::v-deep .player-list-card {
    margin: 0;
}

@media (max-width: 820px) {
    #settingsDialog ::v-deep .md-dialog-container {
        min-width: 90vw;
        min-height: calc(min(90vh, 340px));
    }
}

@media (min-width: 820px) {
    #settingsDialog ::v-deep .md-dialog-container {
        min-width: 720px;
    }
}

#settingsDialog ::v-deep .md-dialog-container {
    display: flex;
}

::v-deep #party-game-type-div {
    justify-content: center;
}

.circle {
    height: 10vw;
    width: 10vw;
    background-color: #bbb;
    border-radius: 50%;
}

.qr-dialog ::v-deep .md-dialog-container {
    background: none;
    box-shadow: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10vw;
    line-height: normal;
}

@media (min-width: 800px) {
    .disp-small {
        display: none;
    }
}

@media (max-width: 800px) {
    .disp-large {
        display: none;
    }
}

.img-hidden {
    opacity: 0;
}
</style>
