<template>
    <div style="padding: 5px 8px" class="fill-height">
        <md-card
            class="md-primary player-list-card"
            :style="{ 'background-color': localTeam.color }"
            style="height: 100%"
        >
            <md-card-header
                @click.native="joinTeam"
                :class="{ 'join-clickable': joinable }"
            >
                <div
                    class="md-display-1 flex-center card-header unselectable-text"
                    style="position: relative"
                >
                    <div v-if="joinable">
                        <!-- style="flex: 1" -->
                        <span style="color: white">Join</span>
                    </div>
                    <div v-else-if="ownTeam">Your team</div>
                    <div v-else>{{ localTeam.name }}</div>
                </div>
            </md-card-header>

            <md-divider></md-divider>
            <div class="md-layout md-alignment-center-center pill-container">
                <PlayerPill
                    :style="{ 'background-color': pillColor(localTeam.color) }"
                    style="color: #e6e6e6"
                    class="md-elevation-3"
                    v-for="player in teamPlayerList"
                    :key="player.id"
                    :player="player"
                    :idOfActivePlayer="publicPlayerId"
                    text="KICK"
                    :nonRemovable="cantRemovePlayers"
                    v-on:kick="
                        $emit('kick', {
                            username: player.username,
                            id: player.id,
                        })
                    "
                    :isTeams="true"
                />
            </div>
            <md-card-actions class="bottom">
                <div v-if="canDelete">
                    <md-button @click="clickDelete" class="delete-btn"
                        >Delete team</md-button
                    >
                </div>
            </md-card-actions>
        </md-card>
    </div>
</template>

<script>
import PlayerPill from "./PlayerPill.vue";
import { mapActions, mapState } from "vuex";

export default {
    name: "TeamCard",
    components: {
        PlayerPill,
    },
    props: {
        canDelete: Boolean,
        localTeam: Object,
        playerList: Array,
        cantRemovePlayers: Boolean,
        waitingForJoin: Boolean,
    },
    data() {
        return {};
    },

    computed: {
        ...mapState("partygame", ["publicPlayerId", "team", "info"]),
        teamPlayerList() {
            return this.playerList.filter(
                (player) => player.teamId == this.localTeam.teamId
            );
        },
        ownTeam() {
            return this.team && this.localTeam.teamId == this.team.teamId;
        },
        joinable() {
            return (
                !(this.waitingForJoin || this.ownTeam) &&
                this.info.roles.includes("GUESSER")
            );
        },
    },

    methods: {
        ...mapActions("partygame", ["removeTeam"]),
        clickDelete() {
            this.removeTeam(this.localTeam.teamId);
        },
        pillColor(color) {
            return (
                "#" +
                color
                    .replace(/^#/, "")
                    .replace(/../g, (color) =>
                        (
                            "0" +
                            Math.min(
                                255,
                                Math.max(0, parseInt(color, 16) - 70)
                            ).toString(16)
                        ).substr(-2)
                    )
            );
        },
        joinTeam() {
            if (this.joinable) {
                this.$emit("join", this.localTeam.teamId);
            }
        },
    },

    created() {},
};
</script>
<style scoped>
.player-list-card {
    min-height: max(250px, 50vh);
    display: flex;
    flex-direction: column;
}

.pill-container {
    flex: 0;
}

.bottom {
    flex: 1;
    align-items: flex-end;
}

.join-team-btn {
    position: absolute;
    top: auto;
    right: 5px;
    height: 100%;
    margin: -10px;
}

.join-clickable {
    cursor: pointer;
}

@media screen and (min-width: 400px) {
    .fill-height {
        height: 100%;
    }
}
</style>